import React,{useState,useEffect} from "react"
import "./AdminTrainerDetail.scss";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import {message,Switch} from "antd";
import {
    addTrainer_api,
    adminCheckoutTrainer_api,
    adminGetCheckoutPrice_api,
    adminSendWelcomeLink_api,
    adminTrainerCreditUpdate_api,
    adminTrainerDetail_api,
    adminTrainerPageUpdate_api,
    adminTrainerStatistic_api,
    adminTrainerUpdate_api
} from "../../Services/Services";
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import defaultUserIcon from "../../assets/images/default_user.png";
import {numberWithCommas} from "../../Services/utils";
let year = "1403"
let month = "07"

function AdminTrainerDetail({trainerId,handleDone}){

    const [fName,set_fName] =useState("")
    const [lName,set_lName] =useState("")
    const [mobile,set_mobile] =useState("")
    const [email,set_email] =useState("")
    const [pageName,set_pageName] =useState("")
    const [welcomeLink,set_welcomeLink] =useState("")
    const [maxVideos,set_maxVideos] =useState(100)
    const [planCommission,set_planCommission] =useState(4000)
    const [bookCommission,set_bookCommission] =useState(20000)
    const [planMaxCount,set_planMaxCount] =useState(1000)
    const [bookMaxCount,set_bookMaxCount] =useState(1000)
    const [endDate,set_endDate] =useState("1405/02/01")
    const [mainSpin,set_mainSpin] =useState(false)

    const [maxProduct,set_maxProduct] =useState(100)
    const [buyCommission,set_buyCommission] =useState(20000)
    const [buyMaxCount,set_buyMaxCount] =useState(1000)

    const [onlinePlanService,set_onlinePlanService] =useState(true)
    const [onlineBookingService,set_onlineBookingService] =useState(true)
    const [onlineOnlineShopService,set_onlineOnlineShopService] =useState(true)

    const [monthInput,set_monthInput] = useState("")
    const [yearInput,set_yearInput] = useState("")

    const [detail,set_detail] = useState({})
    const [statistic,set_statistic] = useState({})

    useEffect(e=>{
        console.log("sssss : ",trainerId)
        setCurrentDate()
        getTrainerStatistic()
        getTrainerDetail()
        getCheckoutPrice()
    },[])


    const setCurrentDate = e=>{
        let nowYear = moment(new Date()).format('jYYYY')
        let nowMonth = moment(new Date()).format('jMM')
        month=parseInt(nowMonth).toString()
        year=parseInt(nowYear).toString()
        set_yearInput(year)
        set_monthInput(month)
    }

    const fillForm = (detail)=>{
        if(detail?.credit?.endAt)
        set_endDate(moment(detail.credit.endAt).format('jYYYY/jMM/jDD'))


        //page
        if(detail?.page?.slug)
            set_pageName(detail?.page?.slug)

        //videos
        if(detail?.credit?.max_video)
            set_maxVideos(detail?.credit?.max_video)


        //product
        if(detail?.credit?.max_product)
            set_maxProduct(detail?.credit?.max_product)



        //buy

        if(detail?.credit?.buy_cost)
            set_buyCommission(detail?.credit?.buy_cost)

        if(detail?.credit?.buy_max_count)
            set_buyMaxCount(detail?.credit?.buy_max_count)

        //plan

        if(detail?.credit?.plan_cost)
            set_planCommission(detail?.credit?.plan_cost)

        if(detail?.credit?.plan_max_count)
            set_planMaxCount(detail?.credit?.plan_max_count)


        //booking
        if(detail?.credit?.booking_cost)
            set_bookCommission(detail?.credit?.booking_cost)

        if(detail?.credit?.booking_max_count)
            set_bookMaxCount(detail?.credit?.booking_max_count)


        //trainer
        if(detail?.trainer?.first_name)
            set_fName(detail?.trainer?.first_name)

        if(detail?.trainer?.last_name)
            set_lName(detail?.trainer?.last_name)

        if(detail?.trainer?.mobile)
            set_mobile(detail?.trainer?.mobile)

        if(detail?.trainer?.email)
            set_email(detail?.trainer?.email)



        if(detail?.trainer?.welcome_link)
            set_welcomeLink(detail?.trainer?.welcome_link)

        if(detail?.trainer?.services){
            let sObj = []
            if(detail?.trainer?.services.includes("plan"))
                set_onlinePlanService(true)
            else
                set_onlinePlanService(false)


            if(detail?.trainer?.services.includes("booking"))
                set_onlineBookingService(true)
            else
                set_onlineBookingService(false)

            if(detail?.trainer?.services.includes("shop"))
                set_onlineOnlineShopService(true)
            else
                set_onlineOnlineShopService(false)


        }



    }

    const getTrainerStatistic = async ()=>{
        let  obj ={
            shamsi_month:month,
            shamsi_year:year
        }
        try{
            const st = await adminTrainerStatistic_api(trainerId,obj)
            set_statistic(st.data)
        }catch (e) {
            console.log(e)
        }
    }
    const getTrainerDetail = async ()=>{
        try{
            const detail = await adminTrainerDetail_api(trainerId)
            set_detail(detail.data)
            fillForm(detail.data)
        }catch (e) {
            console.log(e)
        }
    }

    const handleChangeMonth = (m)=>{
        set_monthInput(m)
        month = m;
        getTrainerStatistic()
    }
    const handleChangeYear = (y)=>{
        set_yearInput(y)
        year = y;
        getTrainerStatistic()
    }




    const handleAddTrainer = async ()=>{
        set_mainSpin(true)
        if(
            fName.length<1 ||
            lName.length<1 ||
            mobile.length<1 ||
            pageName.length<1 ||
            welcomeLink.length<1||
            endDate.length<1
        ){
            set_mainSpin(false)
            return message.error({
                content: "لطفا فرم را کامل نمایید",
                className: 'toast-class'
            });
        }

        let sObj = []
        if(onlinePlanService)
            sObj.push('plan')
        if(onlineBookingService)
            sObj.push('booking')
        if(onlineOnlineShopService)
            sObj.push('shop')

        let trainerObj = {
            first_name:fName,
            last_name:lName,
            mobile:mobile,
            email:email,
            welcome_link:welcomeLink,
            services:sObj
        }



        let creditObj = {
            max_video:maxVideos,
            buy_cost:buyCommission,
            max_product:maxProduct,
            buy_max_count:buyMaxCount,
            plan_cost:planCommission,
            booking_cost:bookCommission,
            plan_max_count:planMaxCount,
            booking_max_count:bookMaxCount,
            endAt:moment(endDate,'jYYYY/jMM/jDD').format('YYYY/MM/DD')
        }

        let pageObj = {
            slug:pageName,
        }

        try{
            await adminTrainerUpdate_api(trainerId,trainerObj)
            await adminTrainerCreditUpdate_api(trainerId,creditObj)
            await adminTrainerPageUpdate_api(trainerId,pageObj)
            handleDone()
             message.success({
                content: "مربی ویرایش شد",
                className: 'toast-class'
            });
            set_mainSpin(false)
        }catch (e) {
            message.error({
                content: "مشکل در ویرایش مربی",
                className: 'toast-class'
            });
            set_mainSpin(false)
            console.log(e)
        }
    }

    const [checkoutPrice,set_checkoutPrice] = useState(null)
    const [refId,set_refId] = useState("")
    const getCheckoutPrice = async ()=>{
        try{
            const res = await adminGetCheckoutPrice_api(trainerId)
            set_checkoutPrice(res.data)
            return message.success({
                content: "استعلام انجام شد",
                className: 'toast-class'
            });

        }catch (e) {
            console.log(e)
        }
    }

    const checkoutTrainer = async ()=>{
        if(refId.length<1 || !checkoutPrice){
            return message.success({
                content: "لطفا استعلام را بزنید و شماره پیگیری را وارد نمایید",
                className: 'toast-class'
            });
        }

        let obj ={ refId:refId, transactionIds:checkoutPrice.transactionIds }
        try{
            await adminCheckoutTrainer_api(trainerId,obj)

        }catch (e) {

            console.log(e)
        }
    }


    const sendWelcomeLink = async ()=>{
        try{
            await adminSendWelcomeLink_api(trainerId)
            return message.success({
                content: "لینک دعوت با موفقیت ارسال شد",
                className: 'toast-class'
            });
        }catch (e) {
            console.log(e)
        }
    }

    return(
        <>
            {!mainSpin?
                <div className="admin-trainer-add-container">

                    <div className="admin-trainer-add-form">

                        {/*header*/}

                        <div className="admin-trainer-detail-img">
                                {detail?.trainer?.image?
                                    <div className="trainer-image-wrapper">
                                        <img className="trainer-image" src={Setup.filePoint+detail?.trainer?.image} alt=""/>
                                    </div>
                                    :
                                    <img src={defaultUserIcon} alt=""/>
                                }
                        </div>
                        <div className="admin-trainer-detail-info">
                                <span  className="admin-trainer-detail-name">{detail?.trainer?.first_name+" "+detail?.trainer?.last_name}</span>
                                <span className="admin-trainer-detail-mobile">{detail?.trainer?.mobile}</span>
                        </div>

                        {/*Statistic*/}

                        <div className="admin-trainer-detail-statistic">

                            <div className="admin-trainer-detail-statistic-header">
                                <div className="admin-trainer-detail-statistic-average">
                                    {statistic?.average?.toFixed(3)}
                                </div>
                                <div className="admin-trainer-detail-statistic-filter">
                                    <div className="admin-filters">
                                        <div className="admin-filter-item">
                                            <select
                                                value={monthInput}
                                                onChange={e=>{
                                                    handleChangeMonth(e.target.value)
                                                }}
                                                name="" id="">
                                                <option value="1">فروردین</option>
                                                <option value="2">اردیبهشت</option>
                                                <option value="3">خرداد</option>
                                                <option value="4">تیر</option>
                                                <option value="5">مرداد</option>
                                                <option value="6">شهریور</option>
                                                <option value="7">مهر</option>
                                                <option value="8">آبان</option>
                                                <option value="9">آذر</option>
                                                <option value="10">دی</option>
                                                <option value="11">بهمن</option>
                                                <option value="12">اسفند</option>
                                                <option value="all">همه</option>
                                            </select>
                                        </div>

                                        <div className="admin-filter-item">
                                            <select
                                                value={yearInput}
                                                onChange={e=>{
                                                    handleChangeYear(e.target.value)
                                                }}
                                                name="" id="">
                                                <option value="1403">1403</option>
                                                <option value="1404">1404</option>
                                                <option value="1405">1405</option>
                                                <option value="1406">1406</option>
                                                <option value="1407">1407</option>
                                                <option value="1408">1408</option>
                                                <option value="1409">1409</option>
                                                <option value="1410">1410</option>
                                                <option value="1411">1411</option>
                                                <option value="1412">1412</option>
                                                <option value="1413">1413</option>
                                                <option value="1414">1414</option>
                                                <option value="all">همه</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="admin-trainer-detail-statistic-row">

                                <div className="admin-trainer-detail-statistic-row-item">
                                    <h4>{statistic?.self_plan}</h4>
                                    <span>پلن خود</span>
                                </div>

                                <div className="admin-trainer-detail-statistic-row-item">
                                    <h4>{statistic?.page_plan}</h4>
                                    <span>پلن صفحه</span>
                                </div>
                            </div>

                            <div className="admin-trainer-detail-statistic-row">

                                <div className="admin-trainer-detail-statistic-row-item">
                                    <h4>{statistic?.self_book}</h4>
                                    <span>رزرو خود</span>
                                </div>

                                <div className="admin-trainer-detail-statistic-row-item">
                                    <h4>{statistic?.page_book}</h4>
                                    <span>رزرو صفحه</span>
                                </div>
                            </div>


                        </div>


                        {/*resource*/}
                        <div className="admin-trainer-detail-resource">

                            <div className="admin-trainer-detail-resource-item">
                                <h5>{detail?.videoCount}</h5>
                                <span>ویدیو</span>
                            </div>

                            <div className="admin-trainer-detail-resource-item">
                                <h5>{detail?.libCount}</h5>
                                <span>کتابخانه</span>
                            </div>

                            <div className="admin-trainer-detail-resource-item">
                                <h5>{detail?.traineesCount}</h5>
                                <span>شاگرد</span>
                            </div>
                        </div>

                        {/*Services*/}
                        <div className="admin-trainer-detail-services">
                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlinePlanService}  onChange={e=>{
                                    set_onlinePlanService(e)
                                }} />
                                <span>برنامه آنلاین</span>
                            </div>

                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlineBookingService}   onChange={e=>{
                                    set_onlineBookingService(e)
                                }} />
                                <span>نوبت دهی</span>
                            </div>

                            <div className="admin-trainer-detail-services-row">
                                <Switch defaultChecked checked={onlineOnlineShopService}   onChange={e=>{
                                    set_onlineOnlineShopService(e)
                                }} />
                                <span>فروشگاه</span>
                            </div>
                        </div>


                        <div className="admin-trainer-detail-debt">
                            <h5> {numberWithCommas(statistic?.debt_force)}  ریال </h5>
                            <span>{numberWithCommas(statistic?.debt)} ریال </span>
                            <div className="status-"></div>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={endDate}
                                onChange={e=>set_endDate(e.target.value)}
                                type="text" name="" id=""/>
                            <span>پایان اعتبار</span>
                        </div>

                        <div className="admin-trainer-detail-checkout">
                            {checkoutPrice?.totalAmount?
                                <h5>{numberWithCommas(checkoutPrice?.totalAmount)}ریال </h5>:
                                <h5>{0}ریال </h5>}
                            <span className="clickable" onClick={e=>{
                                getCheckoutPrice()
                            }}>استعلام تسویه</span>
                            {checkoutPrice?.totalAmount?<>
                                <input type="text"
                                       value={refId}
                                       onChange={e=>set_refId(e.target.value)}
                                       name="" id="" placeholder="کد پیگیری پرداخت"/>
                                <span onClick={e=>{
                                    checkoutTrainer()
                                }}>پرداخت شد</span>
                            </>:null}
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={fName}
                                onChange={e=>set_fName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام</span>
                        </div>
                        <div className="admin-trainer-add-item">
                            <input
                                value={lName}
                                onChange={e=>set_lName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام خانوادگی</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={mobile}
                                pattern="[0-9]*"
                                onChange={e=>set_mobile(e.target.value)}
                                type="tel" name="" id=""/>
                            <span>موبایل</span>
                        </div>
                        <div className="admin-trainer-add-item">
                            <input
                                value={email}
                                pattern="[0-9]*"
                                onChange={e=>set_email(e.target.value)}
                                type="tel" name="" id=""/>
                            <span>ایمیل</span>
                        </div>

                        <div className="admin-trainer-detail-send-welcome">
                            <span className="clickable" onClick={e=>{
                                sendWelcomeLink()
                            }}>ارسال لینک خوش آمد</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={pageName}
                                onChange={e=>set_pageName(e.target.value)}
                                type="text" name="" id=""/>
                            <span>نام صفحه</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={welcomeLink}
                                onChange={e=>set_welcomeLink(e.target.value)}
                                type="text" name="" id=""/>
                            <span >لینک خوش آمد</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={maxVideos}
                                pattern="[0-9]*"
                                onChange={e=>set_maxVideos(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم ویدیو</span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={maxProduct}
                                pattern="[0-9]*"
                                onChange={e=>set_maxProduct(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم محصول </span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={planCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_planCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر پلن</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={planMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_planMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم پلن</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={buyCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_buyCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر خرید(تومان)</span>
                        </div>


                        <div className="admin-trainer-add-item">
                            <input
                                value={buyMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_buyMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم خرید</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={bookCommission}
                                pattern="[0-9]*"
                                onChange={e=>set_bookCommission(e.target.value)}
                                type="text" name="" id=""/>
                            <span>کارمزد هر رزرو</span>
                        </div>

                        <div className="admin-trainer-add-item">
                            <input
                                value={bookMaxCount}
                                pattern="[0-9]*"
                                onChange={e=>set_bookMaxCount(e.target.value)}
                                type="text" name="" id=""/>
                            <span>ماکزیمم رزرو</span>
                        </div>







                        <div className="admin-trainer-detail-send-notification">
                            <textarea name="" id="" cols="30" rows="3">

                            </textarea>
                            <span className="clickable">ارسال اس ام اس</span>
                        </div>



                        <div className="admin-trainer-detail-send-welcome">
                            <span className="clickable">لیست تسویه ها</span>
                        </div>

                    </div>

                    <div className="admin-trainer-add-btn clickable" onClick={e=>{
                        handleAddTrainer()
                    }}>
                        <span>ثبت </span>
                    </div>

                </div>:
                <div className="menu-loading">
                    <span style={{width:40,height:40,border:"1px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
                </div>
            }
            </>

    )
}

export default AdminTrainerDetail;
