import React, {useState, useRef, useEffect} from 'react';
import { ReactMic } from 'react-mic';
import "./VoiceRecorder.scss";
import speakerIcon from "../../assets/dumbbell_image/V2/speaker_icon.svg";
import stopIcon from "../../assets/dumbbell_image/V2/stop_icon.svg";
import {deleteVoice_api, uploadPlanVoice} from "../../Services/Services";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {message, Popconfirm,Spin} from "antd";
import {
    DeleteOutlined,
    CaretRightOutlined,
    BorderOutlined,
    AudioOutlined,
    CloudUploadOutlined,
} from '@ant-design/icons';
import {Setup} from "../../Services/Setup";
import {v4 as uuidv4} from 'uuid';

const VoiceRecorder = ({basePlan,uploadVoiceIsDone,deleteVoiceIsDone}) => {
    const [visibleLine, set_visibleLine] = useState(false);
    const [recording, setRecording] = useState(false);
    const [blobObject, setBlobObject] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    const audioRef = useRef(null);
    const audioPlanRef = useRef(null);
    const setUploadLoading = useDispatchUploadLoading()

    const startRecording = () => {
        set_visibleLine(true)
        setTimeout(() => {
            setRecording(true);
        },0)
        setTimeout(() => {
            stopRecording();
        }, 120000); // 120000 میلی‌ثانیه = 2 دقیقه
    };

    const stopRecording = () => {
        setRecording(false);
    };

    const onData = (recordedBlob) => {
        console.log('Chunk of real-time data is: ', recordedBlob);
    };

    const onStop = (recordedBlob) => {
        set_visibleLine(false)
        console.log('Recorded blob is: ', recordedBlob);
        setBlobObject(recordedBlob.blob);
        setAudioURL(URL.createObjectURL(recordedBlob.blob));
    };

    const [deleteSpin,set_deleteSpin] = useState(false)

    const confirm  = async ()=>{
        set_deleteSpin(true)
        try{
            await deleteVoice_api(basePlan._id)
            deleteVoiceIsDone()
            set_deleteSpin(false)
            return message.success({
                content: "صدا با موفقیت حذف شد",
                className: 'toast-class'
            });
        }catch (e) {
            set_deleteSpin(false)
            console.log("e : ",e)
            return message.success({
                content: "مشکل در حذف صدا",
                className: 'toast-class'
            });
        }

    }
    const cancel  = ()=>{

    }

    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره صدا  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود صدا  . . ."})
        }
    },[thumbUploadProgress])

    const uploadAudio = async () => {
        try{
           let response =  await uploadPlanVoice(blobObject,basePlan._id,set_thumbUploadProgress)
            setUploadLoading({visible:false,percent:0})
            set_thumbUploadProgress(0)
            uploadVoiceIsDone(response.data.data.voice)
            setBlobObject(null);
            setAudioURL(null);
            return message.success({
                content: "آپلود صدا انجام شد",
                className: 'toast-class'
            });
        }catch (e) {
            setUploadLoading({visible:false,percent:0})
            set_thumbUploadProgress(0)
            return message.success({
                content: "مشکل در آپلود صدا , لطقا از اینترنت با سرعت مناسب استفاده نمایید",
                className: 'toast-class'
            });
            console.log("e : ",e)
        }
    };



    const playPlanAudio = () => {
        if (audioPlanRef.current) {
            audioPlanRef.current.play();
        }
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const deleteAudio = () => {
        setBlobObject(null);
        setAudioURL(null);
    };

    return (
        <>
            {basePlan._id?
                <div>

                    {basePlan?.voice?.length>0?
                        <div>
                            <Spin spinning={deleteSpin}>
                                <div className="play-section">
                                    <img src={speakerIcon} onClick={e=>{
                                        playPlanAudio()
                                    }} alt=""/>
                                    <div className="recorder-btn">
                                        <Popconfirm
                                            title="این صدا حذف بشه ؟"
                                            onConfirm={confirm}
                                            onCancel={cancel}
                                            okText="بله"
                                            icon={""}
                                            cancelText="انصراف"
                                        >
                                            <DeleteOutlined onClick={deleteAudio} />
                                        </Popconfirm>
                                    </div>
                                    <audio style={{visibility:'hidden',width:0}} ref={audioPlanRef} src={Setup.filePoint+basePlan.voice+`?id=${uuidv4()}`} controls />
                                </div>
                            </Spin>

                        </div>:
                        <div className="start-record">
                            <div>
                                {visibleLine?<ReactMic
                                    record={recording}
                                    className="recorder-custom"
                                    onStop={onStop}
                                    onData={onData}
                                    strokeColor="#ffffff"
                                    backgroundColor="#8D89FD" />:null}
                            </div>


                            <div className="recorder-btn">
                                {!visibleLine && !audioURL? <AudioOutlined onClick={startRecording} /> :null}
                            </div>
                            <div className="recorder-btn">
                                {visibleLine? <BorderOutlined onClick={stopRecording} /> :null}
                            </div>

                            {audioURL?<audio style={{visibility:'hidden',width:0}} ref={audioRef} src={audioURL} controls />:null}

                            <div className="recorder-btn">
                                {audioURL?<CaretRightOutlined onClick={playAudio} />:null}
                            </div>
                            <div className="recorder-btn">
                                {audioURL?<DeleteOutlined onClick={deleteAudio} />:null}
                            </div>
                            <div className="recorder-btn">
                                {audioURL?<CloudUploadOutlined onClick={uploadAudio} />:null}
                            </div>
                        </div>
                    }



                </div>:null
            }
            </>
    );
};

export default VoiceRecorder;
