import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellVideoUpload.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import playIcon from "../../assets/dumbbell_image/play-icon.png";
import ReactPlayer from 'react-player'
import { v4 as uuidv4 } from 'uuid'

// import sampleMov from "../../assets/video/sample_video.mov";
// import sampleMp4 from "../../assets/video/sample_video.mp4";
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import {boxImageUploadApi, videoEditApi, videoEditTitleApi, videoUploadApi} from "../../Services/Services";
import {LoadingOutlined} from "@ant-design/icons"
import {message,Spin} from "antd";
import {checkFileSize} from "../../Services/utils";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {Setup} from "../../Services/Setup";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let firstEdit = true
function DumbbellVideoUpload({edited=null,handleUploadDone}){

    const [imageFile,set_imageFile] = useState(null)
    const fileRef = useRef(null);
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);
    const [spinTip,set_spinTip] = useState("")
    const[uploadMode,set_uploadMode] = useState(false)
    const[title,set_title] = useState("")
    const[cat,set_cat] = useState("")
    const[videoRefreshId,set_videoRefreshId] = useState()
    const setUploadLoading = useDispatchUploadLoading()


    useEffect(()=>{
        set_videoRefreshId(uuidv4())
        if(edited){
             firstEdit = true
            console.log("Edited : ",Setup.filePoint+edited.video)
            set_title(edited.fa_names[0])
            set_imagePrev(Setup.filePoint+edited.video)
        }
    },[])

    useEffect(() => {

        if(firstEdit){
            firstEdit  =false
            return
        }

        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 100
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره ویدیو  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود ویدیو  . . ."})
        }
    },[thumbUploadProgress])

    const upload = async ()=>{

        if(title.length<2){
            return message.error({
                content: "لطفا عنوان حرکت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!imageFile){
            return message.error({
                content: "لطفا ویدیو را انتخاب نمایید",
                className: 'toast-class'
            });
        }


        set_spinTip("در حال آپلود...")
        set_spin(true)
        set_uploadMode(true)
        if(imageFile){
            try{
                let response = await videoUploadApi(imageFile,title,cat,set_thumbUploadProgress);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }

    }

    const editVideo = async ()=>{

        if(title.length<2){
            return message.error({
                content: "لطفا عنوان حرکت را وارد نمایید",
                className: 'toast-class'
            });
        }

        // if(!imageFile){
        //     return message.error({
        //         content: "لطفا ویدیو جدید را انتخاب نمایید",
        //         className: 'toast-class'
        //     });
        // }



        if(imageFile){
            set_spinTip("در حال آپلود...")
            set_spin(true)
            set_uploadMode(true)
            try{
                let response = await videoEditApi(edited._id,edited.video,imageFile,title,cat,set_thumbUploadProgress);
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                set_imagePrev(null)
                handleUploadDone()
                set_videoRefreshId(uuidv4())
                return message.success({
                    content: "ویدیو حرکت شما با موفقیت آپلود شد",
                    className: 'toast-class'
                });
            }catch(e){
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                set_spin(false)
                set_uploadMode(false)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود ویدیو",
                    className: 'toast-class'
                });
            }
        }else {
            try{
                set_spin(true)
                await videoEditTitleApi(edited._id,title)
                set_spin(false)
                return message.success({
                    content: "تایتل ویدیو با موفقیت ویرایش شد",
                    className: 'toast-class'
                });
            }catch(e){
                set_spin(false)
                console.log(e)
                return message.error({
                    content: "مشکل در ویرایش ویدیو",
                    className: 'toast-class'
                });
            }

        }

    }


    const uri = "https://media.starium-space.com/d69b6df3-e7ea-4450-aced-ad0470e3857a/sample_video.mov"

    return(
        <Spin indicator={antIcon}  spinning={spin}>
            <div>
                <DumbbellDrawerHeader title={"آپلود ویدیو"}/>

                <div className="dumbbell-video-upload-wr">
                    {imagePrev?<div className="edit-video-icon clickable" onClick={handleClickItems}>
                            <img src={EditIcon} alt=""/>
                    </div>:null}
                    {!imagePrev?<div className="dumbbell-video-upload-sec clickable" onClick={e=>{
                        handleClickItems()
                    }}>
                        <img   src={playIcon} alt=""/>
                    </div>:null}
                    {imagePrev && imagePrev.startsWith('https')?
                        <ReactPlayer width={"100%"}  url={imagePrev+`?id=${videoRefreshId}`} controls />:
                        null
                    }
                    {imagePrev && !imagePrev.startsWith('https')?
                        <ReactPlayer width={"100%"}  url={imagePrev} controls />:
                        null
                    }

                    <div className="hint-message">
                        <span>حجم هر ویدیو ارسالی نباید بیشتر از 100 مگابایت باشد</span>
                    </div>
                </div>

                <div className="dumbbell-video-upload-title">
                    <input
                        value={title}
                        onChange={e=>set_title(e.target.value)}
                        type="text" name="" id="" placeholder="عنوان حرکت"/>
                </div>

                {edited?
                    <div className="dumbbell-video-upload-btn" onClick={
                        e=>editVideo()
                    }>
                        <div>ویرایش ویدیو</div>
                    </div>:
                    <div className="dumbbell-video-upload-btn" onClick={
                        e=>upload()
                    }>
                        <div>آپلود ویدیو</div>
                    </div>
                }

                <input type="file" ref={fileRef} name="" accept="video/mp4,video/x-m4v,video/*" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
            </div>
        </Spin>
    )
}

export default DumbbellVideoUpload;
