import "./OnlineShop.scss"
import Swiper from "react-id-swiper";
import {Setup} from "../../Services/Setup";
import {numberWithCommas, validation} from "../../Services/utils";
import plusProductIcon from "../PageV2/assets/plusProductIcon.svg";
import React, {useRef,useState,useEffect} from "react";
import cartIcon from "./assets/cartIcon.svg"
import caretDownIcon from "./assets/caretDownIcon.svg"
import cartCheckedIcon from "./assets/cartCheckedIcon.png"
import {LoadingOutlined,PictureOutlined} from "@ant-design/icons"
import {Drawer, message, Spin} from "antd";
import {
    clearCart,
    decCart,
    getItemCount, getPayableAmount, getTotalDiscount,
    getTotalItems,
    getTotalPrice,
    incCart,
    syncCart
} from "../../Services/cartService";
import {useCart} from "../../Hook/useCart";
import {useDispatchCart} from "../../Hook/useDispatchCart";
import {finalizeBasketApi, sendOrderOtpApi, trackOrderApi} from "../../Services/Services";
import {Collapse} from "react-collapse";
import {CopyToClipboard} from "react-copy-to-clipboard";

const ShopProductCard = ({showDetailDrawer,p,checkItemCount,handleAddItem,handleRemoveItem})=>{
    return(
        <div className="product-swiper-product-card">
            <div className="product-swiper-product-card-image" style={{width:'90%'}} onClick={e=>{
                showDetailDrawer(p)
            }}>
                {p.image.length>0?
                    <img src={Setup.filePoint+p.image} alt=""/>:
                    <div className="image-swiper-placeholder">
                        <PictureOutlined/>
                    </div>}
            </div>
            <div onClick={e=>{
                showDetailDrawer(p)
            }}>
                <div className="product-swiper-product-card-price">
                    <span>{numberWithCommas(p.price-p.discount)}تومان</span>
                </div>

                <div className="product-swiper-product-card-discount">
                    {p.discount>0?<span>{numberWithCommas(p.price)} تومان</span> :<span></span>}
                </div>


                <div className="product-swiper-product-card-name">
                    <span>{p.fa_name.length>30?p.fa_name.substring(0,30)+"...":p.fa_name}</span>
                </div>
            </div>

            {p.stock>0 && checkItemCount(p._id)<1?
                <div className="product-swiper-product-card-add-btn clickable" onClick={e=>{
                    handleAddItem(p)
                }}>
                    <img src={plusProductIcon} alt=""/>
                </div>:null
            }

            {p.stock<1?
                <div className="out-of-stock">
                    <span>ناموجود</span>
                </div>
                :null}

            {p.stock>0 && checkItemCount(p._id)>0?
                <div className="add-remove-section">
                    <span className="add-section-btn clickable" onClick={e=>handleAddItem(p)}>+</span>
                    <span className="count-section-btn">{checkItemCount(p._id)}</span>
                    <span className="remove-section-btn clickable" onClick={e=>handleRemoveItem(p._id)}>-</span>
                </div>
                :null}

        </div>
    )
}

const OnlineShop = ({page,productsRegal,products,slug})=>{
    const MyRef = useRef(null);
    const [selectProduct,set_selectProduct] = useState(null)
    const [cartMode,set_cartMode] = useState("default")
    const [collapseCartProduct,set_collapseCartProduct] = useState(false)
    const cart = useCart();
    const dispatch = useDispatchCart();
    const [customerPayRef,set_customerPayRef] = useState("")
    const [fullName,set_fullName] = useState("")
    const [mobile,set_mobile] = useState("")
    const [otp,set_otp] = useState("")
    const [address,set_address] = useState("")
    const [spin,set_spin] = useState(false)
    const [refNumber,set_refNumber] = useState("")
    const [cartSpin,set_cartSpin] = useState(false)

    const [open, setOpen] = useState(false);
    const refElement = useRef(null);
    const [drawerWidth, setDrawerWidth] = useState(0);

    const resetCartDrawer = ()=>{
        set_cartMode("default")
    }

    useEffect(() => {
        if (refElement.current) {
            setDrawerWidth(refElement.current.offsetWidth); // Set drawer width based on other element's width
        }
    }, []);

    useEffect(() => {
        if (open) {
            const drawerContentWrapper = document.querySelector('.ant-drawer-content-wrapper');
            if (drawerContentWrapper) {
                drawerContentWrapper.style.width = `${drawerWidth}px`; // Set the dynamic width
            }
        }
    }, [open, drawerWidth]);

    const resetTrackDrawer = ()=>{
        set_trackMobile("")
        set_trackCode("")
        set_trackResult({})
        set_trackSpin(false)
    }

    useEffect(()=>{
        console.log(cart)
        syncCart(dispatch);
    },[dispatch])


    const handleAddItem = (item) => {
        let q = checkItemCount(item._id)
        if(item.stock>q){
            incCart(dispatch, item);
        }else {
            return message.error({
                content: "تعداد بیشتر موجود نمی باشد",
                className: 'toast-class'
            });
        }

    };

    const handleRemoveItem = (itemId) => {
        decCart(dispatch, itemId);
    };

    const handleClearCart = () => {
        clearCart(dispatch);
    };

    const checkItemCount = (itemId) => {
        return getItemCount(cart, itemId);

    };

    const paramsLink = {
        slidesPerView: 'auto',
        spaceBetween: 2,
        // rebuildOnUpdate : true,
        rtl:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    //shop drawer
    const [visibleShopDrawer,set_visibleShopDrawer] = useState(false)
    const closeShopDrawer =()=>{
        set_visibleShopDrawer(false)
    }
    const showShopDrawer =()=>{
        set_visibleShopDrawer(true)
    }

    //shop basket
    const [visibleBasketDrawer,set_visibleBasketDrawer] = useState(false)
    const closeBasketDrawer =()=>{
        set_visibleBasketDrawer(false)
    }
    const showBasketDrawer =()=>{
        resetCartDrawer()
        set_visibleBasketDrawer(true)
    }

    //track order
    const [visibleTrackDrawer,set_visibleTrackDrawer] = useState(false)
    const closeTrackDrawer =()=>{
        set_visibleTrackDrawer(false)
    }
    const showTrackDrawer =()=>{
        resetTrackDrawer()
        set_visibleTrackDrawer(true)
    }

    //shop detail
    const [visibleDetailDrawer,set_visibleDetailDrawer] = useState(false)
    const closeDetailDrawer =()=>{
        set_visibleDetailDrawer(false)
    }
    const showDetailDrawer =(p)=>{
        set_selectProduct(p)
        set_visibleDetailDrawer(true)
    }

    const [trackState,set_trackState] = useState("")
    const [trackMode,set_trackMode] = useState("default") // result
    const [trackResult,set_trackResult] = useState({})
    const [trackCode,set_trackCode] = useState("")
    const [trackMobile,set_trackMobile] = useState("")
    const [trackSpin,set_trackSpin] = useState(false)

    const convertOrderStatusToColor = (status)=>{

        if(status==="created"){
            return "track-drawer-state-pending"
        }

        if(status==="cancel_by_customer"){
            return "track-drawer-state-cancel"
        }

        if(status==="cancel_by_owner"){
            return "track-drawer-state-cancel"
        }

        if(status==="send"){
            return "track-drawer-state-send"
        }

        if(status==="packing"){
            return "track-drawer-state-pending"
        }

        return "track-drawer-state-pending"

    }


    const convertOrderStatusToName = (status)=>{

        if(status==="created"){
            return "در حال بررسی"
        }

        if(status==="cancel_by_customer"){
            return "کنسل توسط مشتری"
        }

        if(status==="cancel_by_owner"){
            return "کنسل توسط فروشگاه"
        }

        if(status==="send"){
            return "ارسال شده"
        }

        if(status==="packing"){
            return "در حال آماده سازی"
        }

        return "در دست اقدام"

    }


    const handleTrackOrder = async ()=>{

        if(!validation("MOBILE",trackMobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(trackCode.length<5){
            return message.error({
                content: "کد وارد شده صحیح نمیباشد",
                className: 'toast-class'
            });
        }
        let obj = {trackId:trackCode,mobile:trackMobile}
        try{
            set_trackSpin(true)
            const r = await trackOrderApi(obj)
            set_trackResult(r.data.data)
            set_trackSpin(false)
            // return message.success({
            //     content: "شماره پیگیری وارد شده صحیح نمیباشد",
            //     className: 'toast-class'
            // });
        }catch (e) {
            set_trackSpin(false)
            console.log(e)
            return message.error({
                content: "شماره پیگیری وارد شده صحیح نمیباشد",
                className: 'toast-class'
            });
        }
    }

    const handleSendOtp =async ()=>{
        if(!validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        set_cartSpin(true)
        let  obj = {
            phoneNumber:mobile
        }
        try{
            await sendOrderOtpApi(obj)
            set_cartSpin(false)
            return message.success({
                content: "کد تایید برای شما ارسال شد",
                className: 'toast-class'
            });
        }catch(e){
            set_cartSpin(false)
            console.log(e)
            return message.error({
                content: "شما فعلا قادر به دریافت کد نمی باشید",
                className: 'toast-class'
            });
        }
    }

    const sendCart =async ()=>{
        if(!validation("MOBILE",mobile)){
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(cart.length <1){
            return message.error({
                content: "لطفا یک محصول انتخاب نمایید",
                className: 'toast-class'
            });
        }


        if(fullName.length <1){
            return message.error({
                content: "لطفا نام واریز کننده را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(otp.length <1){
            return message.error({
                content: "لطفا کد تایید موبایل را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(customerPayRef.length <1){
            return message.error({
                content: "لطفا شماره پیگیری را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(address.length <1){
            return message.error({
                content: "لطفا آدرس خود را جهت ارسال وارد نمایید",
                className: 'toast-class'
            });
        }


        set_cartSpin(true)
        let  obj = {
            cart:cart,
            full_name: fullName,
            mobile:mobile,
            slug:slug,
            otp:otp,
            customer_pay_ref:customerPayRef,
            address:address
        }
        try{
            let result = await finalizeBasketApi(obj)
            set_refNumber(result.data.data.trackId)
            set_cartSpin(false)
            clearCart(dispatch)
            set_cartMode("done")
        }catch(e){
            set_cartSpin(false)
            console.log("Data : ",e.response.data)
            if(e.response.data.message==="some_products_have_issues"){
                let problems = e.response.data.data.problematicProducts;
                let genText = ""

                problems.forEach(p=>{
                    genText += `محصول ${p.name} موجود نمباشد. `
                })

                return message.warn({
                    duration:7,
                    content:  genText,
                    className: 'toast-class'
                });
            }


            return message.error({
                content: "مشکل در ارسال درخواست",
                className: 'toast-class'
            });
        }
    }

    return(
        <div className="online-shop-container">
            <div className="online-shop-header">
                <div className="online-shop-header-cart clickable" onClick={e=>{
                    if(cart.length > 0){
                        showBasketDrawer()
                    }else{
                        return message.warn({
                            content: "لطفا یک محصول به سبد اضافه نمایید",
                            className: 'toast-class'
                        });
                    }

                }}>
                    <img src={cartIcon} alt=""/>
                    <div className="online-shop-header-cart-total">
                        <span>تومان</span>
                        <span>{` ${numberWithCommas(getPayableAmount(cart))}`}</span>
                    </div>

                    <div className="cart-badge">{getTotalItems(cart)}</div>
                </div>
                <h5>فروشگاه آنلاین</h5>
            </div>
            {productsRegal.length>0?
                <div className="swiper-page-wrapper">
                    <Swiper ref={MyRef}  {...paramsLink}>
                        {productsRegal.map((p,i)=>{
                            return(
                                <div className="product-swiper-product-card-wrapper">
                                    <div className="product-swiper-product-card">
                                        <div className="product-swiper-product-card-image" onClick={e=>{
                                            showDetailDrawer(p)
                                        }}>
                                            {p.image.length>0?
                                                <img src={Setup.filePoint+p.image} alt=""/>:
                                                <div className="image-swiper-placeholder">
                                                    <PictureOutlined/>
                                                </div>}
                                        </div>
                                        <div onClick={e=>{
                                            showDetailDrawer(p)
                                        }}>
                                            <div className="product-swiper-product-card-price">
                                                <span>{numberWithCommas(p.price-p.discount)}تومان</span>
                                            </div>

                                            <div className="product-swiper-product-card-discount">
                                                {p.discount>0?<span>{numberWithCommas(p.price)} تومان</span> :<span></span>}
                                            </div>


                                            <div className="product-swiper-product-card-name">
                                                <span>{p.fa_name.length>30?p.fa_name.substring(0,30)+"...":p.fa_name}</span>
                                            </div>
                                        </div>
                                        {p.stock>0 && checkItemCount(p._id)<1?
                                            <div className="product-swiper-product-card-add-btn clickable" onClick={e=>{
                                                handleAddItem(p)
                                            }}>
                                                <img src={plusProductIcon} alt=""/>
                                            </div>:null
                                        }
                                        {p.stock<1?
                                            <div className="out-of-stock">
                                                <span>ناموجود</span>
                                            </div>
                                            :null}

                                        {p.stock>1 && checkItemCount(p._id)>0?
                                            <div className="add-remove-section">
                                                <span className="add-section-btn clickable" onClick={e=>handleAddItem(p)}>+</span>
                                                <span className="count-section-btn">{checkItemCount(p._id)}</span>
                                                <span className="remove-section-btn clickable" onClick={e=>handleRemoveItem(p._id)}>-</span>
                                            </div>
                                            :null}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="product-swiper-product-card-wrapper"></div>
                    </Swiper>

                </div>:null
            }

            <div className="shop-footer-row">
                <span className="clickable" onClick={e=>{
                    showTrackDrawer()
                }}>پیگیری سفارشات</span>
                <span className="clickable" onClick={e=>{
                    showShopDrawer()
                }}>دیدن همه محصولات</span>
            </div>

            <Drawer destroyOnClose={true} height={"80vh"}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="bottom" onClose={closeTrackDrawer} visible={visibleTrackDrawer}>
                <Spin spinning={trackSpin}>
                    <div className="track-drawer">
                        <div className="track-drawer-title">
                            <h5>پیگیری سفارش</h5>
                        </div>

                        <div className="track-drawer-input">
                            <input
                                value={trackCode}
                                onKeyPress={e=>{if(e.key==="Enter"){handleTrackOrder()}}}
                                onChange={e=>set_trackCode(e.target.value)}
                                type="text" name="" id="" placeholder={"کد پیگیری را وارد نمایید"}/>
                        </div>
                        <div className="track-drawer-input">
                            <input
                                value={trackMobile}
                                onKeyPress={e=>{if(e.key==="Enter"){handleTrackOrder()}}}
                                onChange={e=>set_trackMobile(e.target.value)}
                                type="text" name="" id="" placeholder={"شماره موبایل سفارش دهنده"}/>
                        </div>

                        <div className="track-drawer-hint">
                            <span>لطفا کد پیگیری  و موبایل خود راوارد نمایید</span>
                        </div>

                        <div className="track-drawer-btn clickable" onClick={e=>{
                            handleTrackOrder()
                        }}>
                            <span>پیگیری سفارش</span>
                        </div>



                        {trackResult?.status?
                            <>
                                <div className={`track-drawer-state ${convertOrderStatusToColor(trackResult.status)}`}  >
                                    <span>{convertOrderStatusToName(trackResult.status)}</span>
                                    {trackResult.description?
                                        <div className="track-drawer-state-description">
                                            <p>{trackResult.description}</p>
                                        </div>:null
                                    }

                                </div>



                            </>:null
                        }

                        {trackResult?.cart?

                            <div className="track-drawer-cart">
                                <div className="cart-invoice-detail-price">
                                    <div className="cart-products-list">
                                        <div className="cart-products-row header-row">
                                            <div className="cart-products-item-price">
                                                <span>قیمت کل</span>
                                            </div>
                                            <div className="cart-products-item-quantity">
                                                <span>تعداد</span>
                                            </div>
                                            <div className="cart-products-item-discount">
                                                <span>تخفیف</span>
                                            </div>
                                            <div className="cart-products-item-original-price">
                                                <span>قیمت</span>
                                            </div>

                                            <div className="cart-products-item-name">
                                                <span>نام</span>
                                            </div>
                                        </div>
                                        {trackResult.cart.map(p=>{
                                            return(
                                                <div className="cart-products-row" onClick={e=>{
                                                    showDetailDrawer(p)
                                                }}>
                                                    <div className="cart-products-item-price">
                                                        <span>{numberWithCommas((p.price-p.discount)*p.quantity)}</span>
                                                    </div>
                                                    <div className="cart-products-item-quantity">
                                                        <span>{p.quantity}</span>
                                                    </div>
                                                    <div className="cart-products-item-discount">
                                                        <span>{numberWithCommas(p.discount)}</span>
                                                    </div>
                                                    <div className="cart-products-item-original-price">
                                                        <span>{numberWithCommas(p.price)}</span>
                                                    </div>

                                                    <div className="cart-products-item-name">
                                                        <span>{p.fa_name.length>30?p.fa_name.substring(0,25)+"...":p.fa_name}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>


                                    <div className="cart-invoice-detail-price-item">
                                        <div className="cart-invoice-detail-price-item-value">{numberWithCommas(getTotalPrice(trackResult.cart))} تومان</div>
                                        <div className="cart-invoice-detail-price-item-label">مبلغ محصولات</div>
                                    </div>
                                    <div className="cart-invoice-detail-price-item">
                                        <div className="cart-invoice-detail-price-item-value">{numberWithCommas(getTotalDiscount(trackResult.cart))} تومان</div>
                                        <div className="cart-invoice-detail-price-item-label">تخفیف محصولات</div>
                                    </div>
                                    <div className="cart-invoice-detail-price-item-total">
                                        <h5>{numberWithCommas(getPayableAmount(trackResult.cart))} تومان</h5>
                                        <span>مبلغ  کل</span>
                                    </div>

                                </div>
                            </div>

                            :null}


                        {page?.shop?.description.length>0?<div className="track-drawer-support-mobile">
                            <span>{page.shop.description}</span>
                        </div>:null}
                    </div>

                </Spin>
            </Drawer>


            {/*Detail Drawer*/}
            <Drawer destroyOnClose={true} width={"200px"} height={"80vh"}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="bottom" onClose={closeDetailDrawer} visible={visibleDetailDrawer}>
                {selectProduct?
                    <div className="shop-product-detail">

                        <div className="shop-product-detail-image">
                            {selectProduct.image.length>0?
                                <img src={Setup.filePoint+selectProduct.image} alt=""/>:
                                <div className="shop-product-detail-image-placeholder">
                                    <PictureOutlined/>
                                </div>
                            }
                        </div>

                        <div className="shop-product-detail-price">
                            <span>{numberWithCommas(selectProduct.price-selectProduct.discount)}</span>
                            <span>تومان</span>
                        </div>
                        {selectProduct.discount>0?
                            <div className="shop-product-detail-discount">
                                <span>{numberWithCommas(selectProduct.price)}</span>
                                <span>تومان</span>
                            </div>:null
                        }

                        <div className="shop-product-detail-label">
                            <span>{selectProduct.label}</span>
                        </div>

                        <div className="shop-product-detail-faname">
                            <span>{selectProduct.fa_name}</span>
                        </div>
                        <div className="shop-product-detail-enname">
                            <span>{selectProduct.en_name}</span>
                        </div>

                        <div className="shop-product-detail-product-description">
                            <span>{selectProduct.product_description}</span>
                        </div>

                        <div className="shop-product-detail-owner-description">
                            <span>{selectProduct.owner_description}</span>
                        </div>

                        <br/>
                        {checkItemCount(selectProduct._id)>0?
                            <div className="add-remove-section">
                                <span className="remove-section-btn clickable" onClick={e=>handleRemoveItem(selectProduct._id)}>-</span>
                                <span className="count-section-btn">{checkItemCount(selectProduct._id)}</span>
                                <span className="add-section-btn clickable" onClick={e=>handleAddItem(selectProduct)}>+</span>
                            </div>
                            :null}
                        {selectProduct.stock>0?<div className="shop-product-detail-add-btn clickable" onClick={e=>handleAddItem(selectProduct)}>
                            <span>افزودن به سبد خرید</span>
                        </div>:null}

                        {selectProduct.stock<1?<div className="shop-product-detail-out-of-stock">
                            <span>ناموجود</span>
                        </div>:null}

                    </div>:null
                }

            </Drawer>

            {/*Shop Drawer*/}
            <Drawer title={"فروشگاه آنلاین"} destroyOnClose={true} height={"80vh"}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="bottom" onClose={closeShopDrawer} visible={visibleShopDrawer}>
                <div className="shop-product-list">
                    {products.map(p=>{
                        return(
                            <div className="shop-product-list-item">
                                <ShopProductCard handleRemoveItem={handleRemoveItem} handleAddItem={handleAddItem} checkItemCount={checkItemCount} showDetailDrawer={showDetailDrawer} p={p}/>
                            </div>
                        )
                    })}
                </div>
            </Drawer>

            {/*Basket Drawer*/}
            <Drawer destroyOnClose={true} height={"80vh"} title={"نهایی کردن سفارش"}   bodyStyle={{padding:0,margin:0}} closable={false}  placement="bottom" onClose={closeBasketDrawer} visible={visibleBasketDrawer}>
                <Spin spinning={cartSpin}>
                    {cartMode==="done"?
                        <div>
                            <div className="cart-done-image">
                                <img src={cartCheckedIcon} alt=""/>
                            </div>
                            <div className="cart-done-hint">
                                <p>پرداخت شما بررسی شده و در صورت تایید شدن سفارش شما در فرایند ارسال قرار میگیرد</p>
                            </div>
                            <div className="cart-done-track-code">
                                <h5>{refNumber}</h5>
                                <CopyToClipboard text={refNumber}
                                                 onCopy={e=>{
                                                     message.success({
                                                         content: `شماره پیگیری کپی شد`,
                                                         className: 'toast-class'
                                                     });
                                                 }}>
                                    <span className="clickable">کپی شماره پیگیری</span>
                                </CopyToClipboard>

                            </div>

                            <div className="cart-done-guid">
                                <p>برای آگاهی از وضعیت سفارش خود میتوانید از کد پیگیری بالا استفاده نمایید , همینطور لینک پیگیری به شماره موبایل شما ارسال شد</p>
                            </div>
                        </div>
                        :null}
                    {cartMode==="default"?
                        <div className="cart-invoice-container">

                            <div className="cart-invoice-detail-price">
                                <div className="cart-invoice-detail-price-item">
                                    <div className="cart-invoice-detail-price-item-value">{numberWithCommas(getTotalPrice(cart))} تومان</div>
                                    <div className="cart-invoice-detail-price-item-label">مبلغ محصولات</div>
                                </div>
                                <div className="cart-invoice-detail-price-item">
                                    <div className="cart-invoice-detail-price-item-value">{numberWithCommas(getTotalDiscount(cart))} تومان</div>
                                    <div className="cart-invoice-detail-price-item-label">تخفیف محصولات</div>
                                </div>
                                <div className="cart-invoice-detail-price-item-total">
                                    <h5>{numberWithCommas(getPayableAmount(cart))} تومان</h5>
                                    <span>مبلغ قابل پرداخت</span>
                                </div>

                                <div className="cart-invoice-detail-price-item-see-detail clickable" onClick={e=>{
                                    set_collapseCartProduct(!collapseCartProduct)
                                }}>
                                    <span>{!collapseCartProduct?"دیدن اقلام":"عدم دیدن اقلام"}</span>
                                    <img
                                        src={caretDownIcon}
                                        alt=""
                                        className={collapseCartProduct ? "rotate" : ""}/>
                                </div>

                                <Collapse isOpened={collapseCartProduct}>
                                    <div className="cart-products-list">
                                        <div className="cart-products-row header-row">
                                            <div className="cart-products-item-price">
                                                <span>قیمت کل</span>
                                            </div>
                                            <div className="cart-products-item-quantity">
                                                <span>تعداد</span>
                                            </div>
                                            <div className="cart-products-item-discount">
                                                <span>تخفیف</span>
                                            </div>
                                            <div className="cart-products-item-original-price">
                                                <span>قیمت</span>
                                            </div>

                                            <div className="cart-products-item-name">
                                                <span>نام</span>
                                            </div>
                                        </div>
                                        {cart.map(p=>{
                                            return(
                                                <div className="cart-products-row" onClick={e=>{
                                                    let find = products.find(f=>f._id===p._id)
                                                    if(find)
                                                    showDetailDrawer(find)
                                                }}>
                                                    <div className="cart-products-item-price">
                                                        <span>{numberWithCommas((p.price-p.discount)*p.quantity)}</span>
                                                    </div>
                                                    <div className="cart-products-item-quantity">
                                                        <span>{p.quantity}</span>
                                                    </div>
                                                    <div className="cart-products-item-discount">
                                                        <span>{numberWithCommas(p.discount)}</span>
                                                    </div>
                                                    <div className="cart-products-item-original-price">
                                                        <span>{numberWithCommas(p.price)}</span>
                                                    </div>

                                                    <div className="cart-products-item-name">
                                                        <span>{p.fa_name.length>30?p.fa_name.substring(0,25)+"...":p.fa_name}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </div>



                            <div className="cart-invoice-owner-pay-info">
                                <h5>{page?.shop?.title}</h5>
                                <div className="cart-invoice-owner-pay-info-footer">
                                    <span>کارت بانکی</span>
                                    <span>{page?.shop?.address}</span>
                                </div>
                            </div>


                            <div className="cart-invoice-hint">
                                <h5>نهایی کردن سفارش</h5>
                                <p>لطفا مبلغ قابل پرداخت را به شماره کارت بالا واریز نموده و اطلاعات زیر را تکمیل کنید</p>
                            </div>

                            <div className="cart-invoice-input">
                                <div className="cart-invoice-input-label">شماره پیگیری</div>
                                <input
                                    value={customerPayRef}
                                    onChange={e=>set_customerPayRef(e.target.value)}
                                    type="text" name="" id="" placeholder="مثلا : 1354698987985"/>
                            </div>

                            <div className="cart-invoice-input">
                                <div className="cart-invoice-input-label">نام و نام خانوادگی واریز کننده</div>
                                <input
                                    value={fullName}
                                    onChange={e=>set_fullName(e.target.value)}
                                    type="text" name="" id="" placeholder="مثلا : علی رحیمی"/>
                            </div>

                            <div className="cart-invoice-input">
                                <div className="cart-invoice-input-label">موبایل واریز کننده</div>
                                <input
                                    value={mobile}
                                    onChange={e=>set_mobile(e.target.value)}
                                    type="text" name="" id="" placeholder="مثلا : 09121111111"/>
                            </div>

                            <div className="cart-invoice-input">
                                <div className="cart-invoice-input-btn clickable" onClick={e=>{
                                    handleSendOtp()
                                }}>
                                    دریافت کد تایید
                                </div>
                                <input
                                    value={otp}
                                    onChange={e=>set_otp(e.target.value)}
                                    type="text" name="" id="" placeholder="مثلا : 1234"/>
                            </div>

                            <div className="cart-invoice-input">
                                <div className="cart-invoice-input-label">آدرس</div>
                                <textarea
                                    value={address}
                                    onChange={e=>set_address(e.target.value)}
                                    placeholder="مثلا : تهران - بلوار ادمان - پلاک 540 - واحد 12 " name="" id="" cols="30" rows="10">
                                rows={2}
                        </textarea>
                            </div>
                            <div className="cart-invoice-btn" >
                                <div className="cart-invoice-submit clickable" onClick={e=>{
                                    sendCart()
                                }}>ارسال اطلاعات</div>
                            </div>
                        </div>:null
                    }
                </Spin>
            </Drawer>
        </div>
    )
}

export default OnlineShop;
