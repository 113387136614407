import React, {useEffect, useRef, useState} from 'react';
import "./DumbbellTrainerMenu.scss";
import sampleTrainerFace from "../../assets/dumbbell_image/sample-trainer-face.png";
import starOffIcon from "../../assets/dumbbell_image/star-off.png";
import starLightIcon from "../../assets/dumbbell_image/star-light.png";
import requestIcon from "../../assets/dumbbell_image/V2/menu/request_menu_icon.svg";
import pageIcon from "../../assets/dumbbell_image/V2/menu/page_menu_icon.svg";
import traineeIcon from "../../assets/dumbbell_image/V2/menu/trainee_menu_icon.svg";
import videoIcon from "../../assets/dumbbell_image/V2/menu/video_menu_icon.svg";
import packageIcon from "../../assets/dumbbell_image/V2/menu/package_menu_icon.svg";
import orderIcon from "../../assets/dumbbell_image/V2/OrderIcon1.svg";
import bilingIcon from "../../assets/dumbbell_image/V2/menu/biling_menu_icon.svg";
import bookingIcon from "../../assets/dumbbell_image/V2/menu/booking_menu_icon.svg";
import designIcon from "../../assets/dumbbell_image/V2/menu/design_menu_icon.svg";
import {getTokenFromFirebase} from "../../firebase";

import { v4 as uuidv4 } from 'uuid';
// import traineeIcon from "../../assets/dumbbell_image/trainee-menu-icon.png";
import playIcon from "../../assets/dumbbell_image/play-menu-icon.png";
// import pageIcon from "../../assets/dumbbell_image/page-menu-icon.png";
import payIcon from "../../assets/dumbbell_image/pay-menu-icon.png";
import logoutIcon from "../../assets/dumbbell_image/logout-menu-icon.png";
import sampleFace from "../../assets/dumbbell_image/sample-big-face.png";
import defaultUser from "../../assets/images/default_user.png";
import {useHistory} from "react-router";
import {
    getDesignPlan_api,
    getTrainerProfileApi,
    sendPushToken_api, sendPushToken_trainer_api,
    trainerThumbUploadApi,
    videoUploadApi
} from "../../Services/Services";
import {message, Spin} from "antd";
import {Setup} from "../../Services/Setup";
import {checkFileSize} from "../../Services/utils";
import UploadLoading from "../../Components/UploadLoading/UploadLoading";
import {useUploadLoading} from "../../Hook/useUploadLoading";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";

Array.prototype.contains = function(obj) {
    let i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

function DumbbellTrainerMenu({close}){

    const history = useHistory();
    const [user,set_user] = useState(null)
    const [plan_design,set_plan_design] = useState(null)
    const setUploadLoading = useDispatchUploadLoading()
    const [noticeStatus,set_noticeStatus] = useState(window.Notification.permission) //default , granted , denied
    const [thumbUid,set_thumbUid] = useState(uuidv4()) //default

    useEffect(e=>{
        getUser()
        getPlanDesign()
    },[])

    const getPlanDesign = async ()=>{
        try{
            const resp = await getDesignPlan_api()
            set_plan_design(resp.data.data)
        }catch (e) {
            console.log(e)
        }
    }

    const getUser = ()=>{
        const user = localStorage.getItem("user");
        if(user)
            set_user(JSON.parse(user))
    }

    const goTo = (path)=>{
        history.push(path)
        close()
    }
    const logout = ()=>{
        localStorage.clear()
        history.push("/login-trainer")
    }

    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);

    //show menu item
    const [menuItems,set_menuItems] = useState({
        my_requests:false,
        my_page:false,
        my_package:false,
        my_booking:false,
        my_trainee:false,
        my_videos:false,
        my_billing:false,
        my_step:false
    })

    const [menuSpin,set_menuSpin] = useState(false)
    useEffect(()=>{
        getServices()
    },[])

    const getServices = async()=>{
        set_menuSpin(true)
        try{
            const response =await getTrainerProfileApi()
            set_menuSpin(false)
            let services = response.data.data.services
            let menuPermission = {}
            if(!services.contains("booking") && services.contains("plan")){
                menuPermission={
                    my_requests:true,
                    my_page:true,
                    my_package:true,
                    my_booking:false,
                    my_trainee:true,
                    my_videos:true,
                    my_billing:true,
                    my_step:true
                }
            }
            if(services.contains("booking") && !services.contains("plan")){
                menuPermission={
                    my_requests:true,
                    my_page:false,
                    my_package:false,
                    my_booking:true,
                    my_trainee:false,
                    my_videos:false,
                    my_billing:true,
                    my_step:true
                }
            }
            if(services.contains("booking") && services.contains("plan")){
                menuPermission={
                    my_requests:true,
                    my_page:true,
                    my_package:true,
                    my_booking:true,
                    my_trainee:true,
                    my_videos:true,
                    my_billing:true,
                    my_step:true
                }
            }
            if(!services.contains("booking") && !services.contains("plan")){
                menuPermission={
                    my_requests:false,
                    my_page:false,
                    my_package:false,
                    my_booking:false,
                    my_trainee:false,
                    my_videos:false,
                    my_billing:false,
                    my_step:true
                }
            }
            set_menuItems(menuPermission)
        }catch (e) {
            set_menuSpin(false)
            console.log(e)
        }
    }

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])


    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }
    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
            setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
            setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[thumbUploadProgress])


    const uploadThumb = async ()=>{
        set_spin(true)
        if(imageFile){
            try{
                let response = await trainerThumbUploadApi(imageFile,set_thumbUploadProgress);
                set_user(response.data.data)
                localStorage.setItem('user', JSON.stringify(response.data.data))
                set_spin(false)
                set_imagePrev(null)
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
            }catch(e){
                set_spin(false)
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                console.log(e)
            }
        }
    }

    const getT = async ()=>{
        let token = await getTokenFromFirebase()
        console.log("fcm : ",token)
        sendPushToken_trainer_api(token)
    }


    return(
        <div className="dumbbell-menu-container">
            {/*<UploadLoading percent={thumbUploadProgress} visible={true} title={`در حال آپلود تصویر . . .`} />*/}
            <Spin spinning={spin}>
            <div className="dumbbell-menu-image" onClick={handleClickItems}>
                <div className="dumbbell-menu-image-sec">
                    {imagePrev?
                        <img src={imagePrev} alt=""/>:
                        <>
                            {user?.image?
                                <img src={Setup.filePoint+user?.image+`?id=${thumbUid}`} alt=""/>
                                :
                                <img src={defaultUser} alt=""/>
                            }
                        </>
                    }

                </div>
            </div>
            </Spin>
            {imagePrev?<div className="dumbbell-menu-image-upload-btn">
                <span onClick={e=>{
                    uploadThumb()
                }}>آپلود تصویر</span>
            </div>:null}

            {user?.first_name?
                <div className="dumbbell-menu-name">
                    <span>{user.first_name+" "+user.last_name}</span>
                    <span>{user.mobile}</span>
                    {/*<div className="dumbbell-header-star">*/}
                    {/*    <img src={starOffIcon} alt=""/>*/}
                    {/*    <img src={starOffIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*</div>*/}
                </div>:null
            }

            <br/>

            {!menuSpin?
                <div className="menu-list">

                    {/*<div className="notifications-sec">*/}
                    {/*    {noticeStatus==="default"?<span onClick={()=>{*/}
                    {/*        getT()*/}
                    {/*    }} className="notification-btn">درخواست فعال کردن نوتیفیکیشن</span>:null}*/}
                    {/*    {noticeStatus==="granted"?<span className="notification-label">نوتیفیکیشن برای شما فعال است</span>:null}*/}
                    {/*    {noticeStatus==="denied"?<span className="notification-btn">نحوه فعال کردن نوتیفیکیشن</span>:null}*/}
                    {/*</div>*/}

                    {menuItems?.my_requests?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/my-requests")}}>
                            <span>درخواست های من</span>
                            <div className="img-sec">
                                <img src={requestIcon} alt=""/>
                            </div>
                        </div>:
                        null
                    }

                    {menuItems?.my_page?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/page-edit")}}>
                            <span>صفحه من</span>
                            <div className="img-sec">
                            <img src={pageIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_package?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/products")}}>
                            <span>محصول های من</span>
                            <div className="img-sec">
                                <img src={orderIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_package?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/packages")}}>
                            <span>پکیج های من</span>
                            <div className="img-sec">
                            <img src={packageIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_trainee?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo(`/trainer/plan-add/${plan_design?.trainee_id}/${plan_design?._id}`)}}>
                            <span>طراحی برنامه</span>
                            <div className="img-sec">
                                <img style={{width:54,marginLeft:-5}} src={designIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_booking?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/booking-management")}}>
                            <span>نوبت دهی</span>
                            <div className="img-sec">
                            <img src={bookingIcon} alt=""/>
                            </div>
                        </div>:null}


                    {menuItems?.my_trainee?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/my-trainee")}}>
                            <span>شاگردان من</span>
                            <div className="img-sec">
                            <img src={traineeIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_videos?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/video-list")}}>
                            <span>ویدیوهای من</span>
                            <div className="img-sec">
                            <img src={videoIcon} alt=""/>
                            </div>
                        </div>:null}

                    {menuItems?.my_billing?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainer/pay-list")}}>
                            <span>پرداخت های من</span>
                            <div className="img-sec">
                            <img src={bilingIcon} alt=""/>
                            </div>
                        </div>:null}

                    {user?.role==="roox"?
                        <div className="dumbbell-menu-item" onClick={e=>{goTo("/admin-trainers")}}>
                            <span>لیست مربیان</span>
                            <div className="img-sec">
                                <img src={pageIcon} alt=""/>
                            </div>
                        </div>:null}


                    {/*<div className="dumbbell-menu-item" onClick={logout}>*/}
                    {/*    <span>خروج</span>*/}
                    {/*    <img src={logoutIcon} alt=""/>*/}
                    {/*</div>*/}

                    {menuItems?.my_step?
                        <div className="step-menu-btn" onClick={e=>{goTo("/my-step")}}>
                            <span className="clickable">استپ من</span>
                        </div>:null}

                </div>:
                <div className="menu-loading">
                    <span style={{width:40,height:40,border:"1px solid #8d89fd", borderBottomColor: "transparent"}} className="loader"></span>
                </div>
            }



            <input type="file" ref={fileRef}
                   accept=".png, .jpg, .jpeg"
                   name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
        </div>
    )
}

export default DumbbellTrainerMenu;

