import {API} from "./Api";
import {Setup} from "./Setup";
import moment from "moment-jalaali";





export const verifyOrganApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/organ-verify`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}


export const getDestinationGroupApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/destination-groups`,{
        type:type
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const getDestinationUsersApi = (word,type)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/destination-users`,{
        word:word,
        type:type
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const checkMeetReserveApi = (startAt,endAt)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/check-meet`,{
        startAt:startAt,
        endAt:endAt
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const transferApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/transaction-media`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}

export const challengeMediaApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/challenge`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}

export const notificationApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}


export const createMeetApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/meet-media`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}


export const permissionApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/permissions`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const seenMediaApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/seen/${id}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}

export const testApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/rei`,{},{
        headers:{
            "x-access-token":token
        }
    })
}


export const surveyMediaApi = (inquiryId,inquiryItemId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/inquiry/${inquiryId}/item/${inquiryItemId}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}

export const setModeApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/mode`,{type},{
        headers:{
            "x-access-token":token
        }
    })
}


export const likeMediaApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/like/${id}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}



export const challMediaApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/challenged/${id}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}

export const dislikeMediaApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/dislike/${id}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}





export const challengeImageUploadApi = (obj,challengeId)=>{
    const token = localStorage.getItem("token")

    const formData = new FormData();

    // Update the formData object
    formData.append("image", obj, obj.name);
    formData.append("challengeId", challengeId);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/challenges/image`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}



export const imageUploadApi = (obj,mediaId)=>{
    const token = localStorage.getItem("token")

    const formData = new FormData();

    // Update the formData object
    formData.append("image", obj, obj.name);
    formData.append("mediaLink", mediaId);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/upload-image`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}


export const voiceUploadApi = (obj,mediaId)=>{
    const token = localStorage.getItem("token")

    const formData = new FormData();

    // Update the formData object
    formData.append("voice", obj, obj.name);
    formData.append("mediaLink", mediaId);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/upload-voice`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}



export const profileApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/profile`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const userProfileApi = (targetId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/user-detail-client`,{
        targetId:targetId
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const favoritesApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/favorites`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const deleteFavorite_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/favorites/${id}`,{
        headers:{
            "x-access-token":token
        }
    })
}


export const sendFavorite_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/favorites`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}


export const getOwnMedia = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/own`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type
        }
    })
}





export const getAiAnalyze_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/${Setup.app_name}-ai-analyze/${id}`,{
        headers:{"x-access-token":token}
    })
}


export const getMediasApi = (type,page=0)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type,
            page:page
        }
    })
}


export const getNewMediaCountApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/seen/new`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type
        }
    })
}

export const getTransactionApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/transaction`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type
        }
    })
}


export const getPackageApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/packages`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type
        }
    })
}



export const getBuyerListApi = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/shopping-list`,{
        headers:{
            "x-access-token":token
        }
    })
}



export const statisticOfOperationApi = (groups=[])=>{
    const token = localStorage.getItem("token")
    let startYear = moment(new Date()).format("jYYYY")
    let intYear = parseInt(startYear)

    let stringDate  = `${startYear}-01-01`
    let endDate  = `${intYear+1}-01-01`

    let startMiladi = moment(stringDate,"jYYYY-jMM-jDD").format("YYYY-MM-DD")
    let endMiladi = moment(endDate,"jYYYY-jMM-jDD").format("YYYY-MM-DD")

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/statistic-operation`,{
        groupId:"61563b85a0a2041374ac5efe",
        groups:groups,
        start:startMiladi,
        end:endMiladi
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const countOfOperationApi = ()=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/count-operation`,{
        groupId:"61563b85a0a2041374ac5efe"
    },{
        headers:{
            "x-access-token":token
        }
    })
}




export const getChartEmotionApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/chart-emotion`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const getUserOperationsApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/operations`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getUserOperationsSumApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/operations-sum`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}






export const getEmotionUserApi = (userId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/statistic-emotions-user`,{
        start:start,
        end:end,
        userTarget:userId
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const getOperationUserApi = (page=null,userId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/operations-user`,{
        start:start,
        end:end,
        userTarget:userId
    },{
        headers:{
            "x-access-token":token
        },
        params:{
            page:page
        }
    })
}






export const getKpiUserDetail_api = (groupId=null,start=null,end=null,kpiId=null,targetId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-user-report-detail-client`,{
        groupId:groupId,
        start:start,
        end:end,
        kpiId:kpiId,
        targetId:targetId
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const getKpiDetail_api = (groupId=null,start=null,end=null,kpiId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-main-report-detail-client`,{
        groupId:groupId,
        start:start,
        end:end,
        kpiId:kpiId
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiMainApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-main-report-client`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiUserApi = (groupId=null,start=null,end=null,targetId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-user-report-client`,{
        groupId:groupId,
        start:start,
        end:end,
        targetId:targetId
    },{
        headers:{
            "x-access-token":token
        }
    })
}




export const getOperationChartApi = (groupId=[],start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/chart-operation`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const getEmotionChartApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/chart-emotion`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}






export const getStatistic = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/statistic-emotions`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getStatisticSum = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/statistic-emotions-sum`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getEmotionCurrentList = (groups=[])=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/current-emotions`,{groups:groups},{
        headers:{
            "x-access-token":token
        }
    })
}

export const getGroupsApi = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/report-groups`,{
        headers:{
            "x-access-token":token
        }
    })
}


export const getUsersListApi = (group)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/users-list`,{
        group:group,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getUserApi = (target)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/report-profile`,{
        params:{
          target:target
        },
        headers:{
            "x-access-token":token
        }
    })
}


export const buyApi = (packageId,wallet)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/buy/${packageId}`,{valList:wallet},{
        headers:{
            "x-access-token":token
        }
    })
}




export const getChallengeApi = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/challenges`,{
        headers:{
            "x-access-token":token
        },
        params:{
            type:type
        }
    })
}


export const getTikApi = ()=>{
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/tik`)
}


export const getUserWelcome_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/welcome-login`,{
        welcomeId:id
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiPositiveList_api = (group)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-positive-list`,{
        group:group,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiNegativeList_api = (group)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-negative-list`,{
        group:group,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiNegativeListWithUser_api = (userId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-negative-list-one`,{
        one:userId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const getKpiPositiveListWithUser_api = (userId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/kpi-positive-list-one`,{
        one:userId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const cancelMeet_api = (mediaId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/meet-cancel`,{
        mediaId:mediaId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const rejectMeet_api = (mediaId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/meet-reject`,{
        mediaId:mediaId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const acceptMeet_api = (mediaId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/meet-accept`,{
        mediaId:mediaId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export const meetLogin_api  = (mediaId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/meet-login`,{
        mediaId:mediaId,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const mediaDeleteApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/${id}`,{
        headers:{
            "x-access-token":token
        }
    })
}


export const mediaChallengeDeleteApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/challenge-media/${id}`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const mediaRedDeleteApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/red-media/${id}`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const HealthCheckApi = ()=>{
    return API.get(`${Setup.endpoint}`)
}

export const sendPushToken_trainer_api = (fcmToken)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/send-fcm-trainer`,{
        fcmToken:fcmToken,
    },{
        headers:{
            "x-access-token":token
        }
    })
}
export const sendPushToken_trainee_api = (fcmToken)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/send-fcm-trainee`,{
        fcmToken:fcmToken,
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const sendFeedback_api = (rate,mediaId,type)=>{
    const token = localStorage.getItem("token")
    console.log("VVVV : ",rate)
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/user/media/feedback/${mediaId}`,{
        type:type,
        rate:rate
    },{
        headers:{
            "x-access-token":token
        }
    })
}



//Dumbbell Api

export const traineeLoginApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-new-login`,obj)
}

export const traineeVerifyApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-new-verify`,obj)
}


export const loginApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer-new-login`,obj)
}

export const verifyApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer-new-verify`,obj)
}

export const trainerWelcomeApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer-welcome`,obj)
}

export const traineeWelcomeApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-welcome`,obj)
}

//Trainee
export const traineeListApi = (page,search,type="all")=>{
    const token = localStorage.getItem("token");
    if(search.length<1)
        search = null


    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee`,{
        headers:{"x-access-token":token},
        params:{
            page,
            search,
            type:type==="all"?null:type
        }
    })
}

export const addTraineeApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee`,obj,{
            headers:{"x-access-token":token}
        })
}

export const editTraineeApi = (obj,id)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}


export const payLandPlanApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/page-request-plan`,obj,{
        headers:{"x-access-token":token}
    })
}

export const payLandBooingApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/page-request-booking`,obj,{
        headers:{"x-access-token":token}
    })
}


export const getTraineeApi = (traineeId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee/${traineeId}`,{
        headers:{"x-access-token":token}
    })
}


export const planOneApi = (traineeId,planId)=>{
    console.log("Params 2: -------- ",planId,traineeId);
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/plans/one/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const printOneApi = (traineeId,planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/plans/print/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const printOneTraineeApi = (traineeId,planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/plans/print-trainee/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const changePlanModeApi = (planId,mode)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/plan-change-mode/${planId}`,{mode},{
        headers:{"x-access-token":token}
    })
}

export const getLib_api = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/lib`,{
        headers:{"x-access-token":token}
    })
}


export const getDesignPlan_api = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/design-plan`,{
        headers:{"x-access-token":token}
    })
}

export const deleteLibItem_api = (Lid)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/lib/${Lid}`,{
        headers:{"x-access-token":token}
    })
}


export const myPlanOneApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/my-plans/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const myGuestPlanOneApi = (planId)=>{
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/my-guest-plans/${planId}`)
}

//Plan
export const planListApi = (traineeId,page,search="")=>{
    const token = localStorage.getItem("token");
    if(search.length<1)
        search = null

    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/plans/${traineeId}`,{
        headers:{"x-access-token":token},
        params:{page,search}
    })
}

export const traineePlanListApi = (page,search="")=>{
    const token = localStorage.getItem("token");
    if(search.length<1)
        search = null

    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/my-plans`,{
        headers:{"x-access-token":token},
        params:{page,search}
    })
}

export const getMyProfileApi = (page,search="")=>{
    const token = localStorage.getItem("token");
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-profile`,{
        headers:{"x-access-token":token}
    })
}

export const getTrainerProfileApi = (page,search="")=>{
    const token = localStorage.getItem("token");
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer-profile`,{
        headers:{"x-access-token":token}
    })
}

export const getDebtApi = ()=>{
    const token = localStorage.getItem("token");
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/debt`,{
        headers:{"x-access-token":token}
    })
}


export const getRewardApi = ()=>{
    const token = localStorage.getItem("token");
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/rewards`,{
        headers:{"x-access-token":token}
    })
}

export const getReportPage_api = (startAt,endAt)=>{
    const token = localStorage.getItem("token");
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/report/page`,{
        params:{
            startAt,
            endAt
        },
        headers:{"x-access-token":token}
    })
}



export const buyRewardApi = (id)=>{
    const token = localStorage.getItem("token");
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/rewards-buy/${id}`,{},{
        headers:{"x-access-token":token}
    })
}

export const checkoutDebt_api = (id)=>{
    const token = localStorage.getItem("token");
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/checkout-debt`,{},{
        headers:{"x-access-token":token}
    })
}

export const checkoutForceDebt_api = (id)=>{
    const token = localStorage.getItem("token");
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/checkout-force-debt`,{},{
        headers:{"x-access-token":token}
    })
}



export const savePlanApi = (obj,planId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/plan/${planId}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const saveAndSendPlanApi = (obj,planId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/plan-send/${planId}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const sendRequestPlanInvoiceApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/send-plan-invoice`,obj,{
        headers:{"x-access-token":token}
    })
}

export const createPackage_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/step/packages`,obj,{
        headers:{"x-access-token":token}
    })
}

export const deletePackage_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/step/packages/${id}`,{
        headers:{"x-access-token":token}
    })
}

export const editPackage_api = (obj,id)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/step/packages/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const listPackage_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/step/packages`,{
        headers:{"x-access-token":token}
    })
}

export const payMySelfPlanInvoiceApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee`,obj,{
        headers:{"x-access-token":token}
    })
}

export const viewInvoiceApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/view-plan-invoice/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const viewInvoiceAuthorityApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/view-plan-invoice-authority/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const verifyDebtApi = (authId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/verify-debt/${authId}`,{
        headers:{"x-access-token":token}
    })
}

export const viewInvoiceWithPlanApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/view-plan-invoice/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const payInvoiceApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/pay-plan-invoice/${planId}`)
}

export const verifyInvoiceApi = (authority)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/verify-plan-invoice/${authority}`)
}

//land pay


export const landViewInvoiceAuthorityApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/land-view-plan-invoice-authority/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const bookingAuthorityVerifyApi = (authority,memberId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/booking-verify-pay/${authority}`,{memberId},{
        headers:{"x-access-token":token}
    })
}

export const landViewInvoiceRequestApi = (requestId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/land-view-plan-invoice-request/${requestId}`,{
        headers:{"x-access-token":token}
    })
}
export const completeRequestApi = (requestId,obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/land-complete-request/${requestId}`,obj,{
        headers:{"x-access-token":token}
    })
}



export const landVerifyInvoiceApi = (authority)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/land-verify-plan-invoice/${authority}`)
}
//chat
export const trainerChatListApi = (page=0,traineeId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/chat/${traineeId}`,{
        params:{page},
        headers:{"x-access-token":token}
    })
}

export const traineeChatListApi = (page=0,traineeId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-chat/${traineeId}`,{
        params:{page},
        headers:{"x-access-token":token}
    })
}

export const trainerChatSendMessageApi = (traineeId,body)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/chat-send/${traineeId}`,{body},{
        headers:{"x-access-token":token}
    })
}

export const traineeChatSendMessageApi = (traineeId,body)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-chat-send/${traineeId}`,{body},{
        headers:{"x-access-token":token}
    })
}

export const trainerChatSeenMessageApi = (messageId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/seen-message/${messageId}`,{},{
        headers:{"x-access-token":token}
    })
}

export const planSeenApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/plan/seen/${planId}`,{},{
        headers:{"x-access-token":token}
    })
}

export const bookSeenApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/book/seen/${planId}`,{},{
        headers:{"x-access-token":token}
    })
}

export const bookSeenFromCalendarApi = (linkId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/book/seen-from-calendar/${linkId}`,{},{
        headers:{"x-access-token":token}
    })
}




export const traineeChatSeenMessageApi = (messageId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee-seen-message/${messageId}`,{},{
        headers:{"x-access-token":token}
    })
}


//box



export const getBoxApi = (traineeId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/box/${traineeId}`,{
        headers:{"x-access-token":token}
    })
}

export const getBoxTraineeApi = (trainerId)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/box-trainee/${trainerId}`,{
        headers:{"x-access-token":token}
    })
}

export const deleteBoxItemApi = (traineeId,index)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/box-remove/${traineeId}/${index}`,{},{
        headers:{"x-access-token":token}
    })
}

export const deleteTraineeBoxItemApi = (trainerId,index)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/box-trainee-remove/${trainerId}/${index}`,{},{
        headers:{"x-access-token":token}
    })
}


//Land

export const getLandApi = (slug)=>{
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/page/${slug}`,{
        params:{referrer:window.frames.top.document.referrer}
    })
}
export const getProductsApi = (slug)=>{
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/products-land/${slug}`)
}
export const getProductsRegalApi = (slug)=>{
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/products-regal/${slug}`)
}



export const getLandPackage_api = (slug)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/step/land/packages/${slug}`,{
        headers:{"x-access-token":token}
    })
}

export const getMyPageApi = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/page`,{
        headers:{"x-access-token":token}
    })
}


export const deleteLinkImage = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/land-link-delete-image`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}

export const updatePageLink = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/land-link-update`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}

export const uploadLinkImage = (obj,updateProgress)=>{
    const token = localStorage.getItem("token")
    const formData = new FormData();
    formData.append("image", obj.image, obj.image.name);
    formData.append("order", obj.order);
    formData.append("type", obj.type);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/land-link-upload-image`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}






export const editLandApi = (obj,pageId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/page/${pageId}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const boxTraineeImageUploadApi = (obj,trainerId,index)=>{

    const token = localStorage.getItem("token")

    const formData = new FormData();

    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/box-trainee-upload/${trainerId}/${index}`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}


export const boxImageUploadApi = (obj,traineeId,index)=>{

    console.log(obj,traineeId,index)
    const token = localStorage.getItem("token")

    const formData = new FormData();

    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/box-upload/${traineeId}/${index}`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}



export const videoEditTitleApi = (id,title)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1//video-edit-title/${id}`,{title},{
        headers:{"x-access-token":token}
    })
}

export const videoEditApi = (id,vid,obj,title,cat,updateProgress)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();

    formData.append("image", obj, obj.name);
    formData.append("title",title);
    formData.append("cat",cat);
    formData.append("vid",vid);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/video-edit/${id}`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}



export const videoUploadApi = (obj,title,cat,updateProgress)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();

    formData.append("image", obj, obj.name);
    formData.append("title",title);
    formData.append("cat",cat);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/video-upload`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}


export const trainerLandThumbUploadApi = (obj,updateProgress)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();
    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/land-thumb-upload`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}


export const trainerThumbUploadApi = (obj,updateProgress)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();
    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/thumb-upload`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = parseInt(percentComplete * 100);
        console.log(percentComplete);
        updateProgress(percentComplete);
    }
    })
}

export const traineeThumbUploadApi = (obj)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();
    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee/thumb-upload`,formData,{
        headers:{
            "x-access-token":token
        }
    })
}

export const trainerLandUploadApi = (obj,updateProgress)=>{

    console.log(obj)
    const token = localStorage.getItem("token")

    const formData = new FormData();
    formData.append("image", obj, obj.name);

    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/land-upload`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}


export const getMyVideosApi = (search,type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1//videos-dumb`,{
        headers:{"x-access-token":token}
    })
}

export const deleteTraineeApi = (traineeId)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainee/${traineeId}`,{
        headers:{"x-access-token":token}
    })
}


export const deletePlanApi = (planId)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/plan/${planId}`,{
        headers:{"x-access-token":token}
    })
}

export const getServices_api = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/service`,{
        headers:{"x-access-token":token}
    })
}

export const getServices_land_api = (slug)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/service-land/${slug}`,{
        headers:{"x-access-token":token}
    })
}



export const addService_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/service`,obj,{
        headers:{"x-access-token":token}
    })
}

export const editService_api = (obj,sId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/service/${sId}`,obj,{
        headers:{"x-access-token":token}
    })
}


export const changeStatusService_api = (obj,sId)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/service-change-status/${sId}`,obj,{
        headers:{"x-access-token":token}
    })
}


export const getTurns_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/turn-list`,obj,{
        headers:{"x-access-token":token}
    })
}

export const getLandTurns_api = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/turn-land-list`,obj)
}

export const selfBook_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/new-self-book`,obj,{
        headers:{"x-access-token":token}
    })
}

export const changeDayDuration_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/change-day-duration`,obj,{
        headers:{"x-access-token":token}
    })
}

export const getDay_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/one-day`,obj,{
        headers:{"x-access-token":token}
    })
}

export const changeTurnStatus_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/turn-status`,obj,{
        headers:{"x-access-token":token}
    })
}


export const editTurn_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/turn`,obj,{
        headers:{"x-access-token":token}
    })
}

export const getBooks_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/book-list`,obj,{
        headers:{"x-access-token":token}
    })
}


export const deleteMember_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/delete-self-book`,obj,{
        headers:{"x-access-token":token}
    })
}

export const changeStatus_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/change-book-status`,obj,{
        headers:{"x-access-token":token}
    })
}

export const changeStatusFromBook_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/booking/change-status-from-book`,obj,{
        headers:{"x-access-token":token}
    })
}


export const getUnreadMessage_api = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/chat-unread-message`,{
        headers:{"x-access-token":token}
    })
}

export const searchVideo_api = (search=null,type=null)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/videos-dumb`,{
        params:{search,type},
        headers:{"x-access-token":token}
    })
}

export const deleteVideo_api = (vId)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/videos/${vId}`,{
        headers:{"x-access-token":token}
    })
}

export const deleteVoice_api = (pId)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/deleteVoice/${pId}`,{
        headers:{"x-access-token":token}
    })
}


export const requestsPlan_api = (page=null,search=null,type=null)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/requests/plan`,{
        params:{page,search,type},
        headers:{"x-access-token":token}
    })
}

export const requestsTurn_api = (page=null,search=null,type=null)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/requests/turn`,{
        params:{page,search,type},
        headers:{"x-access-token":token}
    })
}

export const getNotificationsCount_api = (type)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/notification/count`,{
        headers:{"x-access-token":token}
    })
}

export const getTransactionList = (page=0,search=null,startAt=null,endAt=null)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/transactions`,{
        params:{page,search,startAt,endAt},
        headers:{"x-access-token":token}
    })
}

export const hasCredit_api = (tId)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/has-credit`,{
        trainerId:tId
    },{
        headers:{"x-access-token":token}
    })
}


export const quickSendPlanTrainee_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/quick-send-plan-trainee`,obj,{
        headers:{"x-access-token":token}
    })
}

export const quickSendPlanNew_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/quick-send-plan-new`,obj,{
        headers:{"x-access-token":token}
    })
}


export const uploadPlanVoice = (blobObject,planId,updateProgress)=>{
    const token = localStorage.getItem("token")
    const formData = new FormData();
    formData.append('image', blobObject, 'audio.wav');
    formData.append("planId", planId);
    formData.append("type", "plan");
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/trainer/plan-upload-voice/${planId}`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}

//Admin
export const getAdminTrainers_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainers`,obj,{
        headers:{"x-access-token":token}
    })
}

export const addTrainer_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-create`,obj,{
        headers:{"x-access-token":token}
    })
}


export const adminTrainerDetail_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-detail/${id}`,{},{
        headers:{"x-access-token":token}
    })
}
export const adminTrainerStatistic_api = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-statistic/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const adminTrainerCreditUpdate_api = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-credit/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const adminTrainerUpdate_api = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-info/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}


export const adminTrainerPageUpdate_api = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-page/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const adminCheckoutList_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-checkout-list/${id}`,{
        headers:{"x-access-token":token}
    })
}

export const adminGetCheckoutPrice_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-indebtedness/${id}`,{
        headers:{"x-access-token":token}
    })
}

export const adminCheckoutTrainer_api = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-checkout/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const adminSendWelcomeLink_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/admin/trainer-welcome/${id}`,{},{
        headers:{"x-access-token":token}
    })
}



//product
export const listProduct_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/products`,{
        headers:{"x-access-token":token}
    })
}

export const createProduct_api = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/products`,obj,{
        headers:{"x-access-token":token}
    })
}
export const editProduct_api = (obj,id)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/products/${id}`,obj,{
        headers:{"x-access-token":token}
    })
}

export const deleteProduct_api = (id)=>{
    const token = localStorage.getItem("token")
    return API.delete(`${Setup.endpoint}/${Setup.app_name}/api/v1/products/${id}`,{
        headers:{"x-access-token":token}
    })
}


export const uploadProductImage = (id,obj,updateProgress)=>{
    const token = localStorage.getItem("token")
    const formData = new FormData();
    formData.append("image", obj, obj.name);
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/product-image-upload/${id}`,formData,{
        headers:{
            "x-access-token":token
        },
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress(percentComplete);
        }
    })
}

export const sendOrderOtpApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/send-order-otp`,obj)
}

export const finalizeBasketApi = (obj)=>{
    const token = localStorage.getItem("token")
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/finalize-basket`,obj)
}

export const trackOrderApi = (obj)=>{
    return API.post(`${Setup.endpoint}/${Setup.app_name}/api/v1/track-order`,obj)
}


export const ordersRequestListApi = (page,search,type="all")=>{
    const token = localStorage.getItem("token")
    return API.get(`${Setup.endpoint}/${Setup.app_name}/api/v1/orders-request-list`,{
        headers:{
            "x-access-token":token
        },
        params:{
            page,
            search,
            type:type==="all"?null:type
        }
    })
}

export const ordersRequestSeenApi = (id)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/orders-request-seen/${id}`,{},{
        headers:{
            "x-access-token":token
        }
    })
}

export const ordersRequestChangeStatusApi = (id,obj)=>{
    const token = localStorage.getItem("token")
    return API.put(`${Setup.endpoint}/${Setup.app_name}/api/v1/orders-request-change-status/${id}`,obj,{
        headers:{
            "x-access-token":token
        }
    })
}
