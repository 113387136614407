import React ,{useState,useEffect} from 'react';
import "./DumbbellAddPackage.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {
    addTraineeApi,
    createPackage_api, editPackage_api, getLib_api,
    payMySelfPlanInvoiceApi,
    sendRequestPlanInvoiceApi
} from "../../Services/Services";
import {message, Spin} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {englishNumber, numberWithCommas, validation} from "../../Services/utils";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";

function DumbbellAddPackage({handleDoneSendRequest,edit,traineeId}){

    let [title,set_title] = useState("")
    let [label,set_label] = useState("")
    let [cost,set_cost] = useState("0")
    let [discount,set_discount] = useState("0")
    let [estimate,set_estimate] = useState("1")
    let [target,set_target] = useState("")
    let [duration,set_duration] = useState(30)
    let [supportDay,set_supportDay] = useState("30")
    let [spinPage,set_spinPage] = useState(false)

    let [libs,set_libs] = useState([])
    let [selectedLib,set_selectedLib] = useState("not_select")
    let [deliveredType,set_deliveredType] = useState("default")
    let [chatOption,set_chatOption] = useState(false)
    let [boxOption,set_boxOption] = useState(false)


    const [descriptionInput,set_descriptionInput] = useState("");
    const [descriptions,set_descriptions] = useState([])

    useEffect(()=>{
        if(edit?._id){
            fillForms()
        }else {
            resetForm()
        }
    },[edit])

    useEffect(()=>{
       getLibs()
    },[])

    const getLibs = async ()=>{
        try{
            const res = await getLib_api()
            set_libs(res.data.data)
        }catch (e) {
            console.log("Error :",e)
        }
    }

    const fillForms = ()=>{
        set_title(edit.title)
        set_cost(numberWithCommas(edit.amount))
        set_discount(numberWithCommas(edit.discount))
        set_estimate(edit.delivery_estimate)
        set_duration(edit.duration)
        set_supportDay(edit.support_day)
        set_label(edit.extra_label)
        set_descriptions(edit.descriptions)
        set_descriptionInput("")
        set_chatOption(edit.chat_option)
        set_boxOption(edit.box_option)
        set_selectedLib(edit.connected_lib_id)
        set_deliveredType(edit.delivered_type)
    }

    const resetForm =()=>{
        set_title("")
        set_cost("0")
        set_discount("0")
        set_estimate("1")
        set_label("")
        set_duration(30)
        set_supportDay(30)
        set_descriptions([])
        set_descriptionInput("")
        set_chatOption(false)
        set_boxOption(false)
        set_selectedLib("not_select")
        set_deliveredType("default")
    }


    const editPackage = async ()=>{
        let cost_num= cost.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(cost.length<1){
            return message.error({
                content: "لطفا هزینه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }



        if(!estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(deliveredType==="instant" && selectedLib==="not_select"){
            return message.error({
                content: "لطفا یک برنامه انتخاب نمایید",
                className: 'toast-class'
            });
        }

        if(deliveredType==="instant" && !selectedLib){
            return message.error({
                content: "لطفا یک برنامه انتخاب نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            descriptions:descriptions,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            duration:parseInt(duration),
            support_day:parseInt(supportDay),
            extra_label:label,
            delivery_estimate: estimate,
            delivered_type:deliveredType,
            box_option:boxOption,
            chat_option:chatOption,
            connected_lib_id:deliveredType==="default"?null:selectedLib
        }


        try{
            set_spinPage(true)
            await editPackage_api(obj,edit._id)
            set_spinPage(false)
            resetForm()
            message.success({
                content: "پکیج با موفقیت ویرایش شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ویرایش پکیج بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const sendInvoice = async ()=>{
        let cost_num= cost.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let costIsNum = /^\d+$/.test(cost_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        let estimateIsNum = /^\d+$/.test(estimate);


        if(title.length<2){
            return message.error({
                content: "لطفا عنوان را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(cost.length<1){
            return message.error({
                content: "لطفا هزینه را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!costIsNum){
            return message.error({
                content: "لطفا هزینه را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }


        if(deliveredType==="instant" && selectedLib==="not_select"){
            return message.error({
                content: "لطفا یک برنامه انتخاب نمایید",
                className: 'toast-class'
            });
        }


        if(deliveredType==="default" && !estimateIsNum){
            return message.error({
                content: "لطفا مدت زمان تحویل را وارد نمایید",
                className: 'toast-class'
            });
        }


        let obj ={
            title: title,
            descriptions:descriptions,
            amount: parseInt(cost_num),
            discount: parseInt(discount_num),
            payable_amount: parseInt(cost_num)-parseInt(discount_num),
            duration:parseInt(duration),
            support_day:parseInt(supportDay),
            extra_label:label,
            delivery_estimate: estimate,
            delivered_type:deliveredType,
            chat_option:chatOption,
            box_option:boxOption,
            connected_lib_id:selectedLib
        }


        try{
            set_spinPage(true)
            await createPackage_api(obj)
            set_spinPage(false)
            resetForm()
            message.success({
                content: "پکیج با موفقیت ایجاد شد",
                className: 'toast-class'
            });

            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ایجاد پکیج بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const handleDeleteDescription = (d)=>{
        set_descriptions(descriptions.filter(f=>f.id!==d.id))
    }

    const handleAddDescription = ()=>{
        if(descriptionInput.length<1)
            return

        set_descriptions([...descriptions,{id:uuidv4(),text:descriptionInput}])
        set_descriptionInput("")
    }

    const calcTotal = ()=>{
        let c = cost;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد پکیج جدید"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : برنامه تمرینی"
                            id=""/>
                        <span>عنوان پکیج</span>
                    </div>


                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={cost}
                            onChange={e=>set_cost(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>هزینه پکیج (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={discount}
                            onChange={e=>set_discount(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تخفیف پکیج (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={label}
                            onChange={e=>set_label(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : فقط تا 5 خرداد ماه"
                            id=""/>
                        <span>لیبل پکیج</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            className="select-amend"
                            value={deliveredType}
                            onChange={e=>set_deliveredType(e.target.value)}
                            name="" id="">
                            <option value="instant">تحویل آنی</option>
                            <option value="default">تحویل مدت دار</option>
                        </select>
                        <span>نوع تحویل</span>
                    </div>

                    {deliveredType==="default"?
                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                value={estimate}
                                onChange={e=>set_estimate(e.target.value)}
                                type="text" name="" id=""/>
                            <span>مدت زمان تحویل(روز)</span>
                        </div>:null
                    }

                    {deliveredType==="instant"?
                        <div className="dumbbell-add-trainee-form-item">
                            <select
                                className="select-amend"
                                value={selectedLib}
                                onChange={e=>set_selectedLib(e.target.value)}
                                name="" id="">
                                <option value="not_select">انتخاب برنامه</option>
                                {libs.map(l=>{
                                    return(
                                        <option value={l._id}>{l.title}</option>
                                    )
                                })}
                            </select>
                            <span>انتخاب برنامه</span>
                        </div>:null
                    }


                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={descriptionInput}
                            onKeyPress={e=>{if(e.key==="Enter"){handleAddDescription()}}}
                            onChange={e=>set_descriptionInput(e.target.value)}
                            type="text" name="" id=""/>
                        <img className="add-package-img" src={plusBlueIcon} alt="" onClick={e=>{
                            handleAddDescription()
                        }}/>
                        <span>توضیحات </span>
                    </div>

                    {descriptions.length>0?<div className="package-descriptions">
                        {descriptions.map(d=>{
                            return(
                                <div className="package-descriptions-row">
                                    <img src={trashWhiteIcon} className="package-bullet clickable" onClick={e=>{
                                        handleDeleteDescription(d)
                                    }}/>
                                    <span className="package-descriptions-row-text">{d.text}</span>
                                </div>
                            )
                        })}
                    </div>:null}

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={duration}
                            onChange={e=>set_duration(e.target.value)}
                            type="text" name="" id=""/>
                        <span>طول مدت پکیج (روز)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <select
                            className="select-amend"
                            value={supportDay}
                            onChange={e=>set_supportDay(e.target.value)}
                            name="" id="">
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                            <option value="150">150</option>
                            <option value="180">180</option>
                        </select>
                        <span>طول مدت پشتیبانی (روز)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_chatOption(!chatOption)}
                            type="checkbox" checked={chatOption} name="" id=""/>
                        <span>قابلیت چت با مربی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_boxOption(!boxOption)}
                            type="checkbox" checked={boxOption} name="" id=""/>
                        <span>قابلیت تبادل تصویر با مربی</span>
                    </div>


                    <div className="total-section">
                        <span className="total-section-label">{`مبلغ قابل پرداخت`}</span>
                        <span className="total-section-price">{calcTotal()} تومان </span>
                    </div>



                    {!edit?._id?
                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ایجاد پکیج</span>
                    </div>:null}

                    {edit?._id?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            editPackage()
                        }}>
                            <span>ویرایش پکیج</span>
                        </div>:null
                    }

                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}
                    <div className="padding-bottom"></div>
                </div>

            </Spin>






        </div>
    )
}

export default DumbbellAddPackage;
