import React, {useState, useEffect, useRef} from 'react';
import "./DumbbellAddProduct.scss";
import DumbbellDrawerHeader from "../../Components/DumbbellDrawerHeader/DumbbellDrawerHeader";
import {
    addTraineeApi,
    createPackage_api, createProduct_api, editPackage_api, editProduct_api, getLib_api,
    payMySelfPlanInvoiceApi,
    sendRequestPlanInvoiceApi, trainerThumbUploadApi, uploadProductImage
} from "../../Services/Services";
import {message, Spin} from "antd";
import { v4 as uuidv4 } from 'uuid';
import {checkFileSize, englishNumber, numberWithCommas, validation} from "../../Services/utils";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import {Setup} from "../../Services/Setup";

function DumbbellAddProduct({handleDoneSendRequest,edit,traineeId}){


    //products
    let [fa_name,set_fa_name] = useState("")
    let [en_name,set_en_name] = useState("")
    let [price,set_price] = useState("0")
    let [discount,set_discount] = useState("0")
    let [label,set_label] = useState("")
    let [stock,set_stock] = useState("0")
    let [image,set_image] = useState("")
    let [imagePrev,set_imagePrev] = useState("")
    let [showInRegal,set_showInRegal] = useState(false)
    let [status,set_status] = useState(false)
    let [orderInRegal,set_orderInRegal] = useState(false)
    let [productDescription,set_productDescription] = useState("")
    let [ownerDescription,set_ownerDescription] = useState("")

    let [spinPage,set_spinPage] = useState(false)
    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)

    const setUploadLoading = useDispatchUploadLoading()

    useEffect(()=>{
        if(edit?._id){
            fillForms()
        }else {
            resetForm()
        }
    },[edit])



    const fillForms = ()=>{
            set_fa_name(edit.fa_name)
            set_en_name(edit.en_name)
            set_price(edit.price.toString())
            set_discount(edit.discount.toString())
            set_stock(edit.stock.toString())
            set_productDescription(edit.product_description)
            set_ownerDescription(edit.owner_description)
            set_showInRegal(edit.show_in_regal)
            set_status(edit.status==="active"?true:false)
            set_orderInRegal(edit.order_in_regal)
            set_image(edit.image)
    }

    const resetForm =()=>{
        set_fa_name("")
        set_en_name("")
        set_price("0")
        set_discount("0")
        set_stock("0")
        set_productDescription("")
        set_ownerDescription("")
        set_showInRegal(false)
        set_status(false)
        set_orderInRegal(1)
        set_image("")
    }


    const editPackage = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        // let estimateIsNum = /^\d+$/.test(estimate);


        if(fa_name.length<2){
            return message.error({
                content: "لطفا نام فارسی را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(price_num.length<1){
            return message.error({
                content: "لطفا قیمت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!priceIsNum){
            return message.error({
                content: "لطفا قیمت را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        let obj ={
            fa_name: fa_name,
            en_name: en_name,
            price: parseInt(price_num),
            discount: parseInt(discount_num),
            label:label,
            status:status?"active":"not_active",
            stock:parseInt(stock),
            product_description:productDescription,
            owner_description:ownerDescription,
            show_in_regal:showInRegal,
            order_in_regal:parseInt(orderInRegal),
            image:image
        }



        try{
            set_spinPage(true)
            await editProduct_api(obj,edit._id)
            if(imageFile){
                let productId = edit._id
                try{
                    let response = await uploadProductImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "محصول با موفقیت ویرایش شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ویرایش محصول بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const sendInvoice = async ()=>{
        let price_num= price.replaceAll(",", "");
        let discount_num= discount.replaceAll(",", "");
        let priceIsNum = /^\d+$/.test(price_num);
        let discountIsNum = /^\d+$/.test(discount_num);
        // let estimateIsNum = /^\d+$/.test(estimate);


        if(fa_name.length<2){
            return message.error({
                content: "لطفا نام فارسی را وارد نمایید",
                className: 'toast-class'
            });
        }


        if(price_num.length<1){
            return message.error({
                content: "لطفا قیمت را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!priceIsNum){
            return message.error({
                content: "لطفا قیمت را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        if(discount.length<1){
            return message.error({
                content: "لطفا تخفیف را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!discountIsNum){
            return message.error({
                content: "لطفا تخفیف را بصورت صحیح وارد نمایید",
                className: 'toast-class'
            });
        }

        let obj ={
            fa_name: fa_name,
            en_name: en_name,
            price: parseInt(price_num),
            discount: parseInt(discount_num),
            stock:parseInt(stock),
            label:label,
            product_description:productDescription,
            owner_description:ownerDescription,
            show_in_regal:showInRegal,
            status:status?"active":"not_active",
            order_in_regal:parseInt(orderInRegal),
            image:image
        }


        try{
            set_spinPage(true)
            let created = await createProduct_api(obj)

            if(imageFile){
                let productId = created.data.data._id
                try{
                    let response = await uploadProductImage(productId,imageFile,set_thumbUploadProgress);
                    set_imagePrev(null)
                    set_imageFile(null)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }catch (e) {
                    set_spinPage(false)
                    setUploadLoading({visible:false,percent:0})
                    set_thumbUploadProgress(0)
                }
            }
            set_spinPage(false)
            resetForm()
            message.success({
                content: "محصول با موفقیت ایجاد شد",
                className: 'toast-class'
            });
            handleDoneSendRequest()
        }catch (e) {
            console.log(e.response.status)
            return message.error({
                content: "مشکلی در ایجاد محصول بوجود آمده است",
                className: 'toast-class'
            });
        }
    }


    const calcTotal = ()=>{
        let c = price;
        let d = discount;

        if(typeof c==="number")
            c= c.toString()
        if(typeof d==="number")
            d= d.toString()

        return numberWithCommas((c.replaceAll(",", ""))-(d.replaceAll(",", "")) >0? (c.replaceAll(",", ""))-(d.replaceAll(",", "")): 0)
    }


    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[thumbUploadProgress])

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickUpload = (num)=>{
        fileRef.current.click();
    }


    return(
        <div className="dumbbell-add-trainee-container">

           <DumbbellDrawerHeader title={"ایجاد محصول جدید"}/>

            <Spin spinning={spinPage}>

                <div className="dumbbell-add-trainee-form">

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={fa_name}
                            onChange={e=>set_fa_name(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : مکمل کراتین"
                            id=""/>
                        <span>نام فارسی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={en_name}
                            onChange={e=>set_en_name(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : cratine"
                            id=""/>
                        <span>نام انگلیسی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={price}
                            onChange={e=>set_price(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>قیمت محصول (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={discount}
                            onChange={e=>set_discount(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>تخفیف محصول (تومان)</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={stock}
                            onChange={e=>set_stock(numberWithCommas(englishNumber(e.target.value)))}
                            type="text" name="" id=""/>
                        <span>موجودی</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={label}
                            onChange={e=>set_label(e.target.value)}
                            type="text" name=""
                            placeholder="مثلا : فقط تا 5 خرداد ماه"
                            id=""/>
                        <span>لیبل محصول</span>
                    </div>



                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={productDescription}
                            onChange={e=>set_productDescription(e.target.value)}
                            type="text" name="" id=""/>
                        <span>توضیحات محصول</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={ownerDescription}
                            onChange={e=>set_ownerDescription(e.target.value)}
                            type="text" name="" id=""/>
                        <span>توضیحات اضافه</span>
                    </div>




                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_showInRegal(!showInRegal)}
                            type="checkbox" checked={showInRegal} name="" id=""/>
                        <span>نمایش در رگال</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            onClick={e=>set_status(!status)}
                            type="checkbox" checked={status} name="" id=""/>
                        <span>فعال کردن محصول</span>
                    </div>

                    {showInRegal?
                        <div className="dumbbell-add-trainee-form-item">
                            <input
                                value={orderInRegal}
                                onChange={e=>set_orderInRegal(e.target.value)}
                                type="tel" name="" id=""/>
                            <span>نوبت نمایش در رگال</span>
                        </div>:null
                    }


                    <div className="dumbbell-add-trainee-form-item">
                        {imagePrev || image?<span onClick={handleClickUpload} className="image-btn clickable">ویرایش تصویر</span>:null}
                        {!imagePrev && !image?<span onClick={handleClickUpload} className="image-btn clickable">آپلود تصویر</span>:null}
                        <span>تصویر</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <div className="product-add-image">
                            {image? <img src={Setup.filePoint+image} alt=""/>:null}
                            {imagePrev? <img src={imagePrev} alt=""/>:null}
                        </div>
                    </div>


                    <div className="total-section" style={{margin:0}}>
                        <span className="total-section-label">{`مبلغ قابل پرداخت محصول`}</span>
                        <span className="total-section-price">{calcTotal()} تومان </span>
                    </div>



                    {!edit?._id?
                    <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                        sendInvoice()
                    }}>
                        <span>ایجاد محصول</span>
                    </div>:null}

                    {edit?._id?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            editPackage()
                        }}>
                            <span>ویرایش محصول</span>
                        </div>:null
                    }

                    <input type="file" ref={fileRef}
                           accept=".png, .jpg, .jpeg"
                           name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>
                    {/*<div className="dumbbell-add-trainee-form-submit-pay-my-invoice" onClick={e=>{*/}
                    {/*    payMySelfInvoice()*/}
                    {/*}}>*/}
                    {/*    <span>خودم پرداخت میکنم</span>*/}
                    {/*</div>*/}
                    <div className="padding-bottom"></div>
                </div>

            </Spin>






        </div>
    )
}

export default DumbbellAddProduct;
