import React, {useEffect, useState,useRef} from 'react';
import "./DumbbellTraineeMenu.scss";
import sampleTrainerFace from "../../assets/dumbbell_image/sample-trainer-face.png";
import starOffIcon from "../../assets/dumbbell_image/star-off.png";
import starLightIcon from "../../assets/dumbbell_image/star-light.png";

import traineeIcon from "../../assets/dumbbell_image/trainee-menu-icon.png";
import playIcon from "../../assets/dumbbell_image/play-menu-icon.png";
import pageIcon from "../../assets/dumbbell_image/page-menu-icon.png";
import payIcon from "../../assets/dumbbell_image/pay-menu-icon.png";
import logoutIcon from "../../assets/dumbbell_image/logout-menu-icon.png";
import sampleFace from "../../assets/dumbbell_image/sample-big-face.png";
import defaultUser from "../../assets/images/default_user.png";
import {useHistory} from "react-router";
import {
    sendPushToken_trainee_api,
    sendPushToken_trainer_api,
    traineeThumbUploadApi,
    trainerThumbUploadApi
} from "../../Services/Services";
import {Spin} from "antd";
import {Setup} from "../../Services/Setup";
import {getTokenFromFirebase} from "../../firebase";
import { v4 as uuidv4 } from 'uuid';

function DumbbellTraineeMenu(){

    const history = useHistory();
    const [user,set_user] = useState(null)
    const [thumbUid,set_thumbUid] = useState(uuidv4()) //default

    useEffect(e=>{
        const user = localStorage.getItem("user");
        if(user && user!=="undefined"){
            let ff = JSON.parse(user)
            set_user(ff)
        }

    },[])

    const goTo = (path)=>{
        history.push(path)
    }
    const logout = ()=>{
        localStorage.clear()
        history.push("/login-trainee")
    }


    const fileRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)
    const [imagePrev,set_imagePrev] = useState(null);
    const [spin,set_spin] = useState(false);
    const [noticeStatus,set_noticeStatus] = useState(window.Notification.permission) //default , granted , denied

    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])


    const handleImageChange = (e)=>{
        if(e.target.files && e.target.files[0]){
            set_imageFile(e.target.files[0])
        }
    }
    const handleClickItems = (num)=>{
        fileRef.current.click();
    }

    const uploadThumb = async ()=>{
        set_spin(true)
        if(imageFile){
            try{
                let response = await traineeThumbUploadApi(imageFile);
                set_user(response.data.data)
                localStorage.setItem('user', JSON.stringify(response.data.data))
                set_spin(false)
                set_imagePrev(null)
            }catch(e){
                set_spin(false)
                console.log(e)
            }
        }

    }

    const getT = async ()=>{
        let token = await getTokenFromFirebase()
        console.log("fcm : ",token)
        sendPushToken_trainee_api(token)
    }

    return(
        <div className="dumbbell-menu-container">



            <Spin spinning={spin}>
                <div className="dumbbell-menu-image" onClick={handleClickItems}>
                    <div className="dumbbell-menu-image-sec">
                        {imagePrev?
                            <img src={imagePrev} alt=""/>:
                            <>
                                {user?.image?
                                    <img src={Setup.filePoint+user?.image+`?id=${thumbUid}`} alt=""/>
                                    :
                                    <img src={defaultUser} alt=""/>
                                }
                            </>
                        }

                    </div>
                </div>
            </Spin>
            {imagePrev?<div className="dumbbell-menu-image-upload-btn">
                <span onClick={e=>{
                    uploadThumb()
                }}>آپلود تصویر</span>
            </div>:null}

            {user?.first_name?
                <div className="dumbbell-menu-name">
                    <span>{user.first_name+" "+user.last_name}</span>
                    <span>{user.mobile}</span>
                    {/*<div className="dumbbell-header-star">*/}
                    {/*    <img src={starOffIcon} alt=""/>*/}
                    {/*    <img src={starOffIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*    <img src={starLightIcon} alt=""/>*/}
                    {/*</div>*/}
                </div>:null
            }


            <br/>


            {/*<div className="notifications-btn">*/}
            {/*    <div className="notifications-sec">*/}
            {/*        {noticeStatus==="default"?<span onClick={()=>{*/}
            {/*            getT()*/}
            {/*        }} className="notification-btn">درخواست فعال کردن نوتیفیکیشن</span>:null}*/}
            {/*        {noticeStatus==="granted"?<span className="notification-label">نوتیفیکیشن برای شما فعال است</span>:null}*/}
            {/*        {noticeStatus==="denied"?<span className="notification-btn">نحوه فعال کردن نوتیفیکیشن</span>:null}*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="dumbbell-menu-item" onClick={e=>{goTo("/trainee/plan-list")}}>
                <span>برنامه های من</span>
                <img src={pageIcon} alt=""/>
            </div>

            {/*<div className="dumbbell-menu-item" onClick={e=>{goTo("/trainee/pay-list")}}>*/}
            {/*    <span>پرداخت های من</span>*/}
            {/*    <img src={payIcon} alt=""/>*/}
            {/*</div>*/}

            <div className="dumbbell-menu-item" onClick={logout}>
                <span>خروج</span>
                <img src={logoutIcon} alt=""/>
            </div>

            <input type="file" ref={fileRef} name="" style={{visibility: "hidden"}} onChange={handleImageChange} id=""/>

        </div>
    )
}

export default DumbbellTraineeMenu;
