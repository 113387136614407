import React,{useEffect,useState,useRef} from "react"
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css';
import "./PageEditV2.scss"
import {Setup} from "../../Services/Setup";
import ReactPlayer from "react-player";
import loadingGif from "../../assets/dumbbell_image/V2/page_spinner_v2.gif"
import EditIcon from "../../assets/dumbbell_image/V2/edit_icon.svg"
import calendarIcon from "../../assets/dumbbell_image/V2/calendar_icon_v2.svg"
import halterIcon from "../../assets/dumbbell_image/V2/halter_icon_v2.svg"
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import defaultIcon from "../../assets/dumbbell_image/V2/default_user_icon_v2.svg"
import locationIcon from "../../assets/dumbbell_image/V2/location_icon.svg"
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import markerIcon from "../../assets/dumbbell_image/V2/marker_open.png";
import { v4 as uuidv4 } from 'uuid'

import youTubeImage from "../../assets/dumbbell_image/V2/social_youtube.svg"
import telegramImage from "../../assets/dumbbell_image/V2/social_telegram.svg"
import instagramImage from "../../assets/dumbbell_image/V2/social_instagram.svg"
import whatsAppImage from "../../assets/dumbbell_image/V2/social_whatsApp.svg"
import {useHistory, useParams} from "react-router";
import {
    deleteLinkImage,
    editLandApi,
    getMyPageApi, setMyPageLinkApi, setMyPageLinkWithDeleteImageApi,
    trainerLandThumbUploadApi,
    trainerLandUploadApi,
    trainerThumbUploadApi, updatePageLink, uploadLinkImage
} from "../../Services/Services";
import plusBlueIcon from "../../assets/dumbbell_image/plus-blue-icon.png";
import {Drawer, message, Progress, Spin, Switch} from "antd";
import {LoadingOutlined,EyeOutlined,PictureOutlined} from "@ant-design/icons"
import imageCompression from "browser-image-compression";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import {checkFileSize} from "../../Services/utils";
import {useDispatchUploadLoading} from "../../Hook/useDispatchUploadLoading";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup,useMapEvents,useMap  } from 'react-leaflet'




function PageEditV2(){
    const MyRef = useRef(null);
    const setLoader = useDispatchLoading()
    const history = useHistory()
    const setUploadLoading = useDispatchUploadLoading()
    const {slug} = useParams()
    const [links,set_links] = useState(new Array(10).fill({title:"",image:"",type:"link",address:""}))
    const [gallery,set_gallery] = useState(new Array(10).fill({title:"",image:"",type:"gallery",address:""}))
    const [video,set_video] = useState("")
    const [socials,set_socials] = useState([])
    const [mute,set_mute] = useState(false)
    const socialRef = useRef(null);
    const params = {
        slidesPerView: '2.5',
        spaceBetween: 10,
        // rebuildOnUpdate : true,
        rtl:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    const paramsLink = {
        slidesPerView: '3.5',
        spaceBetween: 8,
        // rebuildOnUpdate : true,
        rtl:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    const [mapl, setMap] = useState(null)
    const [position, setPosition] = useState([35.6892, 51.3890])
    let mapRef = useRef();


    const [videoId,set_videoId] = useState("")


    const [pageStatus,set_pageStatus] = useState("in_active")

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        set_pageStatus(checked?"active":"in_active")
    };

    useEffect(()=>{
        getLand()
    },[])



    const [page,set_page] = useState(null)
    const getLand =async ()=>{
        set_spin(true)
        set_spinTip("بزودی صفحه بارگذاری می شود ...")
        try{
            const resp = await getMyPageApi()
            set_page(resp.data.data)
            fillPage(resp.data.data)
            set_spin(false)
            set_spinTip("")
        }catch (e) {
            set_spin(false)
            set_spinTip("")
            console.log(e)
        }
    }

    const [full_name,set_full_name] = useState("")
    const [short_description,set_short_description] = useState("")
    const [descriptions,set_descriptions] = useState([])
    const [image,set_image] = useState("")
    const [address,set_address] = useState([])
    const [phones,set_phones] = useState([])
    const [email,set_email] = useState("")

    const save = async ()=>{
        try{
            let obj ={
                full_name:full_name,
                short_description:short_description,
                youtube:youtube,
                telegram:telegram,
                whatsApp:whatsApp,
                instagram:instagram,
                phones:phones,
                email:email,
                address:addressInput.length>0?[addressInput]:[],
                descriptions:descriptions,
                max_receive_packages:maxInput,
                status:pageStatus,
                location:location,
                links_status:linkStatus,
                gallery_status:galleryStatus,
                contact_status:contactStatus,
                map_status:mapStatus,
                shop_status:shopStatus,
                shop:{title:shopTitle,description:shopDescription,address:shopAddress}
            }

            set_spin(true)
            await editLandApi(obj,page._id)
            set_spin(false)
            return message.success({
                content: "اطلاعات ویرایش شد",
                className: 'toast-class'
            });
        }catch (e) {
            set_spin(false)
            console.log(e)
            return message.error({
                content: "مشکل در ویرایش",
                className: 'toast-class'
            });
        }
    }

//phones
    const [phoneInput,set_phoneInput] = useState("")
    const handleDeletePhone = (d)=>{
        set_phones(phones.filter(f=>f.id!==d.id))
    }
    const handleAddPhone = ()=>{
        if(phoneInput.length<1)
            return
        set_phones([...phones,{id:uuidv4(),text:phoneInput}])
        set_phoneInput("")
    }

    //max receive package
    const [maxInput,set_maxInput] = useState("")

    //description
    const [descriptionInput,set_descriptionInput] = useState("")

    const handleDeleteDescription = (d)=>{
        set_descriptions(descriptions.filter(f=>f.id!==d.id))
    }
    const handleAddDescription = ()=>{
        if(descriptionInput.length<1)
            return
        set_descriptions([...descriptions,{id:uuidv4(),text:descriptionInput}])
        set_descriptionInput("")
    }

    //address
    const [addressInput,set_addressInput] = useState("")
    const handleDeleteAddress = (d)=>{
        set_address(address.filter(f=>f.id!==d.id))
    }
    const handleAddAddress = ()=>{
        if(addressInput.length<1)
            return
        set_address([...address,{id:uuidv4(),text:addressInput}])
        set_addressInput("")
    }

    const [telegram,set_telegram] = useState("")
    const [instagram,set_instagram] = useState("")
    const [whatsApp,set_whatsApp] = useState("")
    const [youtube,set_youtube] = useState("")

    const [shopTitle,set_shopTitle] = useState("")
    const [shopAddress,set_shopAddress] = useState("")
    const [shopDescription,set_shopDescription] = useState("")

    const [shopStatus,set_shopStatus] = useState(false)
    const [linkStatus,set_linkStatus] = useState(false)
    const [galleryStatus,set_galleryStatus] = useState(false)
    const [contactStatus,set_contactStatus] = useState(false)
    const [mapStatus,set_mapStatus] = useState(false)



    const fillPage =async (p)=>{
        set_pageStatus(p.status)
        set_full_name(p.full_name)
        set_short_description(p.short_description)
        set_descriptions(p.descriptions)
        set_maxInput(p.max_receive_packages)
        set_videoId(uuidv4())
        set_video(p.video)
        set_image(p.image)
        set_phones(p.phones)
        set_address(p.address)
        set_addressInput(p.address[0]?p.address[0]:"")
        set_email(p.email)
        set_location(p.location)
        if(p.location?.lat){
            setPosition([p.location.lat,p.location.lng])
        }
        set_telegram(p.telegram)
        set_instagram(p.instagram)
        set_whatsApp(p.whatsApp)
        set_youtube(p.youtube)

        set_shopStatus(p.shop_status)
        set_linkStatus(p.links_status)
        set_galleryStatus(p.gallery_status)
        set_contactStatus(p.contact_status)
        set_mapStatus(p.map_status)

        set_shopTitle(p.shop.title)
        set_shopAddress(p.shop.address)
        set_shopDescription(p.shop.description)

        let linksTemp = links
        p.links.forEach((l)=>{
            linksTemp[l.order]= l
        })

        set_links(linksTemp)

        let galleryTemp = gallery
        p.gallery.forEach((g)=>{
            galleryTemp[g.order]= g
        })

        set_gallery(galleryTemp)

        // set_location(p.location)
        try{
            set_socials([
                {link:"",image:telegramImage,title:""},
                {link:"",image:youTubeImage,title:""},
                {link:"",image:instagramImage,title:""},
                {link:"",image:whatsAppImage,title:""},
            ])
        }catch (e) {

        }
    }

    const linkImageRef = useRef(null);
    const fileImageRef = useRef(null);
    const [imageFile,set_imageFile] = useState(null)
    const [imagePrev,set_imagePrev] = useState(null);

    const fileVideoRef = useRef(null);
    const [videoFile,set_videoFile] = useState(null)
    const [videoPrev,set_videoPrev] = useState(null);
    const [uploading,set_uploading] = useState(false);
    const [videoProgress,set_videoProgress] = useState(0);

    const [spin,set_spin] = useState(false);
    const [spinTip,set_spinTip] = useState("");
    useEffect(()=>{
        setLoader(spin)
    },[spin])

    const [thumbUploadProgress,set_thumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(thumbUploadProgress>0){
            if(thumbUploadProgress>80)
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال فشرده سازی و ذخیره ویدیو  . . ."})
            else
                setUploadLoading({visible:true,percent:thumbUploadProgress,title:"در حال آپلود ویدیو  . . ."})
        }
    },[thumbUploadProgress])
    const uploadVideo = async ()=>{
        set_spin(true)
        set_spinTip("لطفا تا پایان آپلود صفحه را نبندید")
        if(videoFile){
            try{
                await trainerLandUploadApi(videoFile,set_thumbUploadProgress);
                getLand()
                set_spin(false)
                set_spinTip("")
                set_videoPrev(null)
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                return message.success({
                    content: "آپلود انجام شد",
                    className: 'toast-class'
                });

            }catch(e){
                set_spin(false)
                set_spinTip("")
                setUploadLoading({visible:false,percent:0})
                set_thumbUploadProgress(0)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود",
                    className: 'toast-class'
                });
            }
        }
    }


    const [videoChangeSpin,set_videoChangeSpin] = useState(false)
    useEffect(() => {
        if (!videoFile) {
            set_videoPrev(undefined)
            set_videoChangeSpin(false)
            return
        }
        const objectUrl = URL.createObjectURL(videoFile)
        set_videoPrev(objectUrl)
        set_videoChangeSpin(false)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [videoFile])


    const handleVideoChange = (e)=>{
        let limit = 100;
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_videoChangeSpin(true)
            set_videoFile(e.target.files[0])
        }
    }
    const handleClickVideoItems = (num)=>{
        fileVideoRef.current.click();
    }

    //------------------------------------image

    const [pageThumbUploadProgress,set_pageThumbUploadProgress] = useState(0)

    useEffect(()=>{
        if(pageThumbUploadProgress>0){
            if(pageThumbUploadProgress>80)
                setUploadLoading({visible:true,percent:pageThumbUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:pageThumbUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }

    },[pageThumbUploadProgress])

    const [imageChangeSpin,set_imageChangeSpin] = useState(true)
    const uploadImage = async ()=>{
        if(imageFile){
            set_spinTip("لطفا تا پایان آپلود صفحه را نبندید")
            set_spin(true)
            const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            let blob = await imageCompression(imageFile, options);

            if(blob.size>5000000){
                set_spinTip("")
                set_spin(false)
                return message.error({
                    content: "حجم تصویر باید کمتر از 5 مگابایت باشد",
                    className: 'toast-class'
                });
            }
            if(blob.type!=="image/png" && blob.type!=="image/jpeg"){
                set_spinTip("")
                set_spin(false)
                return message.error({
                    content: "پسوند تصویر باید png یا jpg باشد",
                    className: 'toast-class'
                });
            }

            try{
                await trainerLandThumbUploadApi(blob,set_pageThumbUploadProgress);
                getLand()
                setUploadLoading({visible:false,percent:0})
                set_pageThumbUploadProgress(0)
                set_spin(false)
                set_spinTip("")
                set_imagePrev(null)
                return message.success({
                    content: "آپلود انجام شد",
                    className: 'toast-class'
                });
            }catch(e){
                set_spin(false)
                set_spinTip("")
                setUploadLoading({visible:false,percent:0})
                set_pageThumbUploadProgress(0)
                console.log(e)
                return message.error({
                    content: "مشکل در آپلود",
                    className: 'toast-class'
                });
            }
        }
    }


    useEffect(() => {
        if (!imageFile) {
            set_imagePrev(undefined)
            set_imageChangeSpin(false)
            return
        }
        const objectUrl = URL.createObjectURL(imageFile)
        set_imagePrev(objectUrl)
        set_imageChangeSpin(false)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])


    const handleImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageChangeSpin(true)
            set_imageFile(e.target.files[0])
        }
    }

    const handleClickImageItems = (num)=>{
        fileImageRef.current.click();
    }

    const gotToPage = ()=>{
        window.open(`/page/${page.slug}`, '_blank');
    }


    //map drawer
    const [visibleMapDrawer,set_visibleMapDrawer] = useState(false)
    const showMapDrawer =()=>{
        set_visibleMapDrawer(true)
    }
    const closeMapDrawer =()=>{
        set_visibleMapDrawer(false)
    }

    const [currentLinkIndex,set_currentLinkIndex] = useState("-1") // link,gallery
    const [currentLink,set_currentLink] = useState("-1") // link,gallery
    const [currentLinkTitle,set_currentLinkTitle] = useState("")
    const [currentLinkAddress,set_currentLinkAddress] = useState("")
    const [currentLinkImage,set_currentLinkImage] = useState("")
    const [linkFile,set_linkFile] = useState(null)
    const [visibleLinkBottomDrawer,set_visibleLinkBottomDrawer] = useState(false)
    const onLinkBottomDrawerClose = async ()=>{
        set_visibleLinkBottomDrawer(false)
    }

    const resetLinkForm = ()=>{
        set_linkFile(null)
        set_currentLinkIndex("")
        set_currentLink(null)
        set_currentLinkTitle("")
        set_currentLinkImage("")
        set_currentLinkAddress("")
    }
    const showLinkDrawer = (type,l,i)=>{
        resetLinkForm()
        set_currentLinkIndex(i)
        set_currentLink(l)
        set_currentLinkTitle(l.title)

        set_currentLinkAddress(l.address)
        setTimeout(()=>{
            set_currentLinkImage(l.image)
            set_visibleLinkBottomDrawer(true)
        },0)

    }


    //link drawer -------------------
    useEffect(() => {
        if (!linkFile) {
            set_currentLinkImage(undefined)
            set_imageChangeSpin(false)
            return
        }
        const objectUrl = URL.createObjectURL(linkFile)
        set_currentLinkImage(objectUrl)
        set_imageChangeSpin(false)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [linkFile])


    const handleLinkImageChange = (e)=>{
        let limit = 3
        if(e.target.files && e.target.files[0]){
            if(!checkFileSize(e.target.files[0].size,limit)){
                return message.error({
                    content: `حداکثر حجم فایل انتخابی باید ${limit} مگابایت باشد `,
                    className: 'toast-class'
                });
            }
            set_imageChangeSpin(true)
            set_linkFile(e.target.files[0])
        }
    }
    const handleClickLinkImageEdit = ()=>{
        linkImageRef.current.click();
    }
    const handleDeleteImage = ()=>{
        console.log(currentLinkIndex,currentLink)
        set_currentLinkImage(null)
        set_linkFile(null)
    }

    const [socialSelect,set_socialSelect] = useState("other")
    const handleChangeSocialSelect = (v)=>{
        set_socialSelect(v)
        if(v==="other")
            return set_currentLinkImage(currentLink.image)
        if(v==="youtube")
            return set_currentLinkImage(youTubeImage)
        if(v==="whatsapp")
            return set_currentLinkImage(whatsAppImage)
        if(v==="telegram")
            return set_currentLinkImage(telegramImage)
        if(v==="instagram")
            return set_currentLinkImage(instagramImage)
    }


    const [drawerLinkFlag,set_drawerLinkFlag] = useState(true)
    const [linkUploadProgress,set_linkUploadProgress] = useState(0)

    useEffect(()=>{
        if(linkUploadProgress>0){
            if(linkUploadProgress>80)
                setUploadLoading({visible:true,percent:linkUploadProgress,title:"در حال فشرده سازی و ذخیره تصویر  . . ."})
            else
                setUploadLoading({visible:true,percent:linkUploadProgress,title:"در حال آپلود تصویر  . . ."})
        }
    },[linkUploadProgress])
    const handleSubmitLink = async ()=>{

        let find = null
        if(currentLink.type==="link"){
            find = links.find(l=>l.order===currentLink.order)
        }
        if(currentLink.type==="gallery"){
            find = gallery.find(l=>l.order===currentLink.order)
        }

        let image = find.image

        if(linkFile){
            let upload_payload ={
                image:linkFile,
                order:currentLinkIndex,
                type:currentLink.type
            }
            let response = await uploadLinkImage(upload_payload,set_linkUploadProgress)
            image = response.data.data.image
        }
        if(!linkFile){
            let hasDeleteFlag = false

            console.log("currentLink: ",currentLink)
            hasDeleteFlag = find.image.length>0 && !currentLinkImage
            if(hasDeleteFlag){
                let delete_payload ={
                    image:find.image,
                }
                await deleteLinkImage(delete_payload)
                image = ""
            }
        }
        let obj = {
            image:image,
            title:currentLinkTitle,
            order:currentLinkIndex,
            type:currentLink.type,
            social:socialSelect,
            address:currentLinkAddress
        }

        try{
            await updatePageLink(obj)
            await getLand()
            set_drawerLinkFlag(false)
            set_drawerLinkFlag(true)
            setUploadLoading({visible:false,percent:0})
            set_linkUploadProgress(0)
            return message.success({
                content: "آپلود با موفقیت انجام شد",
                className: 'toast-class'
            });
        }catch (e) {
            setUploadLoading({visible:false,percent:0})
            set_linkUploadProgress(0)
            console.log(e)
        }

    }

    function LocationMarker({getAdd}) {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                console.log("click")
            },
            moveend(e) {
                let cc = map.getCenter()
                console.log(cc.lat,cc.lng)
                getAdd(cc.lat,cc.lng)
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }

    const [mapCenter,set_mapCenter] = useState(null)
    const [location,set_location] = useState({})
    const setAdd = (lat,lng)=>{
        console.log("location : ",lat,lng)
        set_mapCenter({lat,lng})
    }

    // lat: 35.7594246213854, lng: 51.41148805618287
    const handleAcceptLocationClick = ()=>{
        console.log("mapCenter : ",mapCenter)
        set_location(mapCenter)
        closeMapDrawer()
    }


    return(
        <div className="page-container-v2">
            <DumbbellHeader title="صفحه من" />
            <div className="view-page-float clickable" onClick={gotToPage}>
                <EyeOutlined/>
            </div>

            <Drawer height={550}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#ffffff",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom"
                    onClose={closeMapDrawer}
                    visible={visibleMapDrawer}>


                <div className="osm-sec">
                    <div className="accept-location">
                        <span className="clickable" onClick={handleAcceptLocationClick}>تایید موقعیت</span>
                    </div>
                    <div className="osm-custom-flag">
                        <img src={markerIcon} alt=""/>
                    </div>
                    <MapContainer
                        style={{width:"100%",height:"100%"}}
                        ref={mapRef}
                        center={position}
                        zoom={16}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <LocationMarker getAdd={setAdd}/>
                    </MapContainer>

                </div>


            </Drawer>

            {drawerLinkFlag?
                <Drawer height={350}
                        bodyStyle={{padding:0,margin:0,backgroundColor:"#ffffff",borderRadius:"15px 15px 0 0"}}
                        mask={true}  closable={false}  placement="bottom"
                        onClose={onLinkBottomDrawerClose}
                        visible={visibleLinkBottomDrawer}>


                    <div className="link-drawer-section">
                        <div className="link-drawer-header">
                            {/*<span>{currentLink.title}</span>*/}
                        </div>
                        <div className="link-drawer-body">
                            <div className="link-drawer-image">


                                <div className="link-edit-image clickable" onClick={e=>{
                                    handleClickLinkImageEdit()
                                }}>
                                    <img src={EditIcon} alt="" />
                                </div>

                                {currentLinkImage?.length>0?
                                    <div className="link-delete-image clickable" onClick={e=>{
                                        handleDeleteImage()
                                    }}>
                                        <img src={deleteIcon} alt="" />
                                    </div>
                                    :null}

                                <div className="link-main-image">

                                    {currentLinkImage?.length>0?
                                        <>
                                            {
                                                currentLinkImage.startsWith('blob') ||  currentLinkImage.startsWith('/stati')  ?
                                                    <img src={currentLinkImage} alt=""/> :
                                                    <img src={Setup.filePoint + currentLinkImage} alt=""/>
                                            }
                                        </>:<div className="default-image">
                                            <PictureOutlined/>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/*{currentLink.type==="link"?*/}
                            {/*    <select*/}
                            {/*        value={socialSelect}*/}
                            {/*        onChange={e=>handleChangeSocialSelect(e.target.value)}*/}
                            {/*        className="link-social-select" name="" id="">*/}
                            {/*        <option value="other">سایر</option>*/}
                            {/*        <option value="instagram">اینستاگرام</option>*/}
                            {/*        <option value="youtube">یوتیوب</option>*/}
                            {/*        <option value="whatsapp">واتس آپ</option>*/}
                            {/*        <option value="telegram">تلگرام</option>*/}
                            {/*    </select>:null*/}
                            {/*}*/}
                            <input
                                placeholder="عنوان"
                                className="link-title-input"
                                onChange={e=>set_currentLinkTitle(e.target.value)}
                                value={currentLinkTitle}
                                type="text" name="" id=""/>
                            <input
                                placeholder="آدرس"
                                className="link-title-input"
                                onChange={e=>set_currentLinkAddress(e.target.value)}
                                value={currentLinkAddress}
                                type="text" name="" id=""/>
                            <span onClick={e=>{
                                handleSubmitLink()
                            }} className="link-submit-btn clickable">اعمال تغییرات</span>
                        </div>

                    </div>
                    <input type="file"
                           onClick={(event)=> {
                               event.target.value = null
                           }}
                           ref={linkImageRef}
                           name="" style={{visibility: "hidden"}}
                           accept=".png, .jpg, .jpeg"
                           onChange={handleLinkImageChange} id=""/>
                </Drawer>:null
            }


            <div className="page-video-sec page-video-edit-sec">
                <div className="player-sec">
                    {!videoPrev && video?<ReactPlayer
                        playsinline={true}
                        playing={true}
                        volume={0.7}
                        controls={true}
                        muted={mute}
                        height={'auto'}
                        width={"100%"}   url={Setup.filePoint+video+"?id="+videoId}  />:null}

                    {videoPrev?<ReactPlayer
                        playsinline={true}
                        playing={true}
                        volume={0.7}
                        controls={true}
                        muted={mute}
                        height={'auto'}
                        width={"100%"}   url={videoPrev}  />:null}
                </div>

                <div className="edit-icon clickable" onClick={handleClickVideoItems}>
                    <Spin spinning={videoChangeSpin}>
                        <img src={EditIcon} alt=""/>
                    </Spin>
                </div>

                {videoPrev?<div className="upload-btn clickable" onClick={uploadVideo}>
                    <span>آپلود ویدیو</span>
                </div>:null}



                {/*{uploading?<div className="upload-progress">*/}
                {/*    <span>لطفا تا پایان آپلود صفحه را نبندید ({videoProgress}%) </span>*/}
                {/*    <Progress*/}
                {/*        size="small"*/}
                {/*        trailColor={"#e2e2e2"}*/}
                {/*        strokeColor={"#C2B9F2"}*/}
                {/*        showInfo={false}*/}
                {/*        percent={videoProgress}  status="active" />*/}
                {/*</div>:null}*/}


            </div>

            {/*<div className="page-actions-btns">*/}
            {/*    <div className="page-actions-btn clickable">*/}
            {/*        <div className="page-actions-btn-icon">*/}
            {/*            <img src={calendarIcon} alt=""/>*/}
            {/*        </div>*/}
            {/*        <div className="page-actions-btn-label-left">*/}
            {/*            <span>وقت حضوری</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="page-actions-btn clickable">*/}
            {/*        <div className="page-actions-btn-label-right">*/}
            {/*            <span>برنامه آنلاین</span>*/}
            {/*        </div>*/}
            {/*        <div className="page-actions-btn-icon">*/}
            {/*            <img src={halterIcon} alt=""/>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}



            <div className="page-profile">
                <div className="page-profile-img">
                    {!imagePrev && image?
                        <div className="imag-thumb">
                            <img className="user_img" src={Setup.filePoint+image+"?id="+uuidv4()} alt=""/>

                            <div className="edit-thumb-icon clickable" onClick={handleClickImageItems}>
                                <Spin spinning={imageChangeSpin}>
                                    <img src={EditIcon} alt=""/>
                                </Spin>
                            </div>
                        </div>:null}

                    {imagePrev? <div className="imag-thumb">
                        <img className="user_img" src={imagePrev} alt=""/>
                        <div className="edit-thumb-icon clickable" onClick={handleClickImageItems}>
                            <Spin spinning={imageChangeSpin} >
                                <img src={EditIcon} alt=""/>
                            </Spin>
                        </div>
                        <div className="upload_image_btn clickable" onClick={uploadImage}>
                            <span>آپلود تصویر</span>
                        </div>
                    </div>:null}

                    {!imagePrev && !image?
                        <div className="page-profile-img-default">
                            <img src={defaultIcon} alt=""/>
                            <div className="edit-thumb-icon clickable" onClick={handleClickImageItems}>
                                <Spin spinning={imageChangeSpin} >
                                    <img src={EditIcon} alt=""/>
                                </Spin>
                            </div>
                        </div>:null}
                </div>

                <div className="page-profile-name">
                    <input type="text"
                           value={full_name}
                           onChange={e=>set_full_name(e.target.value)}
                           name=""
                           placeholder="نام کامل"
                           id=""/>
                    <input type="text"
                           value={short_description}
                           placeholder="توضیح کوتاه"
                           onChange={e=>set_short_description(e.target.value)}
                           name="" id=""/>
                </div>
            </div>


            <div className="page-divider">
                <hr/>
            </div>

            <div className="page-edit">
                <div className="page-social-item">
                    <div className="input-sec">
                        <input
                            value={descriptionInput}
                            onKeyPress={e=>{if(e.key==="Enter"){handleAddDescription()}}}
                            onChange={e=>set_descriptionInput(e.target.value)}
                            type="text" name="" id=""/>
                        <img className="add-package-img" src={plusBlueIcon} alt="" onClick={e=>{
                            handleAddDescription()
                        }}/>
                    </div>
                    <h6>توضیحات</h6>
                </div>
            </div>

            <div className="page-divider">
                <hr/>
            </div>



            <div className="page-desc-items">

                {descriptions.map(d=>{
                    return(
                        <div className="page-desc-item">
                            <div className="page-desc-item-text">
                                <span>{d.text}</span>
                            </div>
                            <div onClick={e=>handleDeleteDescription(d)} className="page-desc-item-bullet clickable">
                                <img src={trashIcon} alt=""/>
                            </div>
                        </div>
                    )
                })}

            </div>

            <div className="page-edit">
                <div className="page-social-item">
                    <div className="input-sec">
                        <input
                            style={{width:"96%"}}
                            value={maxInput}
                            onChange={e=>set_maxInput(e.target.value)}
                            type="number" name="" id=""/>
                    </div>
                    <h6>ظرفیت پذیرش پکیج در روز</h6>
                </div>
            </div>

            <div className="page-divider">
                <hr/>
            </div>

            <div className="page-sec">
                <div className="page-sec-header">
                    <input type="checkbox" name="" onClick={e=>{set_linkStatus(!linkStatus)}} id="" checked={linkStatus} />
                    <span>لینک ها</span>
                </div>

                {linkStatus?
                    <Swiper ref={MyRef}  {...paramsLink}>
                        {links.map((l,i)=>{
                            return(
                                <div className="page-swiper-gallery-item" onClick={e=>{
                                    showLinkDrawer('link',l,i)
                                }}>
                                    <div className="img-wrapper">
                                        {l.image.length>0?
                                            <img src={`${Setup.filePoint+l.image}?id=${uuidv4()}`} alt=""/>:
                                            <div className="default-icon">
                                                <PictureOutlined/>
                                            </div>
                                        }
                                    </div>
                                    {l.title?
                                        <span className="link-title">{l.title}</span>:
                                        <span className="link-title">{`لینک ${i+1}`}</span>
                                    }

                                </div>
                            )
                        })}
                    </Swiper>:null
                }



            </div>


            <div className="page-divider">
                <hr/>
            </div>

            <div className="page-sec">
                <div className="page-sec-header">
                    <input type="checkbox" name="" onClick={e=>{set_shopStatus(!shopStatus)}} id="" checked={shopStatus} />
                    <span>لینک فروشگاه</span>
                </div>
                {shopStatus?
                    <div className="shop-sec">
                        <input
                            value={shopTitle}
                            onChange={e=>set_shopTitle(e.target.value)}
                            type="text" name="" id="" placeholder="شماره کارت بانکی"/>
                        <input
                            value={shopAddress}
                            onChange={e=>set_shopAddress(e.target.value)}
                            type="text" name="" id="" placeholder="نام صاحب کارت"/>
                        <textarea
                            value={shopDescription}
                            onChange={e=>set_shopDescription(e.target.value)}
                            name="" id="" cols="20" rows="5" placeholder="توضیحات پشتیبانی فروشگاه">
                    </textarea>
                    </div>:null
                }

            </div>



            <div className="page-divider">
                <hr/>
            </div>





            <div className="page-sec">
                <div className="page-sec-header">
                    <input type="checkbox" name="" onClick={e=>{set_galleryStatus(!galleryStatus)}} id="" checked={galleryStatus}/>
                    <span>گالری</span>
                </div>

                {galleryStatus?

                    <Swiper ref={MyRef}  {...params}>
                        {gallery.map((l,i)=>{
                            return(
                                <div className="page-swiper-item" onClick={e=>{
                                    showLinkDrawer('link',l,i)
                                }}>
                                    <div className="img-wrapper">
                                        {l.image.length>0?
                                            <img src={Setup.filePoint+l.image} alt=""/>:
                                            null
                                        }
                                    </div>
                                    {l.title?
                                        <span className="link-title">{l.title}</span>:
                                        <span className="link-title">{`تصویر ${i+1}`}</span>
                                    }
                                </div>
                            )
                        })}
                    </Swiper>:null}



            </div>


            <div className="page-divider">
                <hr/>
            </div>

            {/*<div className="page-edit">*/}

            {/*    <div className="page-social-item">*/}
            {/*        <div className="input-sec">*/}
            {/*            <input*/}
            {/*                value={youtube}*/}
            {/*                onChange={e=>set_youtube(e.target.value)}*/}
            {/*                type="text" name="" id=""/>*/}
            {/*        </div>*/}
            {/*        <h6>آدرس یوتیوب  : </h6>*/}
            {/*    </div>*/}

            {/*    <div className="page-social-item">*/}
            {/*        <div className="input-sec">*/}
            {/*            <input*/}
            {/*                value={whatsApp}*/}
            {/*                onChange={e=>set_whatsApp(e.target.value)}*/}
            {/*                type="text" name="" id=""/>*/}
            {/*        </div>*/}
            {/*        <h6>آدرس واتس آپ  : </h6>*/}
            {/*    </div>*/}

            {/*    <div className="page-social-item">*/}
            {/*        <div className="input-sec">*/}
            {/*            <input*/}
            {/*                value={telegram}*/}
            {/*                onChange={e=>set_telegram(e.target.value)}*/}
            {/*                type="text" name="" id=""/>*/}
            {/*        </div>*/}
            {/*        <h6>آدرس تلگرام  : </h6>*/}
            {/*    </div>*/}

            {/*    <div className="page-social-item">*/}
            {/*        <div className="input-sec">*/}
            {/*            <input*/}
            {/*                value={instagram}*/}
            {/*                onChange={e=>set_instagram(e.target.value)}*/}
            {/*                type="text" name="" id=""/>*/}
            {/*        </div>*/}
            {/*        <h6>آدرس اینستاگرام  : </h6>*/}
            {/*    </div>*/}

            {/*</div>*/}



            <div className="page-sec">
                <div className="page-sec-header">
                    <input type="checkbox" name="" onClick={e=>{set_contactStatus(!contactStatus)}} id="" checked={contactStatus}/>
                    <span>راه های تماس</span>
                </div>
                {contactStatus?
                    <div className="page-sec-body">

                        <div className="page-edit">
                            <div className="page-social-item">
                                <div className="input-sec">
                                    <input
                                        value={phoneInput}
                                        onChange={e=>set_phoneInput(e.target.value)}
                                        onKeyPress={e=>{if(e.key==="Enter"){handleAddPhone()}}}
                                        type="text" name="" id=""/>
                                    <img className="add-package-img" src={plusBlueIcon} alt="" onClick={e=>{
                                        handleAddPhone()
                                    }}/>
                                </div>
                                <h6>تلفن</h6>
                            </div>
                        </div>

                        <div className="page-desc-items">

                            {phones.map(d=>{
                                return(
                                    <div className="page-desc-item">
                                        <div className="page-desc-item-text">
                                            <span>{d.text}</span>
                                        </div>
                                        <div onClick={e=>handleDeletePhone(d)} className="page-desc-item-bullet clickable">
                                            <img src={trashIcon} alt=""/>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                        <div className="page-edit">
                            <div className="page-social-item">
                                <div className="input-sec">
                                    <input
                                        value={email}
                                        onChange={e=>set_email(e.target.value)}
                                        type="text" name="" id=""/>
                                </div>
                                <h6>ایمیل</h6>
                            </div>
                        </div>


                        <div className="page-edit">
                            <div className="page-social-item">
                                <div className="input-sec">
                                    <input
                                        value={addressInput}
                                        onKeyPress={e=>{if(e.key==="Enter"){handleAddAddress()}}}
                                        onChange={e=>set_addressInput(e.target.value)}
                                        type="text" name="" id=""/>
                                    {/*<img className="add-package-img" src={plusBlueIcon} alt="" onClick={e=>{*/}
                                    {/*    handleAddAddress()*/}
                                    {/*}}/>*/}
                                </div>
                                <h6>آدرس</h6>
                            </div>
                        </div>


                        {/*<div className="page-desc-items">*/}

                        {/*    {address.map(d=>{*/}
                        {/*        return(*/}
                        {/*            <div className="page-desc-item">*/}
                        {/*                <div className="page-desc-item-text">*/}
                        {/*                    <span>{d.text}</span>*/}
                        {/*                </div>*/}
                        {/*                <div onClick={e=>handleDeleteAddress(d)} className="page-desc-item-bullet clickable">*/}
                        {/*                    <img src={trashIcon} alt=""/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })}*/}

                        {/*</div>*/}


                    </div>:null
                }
            </div>



            <div className="page-divider">
                <hr/>
            </div>


            <div className="page-sec">
                <div className="page-sec-header">
                    <input type="checkbox" name="" onClick={e=>{set_mapStatus(!mapStatus)}} id="" checked={mapStatus}/>
                    <span>نقشه</span>
                </div>
                {mapStatus?
                    <div className="page-sec-body">
                        <div className="page-actions-btn clickable">
                            <div className="page-actions-btn-icon">
                                <img src={locationIcon} alt=""/>
                            </div>
                            <div className="page-actions-btn-label-left" onClick={showMapDrawer}>
                                <span>تغییر موقعیت نقشه</span>
                            </div>
                        </div>
                    </div>:null
                }
            </div>

            <div className="page-divider">
                <hr/>
            </div>

            <div className="active-page">
                <Switch checked={pageStatus==="active"} onChange={onChange} />
                <h6>فعال کردن صفحه</h6>
            </div>

            <div className="page-save-btn" onClick={e=>{
                save()
            }}>
                <span className="clickable">ذخیره تغییرات</span>
            </div>




            {/*<div className="page-divider">*/}
            {/*    <hr/>*/}
            {/*</div>*/}

            {/*<div className="page-followup clickable">*/}
            {/*    <div className="page-followup-btn">*/}
            {/*        <img src={followupIcon} alt=""/>*/}
            {/*        <span>پیگیری</span>*/}
            {/*    </div>*/}

            {/*</div>*/}


            <input type="file" ref={fileVideoRef}
                   onClick={(event)=> {
                       event.target.value = null
                   }}
                   name="" style={{visibility: "hidden"}} accept="video/mp4,video/x-m4v,video/*" onChange={handleVideoChange} id=""/>
            <input type="file"
                   onClick={(event)=> {
                       event.target.value = null
                   }}
                   ref={fileImageRef} name="" style={{visibility: "hidden"}} accept=".png, .jpg, .jpeg" onChange={handleImageChange} id=""/>


        </div>
    )
}

export default PageEditV2
