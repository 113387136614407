import React,{useEffect,useState} from 'react';
import "./DumbbellLogin.scss";
// import loginImage from  "../../assets/dumbbell_image/V2/login_image.png";
import loginImage from  "../../assets/dumbbell_image/V2/login_back.jpg";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from  "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import caretIcon from  "../../assets/dumbbell_image/V2/caret_icon_v2.png";
import hintIcon from  "../../assets/dumbbell_image/V2/hint_icon_v2.png";
import spinnerIcon from  "../../assets/dumbbell_image/V2/spinner_icon.gif";
import {Spin, message, Switch, Drawer} from "antd";
import {loginApi} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory} from "react-router";
import {convertToEnglishNumbers, englishNumber, validation} from "../../Services/utils";
import {MailOutlined} from '@ant-design/icons';
import {Setup} from "../../Services/Setup";
import DumbbellVideoUpload from "../DumbbellVideoUpload/DumbbellVideoUpload";

function DumbbellLogin(){
    useEffect(()=>{
        document.body.style.backgroundColor = "white";
    },[])


    const history = useHistory()



    const [mode,set_mode] = useState("mobile")
    const [email,set_email] = useState("")
    const [mobile,set_mobile] = useState("")
    const [spin,set_spin] = useState(false)

    const modeName = ()=>{
        return mode==="mobile"?"موبایل":"ایمیل"
    }

    const sendCode = async ()=>{
        set_spin(true)

        if(mode==="mobile" && !validation("MOBILE",mobile)){
            set_spin(false)
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        if(mode==="email" && !validation("EMAIL",email)){
            set_spin(false)
            return message.error({
                content: "فرمت ایمیل درست نمیباشد",
                className: 'toast-class'
            });
        }

        try{
            await loginApi({mobile,login_mode:mode,email})
             message.success({
                 content: `کد تایید به شماره ${modeName()} شما ارسال شد`,
                className: 'toast-class'
            });
            set_spin(false)
            history.push("/verify-trainer",{mobile,email,mode})
        }catch (e) {
            set_spin(false)
            return message.error({
                content: " مورد پذیرش نمیباشد",
                className: 'toast-class'
            });
        }



    }

    const onChange = (checked) => {
        set_mode(mode==="mobile"?"email":"mobile")
    };


    //Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleClickCloseBottomDrawer = (m)=>{
        set_visibleBottomDrawer(false);
        set_mode(m)
    }

    return(
        <div className="dumbbell-login-container">
            <Drawer height={200}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickCloseBottomDrawer("mobile")
                    }}>
                        <img src={mobileIcon} alt=""/>
                        <span className="label">ورود با موبایل</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickCloseBottomDrawer("email")
                    }}>
                        <img src={mailIcon} alt=""/>
                        <span className="label">ورود با ایمیل</span>
                    </div>
                </div>
            </Drawer>

            <div className="v2-dumbbell-login-image" style={{ backgroundImage:`url(${loginImage})`}}>


                <div className="v2-dumbbell-login-logo">
                    <h3>STEP</h3>
                    <h6>The future  of  Planing</h6>
                    <span>Ali Pakzadi - The best coach of the month</span>
                    {/*<img className="login-hint-icon clickable" src={hintIcon} alt=""/>*/}
                </div>

                <div className="v2-dumbbell-login-image-wrapper"></div>

            </div>

            <div className="v2-dumbbell-login-form">

                <div className="v2-dumbbell-login-select clickable" onClick={e=>{
                    showBottomDrawer()
                }}>
                    <img src={caretIcon} alt=""/>
                    {mode==="mobile"? <span>ورود با موبایل</span>: null}
                    {mode==="email"? <span>ورود با ایمیل</span>: null}
                </div>

                {mode==="mobile"?
                    <div className="v2-dumbbell-login-input">
                        <img src={mobileIcon} alt=""/>
                        <input
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    sendCode();
                                }
                            }}
                            value={mobile}
                            onChange={(e) => set_mobile(convertToEnglishNumbers(e.target.value))}
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            placeholder="0912 1111111"
                        />
                    </div>:null
                }

                {mode === "email" ?
                    <div className="v2-dumbbell-login-input">
                        <img src={mailIcon} alt=""/>
                        <input
                            onKeyPress={e=>{if(e.key==="Enter"){sendCode()}}}
                             value={email}
                            onChange={e=>set_email(e.target.value)}
                            type="text" name="" id="" placeholder="me@gmail.com"/>
                    </div> : null
                }


                <div className="v2-dumbbell-login-btn clickable" onClick={sendCode}>
                    {spin?
                        <span style={{width:20,height:20,border:"1.5px solid #ffffff", borderBottomColor: "transparent"}} className="loader"></span>:
                        <span>ورود</span>

                    }


                </div>

            </div>


            {/*<div className="dumbbell-login-image">*/}
            {/*    <img src={loginImage} alt=""/>*/}
            {/*</div>*/}

            {/*<div className="dumbbell-login-logo">*/}
            {/*    <img src={logoWhite} alt=""/>*/}
            {/*</div>*/}

            {/*<div className="mode-sec">*/}
            {/*    <span>ورود با موبایل</span>*/}
            {/*    /!*{mode==="mobile"?*!/*/}
            {/*    /!*<span>ورود با موبایل</span>*!/*/}
            {/*    /!*    :<span>ورود با ایمیل</span>*!/*/}
            {/*    /!*}*!/*/}
            {/*    <Switch defaultChecked  onChange={onChange} />*/}
            {/*</div>*/}



            {/*<div className="dumbbell-login-input-sec">*/}
            {/*    {mode==="mobile"?<div className="dumbbell-login-input">*/}
            {/*            <img src={mobileIcon} alt=""/>*/}
            {/*            <input*/}
            {/*                onKeyPress={e=>{if(e.key==="Enter"){sendCode()}}}*/}
            {/*                value={mobile}*/}
            {/*                onChange={e=>set_mobile(e.target.value)}*/}
            {/*                type="text" placeholder="شماره موبایل"/>*/}
            {/*        </div>:*/}
            {/*        <div className="dumbbell-login-input align-center">*/}
            {/*            <MailOutlined style={{color:"#CECECE",fontSize:20}} />*/}
            {/*            <input*/}
            {/*                onKeyPress={e=>{if(e.key==="Enter"){sendCode()}}}*/}
            {/*                value={email}*/}
            {/*                onChange={e=>set_email(e.target.value)}*/}
            {/*                type="text" placeholder="ایمیل"/>*/}
            {/*        </div>}*/}
            {/*</div>*/}

            {/*<Spin spinning={spin}>*/}
            {/*    <div className="dumbbell-login-btn" onClick={sendCode}>*/}
            {/*        <span>ارسال کد تایید</span>*/}
            {/*    </div>*/}
            {/*</Spin>*/}



            <div className="dumbbell-login-copyright">
                <span>copywrite@2024  - all right reserved</span>
            </div>

        </div>
    )
}

export default DumbbellLogin;
