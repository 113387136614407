import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellBookingLand.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png"
import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png"
import Swiper from "react-id-swiper";
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import {convertMonthIntNumberToName, numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";
import {
    addService_api, changeDayDuration_api, changeStatus_api, changeStatusService_api,
    changeTurnStatus_api, deleteMember_api, editService_api,
    editTurn_api, getBooks_api, getDay_api, getLandTurns_api,
    getServices_api, getServices_land_api,
    getTurns_api, selfBook_api
} from "../../Services/Services";
import {Drawer, message, Spin, Switch} from "antd";
import DumbbellLandingPageAddBookingDrawer
    from "../DumbbellLandingPageAddBookingDrawer/DumbbellLandingPageAddBookingDrawer";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg";

let currentServiceId ="";
let currentGlobalDate ="";
let currentDuration ="";
let currentTab ="calender";

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const params = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    // rebuildOnUpdate : true,
    rtl:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}


function TurnCard({turn,date,serviceId,duration,getTurns,services,showPayDrawer}) {
    return(
        <div className="dumbbell-booking-land-item clickable" onClick={e=>{
            showPayDrawer(turn)
        }}>
            <div className="">
                <div className="turn-label">
                    <span>{turn.startAt}</span>
                    <span>تا</span>
                    <span>{turn.endAt}</span>
                </div>
            </div>
        </div>
    )
}



function DumbbellBookingLand({trainerId,slug}){


    const [mainSpin,set_mainSpin] = useState(true)
    const setLoader = useDispatchLoading()

    useEffect(()=>{
        setLoader(mainSpin)
    },[mainSpin])



    // تاریخ جاری شمسی
    const currentJalaliDate = moment().format('jYYYY/jMM/jDD');
    const currentYear = parseInt(moment().format('jYYYY'));
    const currentMonth = parseInt(moment().format('jMM'));

// تولید گزینه‌های سال
    const generateYearOptions = () => {
        const yearOptions = [];
        for (let year = currentYear; year <= currentYear + 10; year++) {
            yearOptions.push(
                <option key={year} value={year}>
                    {year}
                </option>
            );
        }
        return yearOptions;
    };


    const generateMonthOptions = () => {
        console.log("set_selectedMonth")
        const monthOptions = [];
        const months = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];

        for (let month = 1; month <= 12; month++) {
            // اگر سال جاری انتخاب شده، ماه‌های قبل از ماه جاری را غیرفعال کن
            if (parseInt(selectedYear) === currentYear && month < currentMonth) {
                continue;
            }
            monthOptions.push(
                <option key={month} value={month}>
                    {months[month - 1]}
                </option>
            );
        }
        return monthOptions;
    };


    //--------------------------------------------------------------- services
    const [selectedService,set_selectedService] = useState("");
    const [services,set_services] = useState([]);

    const getServices = async()=>{
        set_mainSpin(true)
        try{
            const resp = await getServices_land_api(slug);
            set_services([])

            set_services(resp.data.data)

            if(resp.data.data.length > 0){
                if(currentServiceId.length <1){
                    currentServiceId = resp.data.data[0]._id;
                }
                if(currentGlobalDate.length<1){
                    getTodayTurns(resp.data.data[0])
                    set_selectedService(resp.data.data[0]._id)
                    // currentServiceId = resp.data.data[0]._id
                }else {
                    // let t = generateTimes(currentGlobalDate)
                    // set_times(t)
                    // handleClickOnTimes(t[0],currentServiceId)
                    switch (currentTab) {
                        case 'service':
                            set_mainSpin(false)
                            break;
                        case 'calender':
                            getTurns()
                            break;
                    }
                }
            }else {
                set_mainSpin(false)
            }
            return resp.data.data
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }

    const getInitServices = async()=>{
         set_mainSpin(true)
        try{
            const resp = await getServices_land_api(slug);
            set_services(resp.data.data)
            if(resp.data.data.length > 0){
                set_selectedService(resp.data.data[0]._id)
                currentServiceId = resp.data.data[0]._id
                getTodayTurns(resp.data.data[0])
            }
            set_mainSpin(false)
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }

    //--------------------------------------------------- days
    const [day,set_day] = useState({});
    const getDay = async(date,serviceId)=>{
        let obj = {
            date:date,
            serviceId:serviceId
        }
        try{
            const resp = await getDay_api(obj);
            set_day([])
            set_day(resp.data.data)
            return resp.data.data
        }catch (e) {
            console.log(e)
        }
    }

    //--------------------------------------------------- turns
    const [turns,set_turns] = useState([]);

    const getTurns = async()=>{
        set_mainSpin(true)
        let obj = {
            date:currentGlobalDate,
            serviceId:currentServiceId,
            trainerId:trainerId
        }
        try{
            //check current date
            const resp = await getLandTurns_api(obj);
            set_mainSpin(false)
            set_turns([])
            set_turns(resp.data.data)
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }


    // ---------------------------------------------


    useEffect(()=>{
        document.body.style.backgroundColor = "white";
        init()
    },[])





    //----------------------------------------------------- handles method

    const handleChangeTab = async (tab)=>{
        set_activeTab(tab);
        currentTab = tab
        await getServices();
    }

    const handleChangeService = async(sId)=>{
        console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH L")
        set_selectedService(sId);
        currentServiceId = sId
        let day = await getDay(currentGlobalDate,currentServiceId)
        set_duration(day.duration.toString())
        currentDuration  = day.duration
        switch (currentTab) {
            case 'service':
                set_mainSpin(false)
                break;
            case 'calender':
                getTurns()
                break;
        }
    }

    const handleChangeDuration = async(due)=>{
        currentDuration  = due
        let status = await changeDayDuration()
        if(status)
         await getServices();
        else {
            message.error("به دلیل وجود نوبت در این روز شما اجازه تغییر بازه زمانی را ندارید")
        }
    }


    const [pageSpin,set_pageSpin] = useState(true)
    const handleClickOnTimes =async (t,sId)=>{
        set_currentTimes(t.index)
        set_selectTimes(t)
        let d = moment(t.jDate,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
        currentGlobalDate = d
        // let day = await getDay(d,sId)
        // set_duration(day.duration.toString())
        // currentDuration = day.duration;

        // getTurns(d,sId,day.duration)
        // getBooks(d,selectedService)
        console.log("today turn ....................bbbb",currentTab)
        switch (currentTab) {
            case 'calender':
                if(currentServiceId.length>0)
                    getTurns()
                break;
            default:
                set_mainSpin(false)
        }
    }



    //-----------------------------------------------------

    const getTodayTurns = async (service)=>{
        console.log("today turn .................... sss")
        const currentDate = new Date()
        let date = moment(currentDate).format('YYYY-MM-DD')
        let nowYear  = moment(currentDate).format('jYYYY')
        let nowMonth  = moment(currentDate).format('jMM')
        let nowDay  = moment(currentDate).format('jDD')

        set_selectedYear(nowYear)
        nowMonth = nowMonth.replace(/^0+/, '');
        set_selectedMonth(nowMonth)
        let t = newGenerateTimes(nowYear,nowMonth,nowDay)
        // let t = generateTimes(date)
        set_times(t)
        handleClickOnTimes(t[0],service._id)
    }



    const changeDayDuration = async()=>{
        let obj = {
            date:currentGlobalDate,
            serviceId:currentServiceId,
            duration:parseInt(currentDuration)
        }
        try{
            await changeDayDuration_api(obj);
            set_duration("")
            set_duration(currentDuration.toString())
            return true
        }catch (e) {
            return false
            console.log(e)
        }
    }

    const newGenerateTimes = (year, month, day) => {
        let results = [];
        console.log("new Gen Date", year, month, day)

        const currentYear = parseInt(moment().format('jYYYY'));
        const currentMonth = parseInt(moment().format('jMM'));
        const currentDay = parseInt(moment().format('jDD'));


        if (parseInt(year) === currentYear && parseInt(month) === currentMonth) {
            day = currentDay; // روز جاری
        } else {
            day = 1;
        }
        try{
            const daysInMonth = moment.jDaysInMonth(parseInt(year), parseInt(month));
            for (let i = day; i <= daysInMonth; i++) {
                let jDate = year + "-" + month + "-" + i;
                let date = moment(jDate, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
                let jDay = i;
                let jMonth = month;
                let jYear = year;
                let weekDay = convertDayToPersian(moment(jDate, 'jYYYY-jMM-jDD').format('dddd'));

                results.push({
                    index: i,
                    date: date,
                    jDate: jDate,
                    jDay: jDay,
                    jMonth: +jMonth,
                    jYear: jYear,
                    weekDay: weekDay,
                    parts: []
                });
            }
        }catch (e) {
            console.log("E: ",e)
        }





        return results;
    };




    const generateTimes = (date)=>{
        let start = new Date(date)
        let results =[]
        for (let i = 0; i < 30; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY-jMM-jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }
        return results
    }



    const init = ()=>{
        getInitServices()
    }


    const convertDayToPersian = (day) => {
        switch (day) {
            case 'Saturday':
                return 'شنبه';
            case 'Sunday':
                return 'یک‌شنبه';
            case 'Monday':
                return 'دو‌شنبه';
            case 'Tuesday':
                return 'سه‌شنبه';
            case 'Wednesday':
                return 'چهار‌شنبه';
            case 'Thursday':
                return 'پنج‌شنبه';
            case 'Friday':
                return 'جمعه';
            default:
                return 'نامعتبر';
        }
    };



    const MyRef = useRef(null);
    const [my,set_my] = useState(null)
    const getLocalUser = ()=>{
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }

    const [activeTab,set_activeTab] = useState("booking") //booking | services | calender

    const [times,set_times] = useState([])
    const [currentTimes,set_currentTimes]= useState("")
    const [selectTimes,set_selectTimes]= useState({})




    const convertDayOfWeek = (num)=>{
        // console.log("num",num)
        switch(num){
            case 1:
                return "دوشنبه"
                break;
            case 2:
                return "سشنبه"
                break;
            case 3:
                return "چهارشنبه"
                break;
            case 4:
                return "پنجشنبه"
                break;
            case 5:
                return "جمعه"
                break;
            case 6:
                return "شنبه"
                break;
            case 0:
                return "یکشنبه"
                break;


        }
    }







    const [selectedYear,set_selectedYear] = useState("")
    const [selectedMonth,set_selectedMonth] = useState("")

    // const handleSelectedYear = async (y)=>{
    //     set_selectedYear(y);
    //     let date_path = y+"-"+selectedMonth+"-"+selectTimes.jDay
    //     let d = moment(date_path,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
    //     let day = await getDay(d,selectedService)
    //     set_duration(day.duration.toString())
    //     currentDuration = day.duration
    //
    //     let ye  = moment(date_path,"jYYYY-jMM-jDD").format('YYYY')
    //     let mo  = moment(date_path,"jYYYY-jMM-jDD").format('MM')
    //     let gen_date = ye+"-"+mo+"-"+"1"
    //     console.log("gen_date",gen_date)
    //     let t = generateTimes(gen_date)
    //     set_times(t)
    //     handleClickOnTimes(t[0],selectedService)
    //     // getTurns(d,selectedService,day.duration)
    // }

    const handleSelectedYear = async (year) => {
        set_selectedYear(year);

        // ساختن مسیر تاریخ به صورت شمسی
        const jalaliDatePath = `${year}-${selectedMonth}-${selectTimes.jDay}`;
        const gregorianDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").format('YYYY-MM-DD');

        // دریافت روز و تنظیم مدت زمان
        const dayData = await getDay(gregorianDate, selectedService);
        const { duration } = dayData;
        set_duration(duration.toString());
        currentDuration = duration;

        // تولید تاریخ میلادی از ابتدای ماه
        const generatedStartDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").startOf('month').format('YYYY-MM-DD');
        console.log("generatedStartDate", generatedStartDate);
        console.log("generatedStartDate", year,currentYear,typeof selectedMonth,typeof currentMonth);


        // بررسی اینکه آیا ماه انتخاب‌شده از ماه جاری عقب‌تر است
        if (parseInt(year) < parseInt(currentYear) || (parseInt(year) === parseInt(currentYear) && parseInt(selectedMonth) < parseInt(currentMonth))) {
            // کال کردن تابع دلخواه اگر ماه عقب‌تر باشد
            set_selectedMonth(currentMonth)
            // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
            const generatedTimes = newGenerateTimes(year, currentMonth, 1);
            set_times(generatedTimes);

            // انتخاب اولین زمان تولید‌شده
            handleClickOnTimes(generatedTimes[0], selectedService);

        }else {
            // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
            const generatedTimes = newGenerateTimes(year, selectedMonth, 1);
            set_times(generatedTimes);

            // انتخاب اولین زمان تولید‌شده
            handleClickOnTimes(generatedTimes[0], selectedService);
        }


    };


    // const handleSelectedMonth = async (m)=>{
    //     set_selectedMonth(m);
    //     let date_path = selectedYear+"-"+m+"-"+selectTimes.jDay
    //     let d = moment(date_path,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
    //     let day = await getDay(d,selectedService)
    //     set_duration(day.duration.toString())
    //     currentDuration = day.duration
    //
    //     let ye  = moment(date_path,"jYYYY-jMM-jDD").format('YYYY')
    //     let mo  = moment(date_path,"jYYYY-jMM-jDD").format('MM')
    //
    //     let gen_date = ye+"-"+mo+"-"+"1"
    //     console.log("gen_date",gen_date)
    //     let t = generateTimes(gen_date)
    //     set_times(t)
    //     handleClickOnTimes(t[0],selectedService)
    //     // getTurns(d,selectedService,day.duration)
    // }

    const handleSelectedMonth = async (month) => {
        set_selectedMonth(month);

        // ساختن مسیر تاریخ به صورت شمسی
        const jalaliDatePath = `${selectedYear}-${month}-${selectTimes.jDay}`;
        const gregorianDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").format('YYYY-MM-DD');

        // دریافت روز و تنظیم مدت زمان
        const dayData = await getDay(gregorianDate, selectedService);
        const { duration } = dayData;
        set_duration(duration.toString());
        currentDuration = duration;

        // تولید تاریخ میلادی از ابتدای ماه
        const generatedStartDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").startOf('month').format('YYYY-MM-DD');
        console.log("generatedStartDate", generatedStartDate);

        // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
        const generatedTimes = newGenerateTimes(selectedYear, month, 1);
        set_times(generatedTimes);

        // انتخاب اولین زمان تولید‌شده
        handleClickOnTimes(generatedTimes[0], selectedService);
    };



    const [duration,set_duration] = useState("30")


    //Add Trainee Drawer
    const [visiblePayDrawer,set_visiblePayDrawer] = useState(false)
    const [selectedTurn,set_selectedTurn] = useState(null)

    const showPayDrawer = (t)=>{
        set_visiblePayDrawer(!visiblePayDrawer)
        set_selectedTurn(t)
    }

    const onPayDrawerClose = () => {
        set_visiblePayDrawer(false);

    };


    return(
        <div className="dumbbell-trainee-container">

            <Drawer bodyStyle={{padding:0,margin:0,backgroundColor:"##C6C3FF"}}   placement="right" width={300} closable={false} onClose={onPayDrawerClose} visible={visiblePayDrawer}>
                <DumbbellLandingPageAddBookingDrawer turn={selectedTurn} trainerId={trainerId}/>
            </Drawer>

            <div>
                <br/>
                {currentServiceId.length>0?
                    <Fragment>
                        {activeTab==="booking" || activeTab==="calender"?
                            <Fragment>
                                {/*date filter*/}
                                <div className="dumbbell-booking-mg-date-filter">
                                    <div className="dumbbell-booking-mg-date-filter-item">
                                        <select
                                            value={selectedMonth}
                                            onChange={e=>handleSelectedMonth(e.target.value)}
                                            name="" id="">
                                            {generateMonthOptions()}
                                        </select>
                                    </div>

                                    <div className="dumbbell-booking-mg-date-filter-item">
                                        <select
                                            value={selectedYear}
                                            onChange={e=>handleSelectedYear(e.target.value)}
                                            name="" id="">
                                            {generateYearOptions()}
                                        </select>
                                    </div>
                                </div>


                                {/*choose days*/}
                                <div className="dumbbell-booking-mg-days-regal">

                                    {times.length>0?<div className="plan-bar" style={{margin:'4px 10px'}}>

                                        <div className="plan-bar-list">
                                            <Swiper ref={MyRef}  {...params}>
                                                {times.map(time=>{

                                                    return(
                                                        <div  className={'plan-bar-item'}
                                                              style={currentTimes===time.index?{borderRadius:5,padding:"2px",color:"white",backgroundColor:"#8D89FD",fontFamily:"YekanBold"}:{}}
                                                              onClick={e=>{
                                                                  handleClickOnTimes(time,selectedService)
                                                              }}>

                                                            <span>{time.jDay}</span>
                                                            <span>{time.weekDay}</span>
                                                        </div>
                                                    )
                                                })}

                                            </Swiper>
                                        </div>
                                    </div>:null}


                                </div>


                                {/*choose services*/}
                                <div className="dumbbell-booking-mg-service">
                                    {activeTab==="calender"?
                                        <select
                                            value={duration}
                                            onChange={e=>{
                                                handleChangeDuration(e.target.value)
                                            }}
                                            name="" id="">
                                            <option value="5">۵ دقیقه</option>
                                            <option value="10">۱۰ دقیقه</option>
                                            <option value="15">۱۵ دقیقه</option>
                                            <option value="20">۲۰ دقیقه</option>
                                            <option value="25">۲۵ دقیقه</option>
                                            <option value="30">۳۰ دقیقه</option>
                                            <option value="35">۳۵ دقیقه</option>
                                            <option value="40">۴۰ دقیقه</option>
                                            <option value="45">۴۵ دقیقه</option>
                                            <option value="50">۵۰ دقیقه</option>
                                            <option value="55">۵۵ دقیقه</option>
                                            <option value="60">۶۰ دقیقه</option>
                                            <option value="90">۹۰ دقیقه</option>
                                            <option value="120">۲ ساعت</option>
                                            <option value="180">۳ ساعت</option>
                                        </select>:null
                                    }
                                    <select
                                        value={selectedService}
                                        onChange={e=>{
                                            handleChangeService(e.target.value)
                                        }}
                                        name="" id="">
                                        {services?.map(s=>{
                                            return(
                                                <option value={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Fragment>:null
                        }

                    </Fragment>:null
                }


                <div className="dumbbell-booking-mg-calender-list">

                    {/*booking item*/}
                    {turns.map(t=>{
                        return(
                            <TurnCard showPayDrawer={showPayDrawer} services={services} getTurns={getTurns} turn={t} date={selectTimes} duration={duration} serviceId={selectedService} />
                        )
                    })}
                    {turns.length<1 && !mainSpin?
                        <div className="empty-state">
                            <img src={emptyIcon} alt=""/>
                            <span>برای این روز نوبتی وجود </span>
                            <span>ندارد !</span>
                        </div>:null
                    }

                </div>

            </div>


        </div>
    )
}



export default DumbbellBookingLand;
