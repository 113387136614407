import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DumbbellBookingManagement.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png"
import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png"
import Swiper from "react-id-swiper";
import trashIcon from "../../assets/dumbbell_image/trash-icon.png";
import {convertMonthIntNumberToName, convertToEnglishNumbers, numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";
import {
    addService_api,
    bookSeenApi,
    bookSeenFromCalendarApi,
    changeDayDuration_api,
    changeStatus_api,
    changeStatusService_api,
    changeTurnStatus_api,
    deleteMember_api,
    editService_api,
    editTurn_api,
    getBooks_api,
    getDay_api,
    getServices_api,
    getTurns_api,
    selfBook_api
} from "../../Services/Services";
import {Drawer, message, Spin, Switch} from "antd";

import DumbbellVideoUpload from "../DumbbellVideoUpload/DumbbellVideoUpload";
import {Collapse} from "react-collapse";
import mobileIcon from "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import {useHistory} from "react-router";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg";
import markerIcon from "../../assets/dumbbell_image/V2/marker_open.png";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import locationIcon from "../../assets/dumbbell_image/V2/location_icon.svg";

let currentServiceId ="";
let currentGlobalDate ="";
let currentDuration ="";
let currentTab ="booking";

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const params = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    // rebuildOnUpdate : true,
    rtl:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    }
}

function BookCard({item,getBooks}){

    //default , booked ,accepted , cancel_me , cancel_client , not_come
    const [memberStatus,set_memberStatus] = useState("")
    const [statusSpin,set_statusSpin] = useState(false)

    useEffect(()=>{
        set_memberStatus(item.member.status)
    },[])


    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const history = useHistory()

    const [pageSeen,set_pageSeen] = useState(false);
    const [currentSeen,set_currentSeen] = useState(item.member.seen);


    const seen = async (id)=>{
        try{
            await bookSeenFromCalendarApi(id);
        }catch(e){
            console.log(e)
        }
    }
    //
    useEffect(()=>{
        if(!currentSeen && isVisible ){
            console.log(item._id,"seen",isVisible)
            set_currentSeen(true)
            seen(item.member.link_id)
        }
    },[isVisible])

    const handleChangeMemberStatus = async (s)=>{
        handleClickCloseBottomDrawer()
        set_statusSpin(true)
        let obj = {
            turnId:item.turn._id,
            memberId:item.member._id,
            status:s
        }
        try{
            await changeStatus_api(obj)
            set_memberStatus(s)
            getBooks()
            set_statusSpin(false)
        }catch (e) {
            console.log(e)
            set_statusSpin(false)
            message.error("نمیتونی وضعیت و تغییر بدی")
        }
    }

    const bookBackColor = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"
        }

        if(status==="cancel_me"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-me-status"
        }

        if(status==="cancel_client"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-cancel-client-status"
        }

        if(status==="accepted"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-accepted-status"
        }

        if(status==="not_come"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }


    const convertStatusToName = (status)=>{

        console.log("status :",status)

        if(status==="booked"){
            return "رزرو شده"
        }

        if(status==="cancel_me"){
            return "کنسل توسط خودم"
        }

        if(status==="cancel_client"){
            return "کنسل توسط مشتری"
        }

        if(status==="accepted"){
            return "پذیرش شده"
        }

        if(status==="not_come"){
            return "مراجعه نکرده"
        }

        return "رزرو شده"

    }



    const [collapseCard,set_collapseCard] = useState(false)
    const [currentStatus,set_currentStatus] = useState(item.member.status)

    //Drawer
    const [visibleBottomDrawer,set_visibleBottomDrawer] = useState(false)
    const [selectedVideo,set_selectedVideo] = useState("")


    const showBottomDrawer = ()=>{
        set_visibleBottomDrawer(!visibleBottomDrawer)
    }

    const onBottomDrawerClose = () => {
        set_visibleBottomDrawer(false);
    };

    const handleClickCloseBottomDrawer = (m)=>{
        set_visibleBottomDrawer(false);
    }

    return(
        <Fragment>
            <Spin spinning={statusSpin}>
                <div className="dumbbell-booking-mg-booking-item" ref={ref}>
                    {/*<div className={bookBackColor(memberStatus)}>*/}
                    {/*    <div className="dumbbell-booking-mg-booking-item-header-approve">*/}
                    {/*        <span>{item.member.number}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="dumbbell-booking-mg-booking-item-header-status">*/}
                    {/*        <select*/}
                    {/*            value={memberStatus}*/}
                    {/*            onChange={e=>{*/}
                    {/*                 handleChangeMemberStatus(e.target.value)*/}
                    {/*            }}*/}
                    {/*            name="" id="">*/}
                    {/*            <option value="booked">رزرو شده</option>*/}
                    {/*            <option value="accepted">پذیرش شده</option>*/}
                    {/*            <option value="cancel_client">کنسل توسط مشتری</option>*/}
                    {/*            <option value="cancel_me">کنسل توسط خودم</option>*/}
                    {/*            <option value="not_come">ممراجعه نکرده</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="plan-card-fix-row clickable" onClick={e=>{
                        set_collapseCard(!collapseCard)
                    }}>

                        <div className="plan-card-fix-price">
                            <span className="price">{`${item.turn.startAt} - ${item.turn.endAt}`}</span>
                            <span className="unit">{moment(item.member.date,"YYYY-MM-DD").format("jYYYY/jMM/jDD")}</span>
                        </div>

                        <div className="plan-card-fix-trainee">
                            <span className="trainee-name">{item.member.first_name+" "+item.member.last_name}</span>
                            <span className="trainee-date">{item.member.mobile}</span>
                            <span className={bookBackColor(item.member.status)}>{convertStatusToName(item.member.status)}</span>
                        </div>
                    </div>

                    <Collapse isOpened={collapseCard}>
                        <Fragment>

                            <div className="booking-card-price-sec">
                                <div className="booking-card-price-row">
                                    <div className="booking-card-price-item">
                                        <h6>{item.member.number}</h6>
                                    </div>
                                    <div className="booking-card-price-item">
                                        <span>شماره </span>
                                    </div>
                                </div>
                                <div className="booking-card-price-row">
                                    <div className="booking-card-price-item">
                                        <h6>{numberWithCommas(item.member.cost)} تومان </h6>
                                    </div>
                                    <div className="booking-card-price-item">
                                    <span>قیمت سرویس</span>
                                    </div>
                                </div>
                                <div className="booking-card-price-row">
                                    <div className="booking-card-price-item">
                                        <h6>{numberWithCommas(item.member.pre_cost)} تومان </h6>
                                    </div>
                                    <div className="booking-card-price-item">
                                        <span>قیمت پرداخت شده</span>
                                    </div>
                                </div>
                            </div>


                            <div className="booking-card-description-sec">
                                <span>توضیحات</span>
                                <p>{item.member.description}</p>
                            </div>

                            <div className="booking-card-price-btn">
                                <span className="clickable" onClick={showBottomDrawer}>{convertStatusToName(item.member.status)}</span>
                            </div>

                        </Fragment>
                    </Collapse>


                    <Drawer height={350}
                            bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                            mask={true}  closable={false}  placement="bottom" onClose={onBottomDrawerClose} visible={visibleBottomDrawer}>

                        <div className="bottom-drawer-handle">
                            <span></span>
                        </div>
                        <div className="bottom-drawer-body">
                            <div className="bottom-drawer-item clickable" onClick={e=>{
                                handleChangeMemberStatus("booked")
                            }}>
                                {/*<img src={mobileIcon} alt=""/>*/}
                                <span className="label">رزرو شده</span>
                            </div>
                            <div className="bottom-drawer-item clickable" onClick={e=>{
                                handleChangeMemberStatus("cancel_me")
                            }}>
                                {/*<img src={mailIcon} alt=""/>*/}
                                <span className="label">کنسل توسط خودم</span>
                            </div>

                            <div className="bottom-drawer-item clickable" onClick={e=>{
                                handleChangeMemberStatus("cancel_client")
                            }}>
                                {/*<img src={mailIcon} alt=""/>*/}
                                <span className="label">کنسل توسط مشتری</span>
                            </div>

                            <div className="bottom-drawer-item clickable" onClick={e=>{
                                handleChangeMemberStatus("accepted")
                            }}>
                                {/*<img src={mailIcon} alt=""/>*/}
                                <span className="label">پذیرش شده</span>
                            </div>
                            <div className="bottom-drawer-item clickable" onClick={e=>{
                                handleChangeMemberStatus("not_come")
                            }}>
                                {/*<img src={mailIcon} alt=""/>*/}
                                <span className="label">مراجعه نکرده</span>
                            </div>
                        </div>
                    </Drawer>


                    {/*<p>{item.member.description}</p>*/}

                </div>
            </Spin>
        </Fragment>
    )
}

function ServiceCard({item,getServices}){

    const [status,set_status] = useState(item.status)


    const onChange = async (checked) => {
        set_cardSpin(true)

        let obj = {
            status:checked?"active":"inactive"
        }

        try{
            await changeStatusService_api(obj,item._id)
            set_cardSpin(false)
            getServices()
            onServiceEditDrawerClose()
            set_status(checked)
            return message.success({
                content: checked?"سرویس فعال شد":"سرویس غیر فعال شد",
                className: 'toast-class'
            });
        }catch(e){
            console.log(e)
            return message.error({
                content: "مشکل در ویرایش",
                className: 'toast-class'
            });
            console.log(e)
        }
    };

    const onChangeIsMe = (checked) => {
        set_is_me(checked)
    };

    //service
    const [visibleServiceEditDrawer,set_visibleServiceEditeDrawer] = useState(false)


    const showServiceEditDrawer = ()=>{
            set_visibleServiceEditeDrawer(!visibleServiceEditDrawer)
    }

    const onServiceEditDrawerClose = () => {
        set_visibleServiceEditeDrawer(false);
    };

    const [cardSpin,set_cardSpin] = useState(false)
    const [title,set_title] = useState("")
    const [cost,set_cost] = useState("")
    const [pre_cost,set_pre_cost] = useState("")
    const [is_me,set_is_me] = useState(false)

    useEffect(()=>{
        set_title(item.title)

        const costValue = item.cost.replace(/\D/g, "");
        set_cost(costValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        const preCostValue = item.pre_cost.replace(/\D/g, "");
        set_pre_cost(preCostValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));


        set_address(item.address)
        set_location(item.location)
        if(item.location?.lat){
            setPosition([item.location.lat,item.location.lng])
        }

        set_is_me(item.is_me)
    },[])


    const handleCostChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };
    const handlePreCostChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_pre_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };


    const editService =async ()=>{

        let cost_num= cost.replaceAll(",", "");
        let pre_cost_num= pre_cost.replaceAll(",", "");


        let obj = {
            title:title,
            cost:parseInt(cost_num),
            pre_cost:parseInt(pre_cost_num),
            duration:30,
            address:address,
            location:location,
            is_me:is_me,
        }

        try{
            await editService_api(obj,item._id)
            getServices()
            onServiceEditDrawerClose()
            return message.success({
                content: "سرویس ویرایش شد",
                className: 'toast-class'
            });
        }catch(e){
            console.log(e)
            return message.error({
                content: "مشکل در ویرایش",
                className: 'toast-class'
            });
            console.log(e)
        }

    }


    //inner map
    const [address,set_address] = useState("")
    const [mapCenter,set_mapCenter] = useState(null)
    const [location,set_location] = useState({})
    const [position, setPosition] = useState([35.6892, 51.3890])
    let mapRef = useRef();
    const [visibleMapDrawer,set_visibleMapDrawer] = useState(false)
    const showMapDrawer =()=>{
        set_visibleMapDrawer(true)
    }
    const closeMapDrawer =()=>{
        set_visibleMapDrawer(false)
    }
    const setAdd = (lat,lng)=>{
        set_mapCenter({lat,lng})
    }
    const handleAcceptLocationClick = ()=>{
        set_location(mapCenter)
        closeMapDrawer()
    }
    function LocationMarker({getAdd}) {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                console.log("click")
            },
            moveend(e) {
                let cc = map.getCenter()
                console.log(cc.lat,cc.lng)
                getAdd(cc.lat,cc.lng)
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }



    return(
        <Fragment>
            <Spin spinning={cardSpin}>
                <div className="service-item">
                    <div className="service-item-header">

                        <div className="service-item-header-edit clickable" onClick={showServiceEditDrawer}>
                            <span>ویرایش</span>
                        </div>

                        <div className="service-item-header-status">
                            <Switch checked={status==="active"}  onChange={onChange} />
                            <span>{status==="active"?"فعال":"غیرفعال"}</span>
                        </div>

                    </div>
                    <div className="service-item-row title">
                        <span className="service-item-row-value">{item.title}</span>
                        <span className="service-item-row-label">نام سرویس : </span>

                    </div>
                    <div className="service-item-row cost">
                        <span className="service-item-row-value">{numberWithCommas(item.cost)} تومان </span>
                        <span className="service-item-row-label">هزینه سرویس : </span>
                    </div>

                    <div className="service-item-row pre-cost">
                        <span className="service-item-row-value">{numberWithCommas(item.pre_cost)} تومان </span>
                        <span className="service-item-row-label">هزینه رزور : </span>

                    </div>

                    <div className="service-item-row duration">
                        <span className="service-item-row-value">{item.duration}</span>
                        <span className="service-item-row-label">مدت زمان سرویس : </span>

                    </div>
                    <div className="service-item-row is-me">
                        <span className="service-item-row-value">{item.is_me?"هست":"نیست"}</span>
                        <span className="service-item-row-label">سرویس خودم</span>

                    </div>

                    <Drawer height={550}
                            bodyStyle={{padding:0,margin:0,backgroundColor:"#ffffff",borderRadius:"15px 15px 0 0"}}
                            mask={true}  closable={false}  placement="bottom"
                            onClose={closeMapDrawer}
                            visible={visibleMapDrawer}>
                        <div className="osm-sec">
                            <div className="accept-location">
                                <span className="clickable" onClick={handleAcceptLocationClick}>تایید موقعیت</span>
                            </div>
                            <div className="osm-custom-flag">
                                <img src={markerIcon} alt=""/>
                            </div>
                            <MapContainer
                                style={{width:"100%",height:"100%"}}
                                ref={mapRef}
                                center={position}
                                zoom={16}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                                <LocationMarker getAdd={setAdd}/>
                            </MapContainer>

                        </div>


                    </Drawer>

                    <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onServiceEditDrawerClose} visible={visibleServiceEditDrawer}>

                        <div className="add-service-drawer">

                            <div className="add-service-drawer-header">
                                <span>ویرایش سرویس</span>
                            </div>

                            <div className="add-service-drawer-item">
                                <input
                                    value={title}
                                    onChange={e=>set_title(e.target.value)}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">نام سرویس  </span>
                            </div>

                            <div className="add-service-drawer-item">
                                <input
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    value={cost}
                                    onChange={e=>handleCostChange(convertToEnglishNumbers(e.target.value))}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">هزینه سرویس (تومان) </span>
                            </div>

                            <div className="add-service-drawer-item">
                                <input
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    value={pre_cost}
                                    onChange={e=>handlePreCostChange(convertToEnglishNumbers(e.target.value))}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">هزینه رزرو سرویس (تومان) </span>
                            </div>

                            {/*<div className="add-service-drawer-item">*/}
                            {/*    <input*/}
                            {/*        value={duration}*/}
                            {/*        onChange={e=>set_duration(e.target.value)}*/}
                            {/*        type="text" name="" id=""/>*/}
                            {/*    <span>مدت زمان سرویس(دقیقه)</span>*/}
                            {/*</div>*/}

                            <div className="add-service-drawer-item">
                                <input
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    value={address}
                                    onChange={e=>set_address(e.target.value)}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">آدرس محل سرویس</span>
                            </div>

                            <div className="add-service-drawer-item">
                                <div className="page-actions-btn clickable location-btn">
                                    <div className="page-actions-btn-icon">
                                        <img src={locationIcon} alt=""/>
                                    </div>
                                    <div className="page-actions-btn-label-left" onClick={showMapDrawer}>
                                        <span>تغییر موقعیت نقشه</span>
                                    </div>
                                </div>
                                <span className="add-service-drawer-item-label">موقعیت سرویس</span>
                            </div>

                            <div className="add-service-drawer-item">
                                <Switch checked={is_me}  onChange={onChangeIsMe} />
                                <span className="add-service-drawer-item-label">سرویس خودم</span>
                            </div>

                            <div className="add-service-drawer-btn clickable" >
                                    <span onClick={e=>{
                                        editService()
                                    }}>ذخیره تغییرات</span>
                            </div>

                        </div>

                    </Drawer>

                </div>
            </Spin>
        </Fragment>
    )
}

function TurnCard({turn,date,serviceId,duration,getTurns,services}) {


    const [spin,set_spin] = useState(false)

    const [DeleteSpin,set_DeleteSpin] = useState(false)

    const handleDeleteMember = async (m)=>{
        set_DeleteSpin(true)
        let obj = {
            turnId:turn._id,
            memberId:m._id
        }
        try{
            const resp = await deleteMember_api(obj);
            onTurnDetailDrawerClose()
            onBookListDrawerClose()
            getTurns()
            set_DeleteSpin(false)
        }catch (e) {
            set_DeleteSpin(false)
            console.log(e)
        }
    }
    const capacityBackColor = (turn)=>{
        let left = turn.capacity-turn.capacity_use;

        if(left===turn.capacity){
            return "dumbbell-booking-mg-calender-item-capacity-empty"
        }

        if(left<=0){
            return "dumbbell-booking-mg-calender-item-capacity-fill"
        }

        if(left>0){
            return "dumbbell-booking-mg-calender-item-capacity-limit"
        }


        return "dumbbell-booking-mg-calender-item-capacity-empty"

    }
    const turnChangeStatus = async()=>{
        set_spin(true)
        let obj = {
            startAt:turn.startAt,
            startMin:turn.startMin,
            endAt:turn.endAt,
            endMin:turn.endMin,
            date:moment(date.jDate,'jYYYY-jMM-jDD').format('YYYY-MM-DD'),
            serviceId:serviceId,
            duration:duration,
            status:turn.status &&  turn.status==="active"?"default":"active",
        }
        try{
            await changeTurnStatus_api(obj)
            await getTurns()
            set_spin(false)
        }catch (e) {
            message.error("بدیل رزرو این بازه زمانی در سرویس دیگر فعال کردن امکان پذیز نمیباشد")
            set_spin(false)
            console.log(e)
        }
    }


    //service
    const [visibleTurnDetailDrawer,set_visibleTurnDetaileDrawer] = useState(false)
    const [currentService,set_currentService] = useState(false)


    const showTurnDetailDrawer = ()=>{
        if(turn.status==="active"){
            let findCurrentService = services.find(s=>s._id===currentServiceId)
            set_currentService(findCurrentService)
            set_visibleTurnDetaileDrawer(!visibleTurnDetailDrawer)
        }else {
            message.error("برای مشاهده جزییات باید بازه زمانی فعال باشد")
        }
    }

    const onTurnDetailDrawerClose = () => {
        set_visibleTurnDetaileDrawer(false);
    };

    const [cost,set_cost] = useState("")
    const [pre_cost,set_pre_cost] = useState("")
    const [capacity,set_capacity] = useState("")
    const [turnSpin,set_turnSpin] = useState(false)

    const handleCostChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };
    const handlePreCostChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        set_pre_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };


    useEffect(()=>{
        if(turn.cost){
            let st_cost = turn.cost.toString()
            const costValue = st_cost.replace(/\D/g, "");
            set_cost(costValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }

        if(turn.pre_cost) {
            let st_pre_cost = turn.pre_cost.toString()
            const preCostValue = st_pre_cost.replace(/\D/g, "");
            set_pre_cost(preCostValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }


        set_capacity(turn.capacity)
    },[])

    const [me,set_me] = useState(true)


    const onChange = (checked) => {
        set_me(!me)
    };

    const editTurn = async () => {

        let cost_num= cost.replaceAll(",", "");
        let pre_cost_num= pre_cost.replaceAll(",", "");

        cost_num = parseInt(cost_num)
        pre_cost_num = parseInt(pre_cost_num)
        let capacity_num =parseInt(capacity)

        if(!cost_num || typeof cost_num !=="number"){
            return message.error({
                content: "هزینه سرویس را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!pre_cost_num || typeof pre_cost_num !=="number"){
            return message.error({
                content: "هزینه رزرو سرویس را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!currentService.is_me){
            if(!capacity_num || typeof capacity_num !=="number"){
                return message.error({
                    content: "ظرفیت نوبت باید شامل عدد باشد",
                    className: 'toast-class'
                });
            }
        }


        let obj = {
            date:turn.date,
            pre_cost:pre_cost_num,
            cost:cost_num,
            capacity:capacity_num,
            startAt:turn.startAt,
            endAt:turn.endAt,
            serviceId:turn.service_id
        }

        try{
            set_turnSpin(true)
            await editTurn_api(obj)
            await getTurns(turn.date,serviceId,duration)
            set_turnSpin(false)
            onTurnDetailDrawerClose()
            return message.success({
                content: "نوبت ویرایش شد",
                className: 'toast-class'
            });
        }catch(e){
            set_turnSpin(false)
            console.log(e)
        }
    };


    //book list
    //book
    const [visibleBookListDrawer,set_visibleBookListeDrawer] = useState(false)


    const showBookListDrawer = ()=>{
        set_visibleBookListeDrawer(!visibleBookListDrawer)
    }

    const onBookListDrawerClose = () => {
        set_visibleBookListeDrawer(false);
    };

    //book
    const [visibleSelfBookDrawer,set_visibleSelfBookeDrawer] = useState(false)


    const showSelfBookDrawer = ()=>{
        set_visibleSelfBookeDrawer(!visibleSelfBookDrawer)
    }

    const onSelfBookDrawerClose = () => {
        set_visibleSelfBookeDrawer(false);
    };

    const [fname,set_fname] = useState("")
    const [lname,set_lname] = useState("")
    const [mobile,set_mobile] = useState("")
    const [description,set_description] = useState("")


    const bookTurn = async () => {


        if(fname.length<1){
            return message.error({
                content: "نام  را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(lname.length<1){
            return message.error({
                content: "نام خانوادگی را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!validation("MOBILE",mobile)){
            set_spin(false)
            return message.error({
                content: "فرمت موبایل درست نمیباشد",
                className: 'toast-class'
            });
        }

        let obj = {
            first_name:fname,
            last_name:lname,
            mobile:mobile,
            description:description,
            turn:turn
        }
        try{
            set_turnSpin(true)
            const res= await selfBook_api(obj)
            await getTurns(turn.date,serviceId,duration)
            set_turnSpin(false)
            onSelfBookDrawerClose()
            onTurnDetailDrawerClose()
            return message.success({
                content: "رزرو انجام شد",
                className: 'toast-class'
            });
        }catch(e){
            set_turnSpin(false)
            return message.error({
                content: "رزرو امکان پذیر نمیباشد",
                className: 'toast-class'
            });
            console.log(e)
        }

    };

    return(
        <div className="dumbbell-booking-mg-calender-item">

            <div className={
                turn.status && turn.status==="active"  ?"dumbbell-booking-mg-calender-item-time active-time":"dumbbell-booking-mg-calender-item-time"
            } onClick={e=>{
                showTurnDetailDrawer()
            }}>
                <Spin spinning={spin}>
                <div className="turn-label">
                    <span>{turn.startAt}</span>
                    <span>تا</span>
                    <span>{turn.endAt}</span>
                </div>
                </Spin>
            </div>
            <div className={capacityBackColor(turn)} onClick={e=>{

                turnChangeStatus()
            }}>
                {/*<span>{turn.capacity?turn.capacity-turn.capacity_use:"1"}</span>*/}
                <Switch checked={turn.status==="active"}/>
            </div>


            <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onTurnDetailDrawerClose} visible={visibleTurnDetailDrawer}>

                <Spin spinning={turnSpin}>
                    <Fragment>





                        <div className="add-service-drawer">


                            <div className="add-service-drawer-header">
                                <span>ویرایش نوبت</span>
                            </div>

                            <div className="add-service-drawer-item">
                                <input
                                    value={cost}
                                    onChange={e=>handleCostChange(e)}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">هزینه نوبت (تومان)</span>
                            </div>

                            <div className="add-service-drawer-item">
                                <input
                                    value={pre_cost}
                                    onChange={e=>handlePreCostChange(e)}
                                    type="text" name="" id=""/>
                                <span className="add-service-drawer-item-label">هزینه رزرو نوبت (تومان)</span>
                            </div>

                            {!currentService.is_me?
                            <div className="add-service-drawer-item">
                                <input
                                value={capacity}
                                onChange={e=>set_capacity(e.target.value)}
                                type="text" name="" id=""/>

                                <span className="add-service-drawer-item-label">ظرفیت نوبت</span>
                                </div>:null
                            }





                            {/*<div className="add-service-drawer-item">*/}
                            {/*    <Switch defaultChecked  onChange={onChange} />*/}
                            {/*    <span className="add-service-drawer-item-label">سرویس خودم</span>*/}
                            {/*</div>*/}

                            <div className="add-service-drawer-btn clickable" >
                                    <span onClick={e=>{
                                        editTurn()
                                    }}>ثبت</span>
                            </div>

                            <div className="add-service-drawer-self-btn clickable" >
                                    <span onClick={e=>{
                                        showSelfBookDrawer()
                                    }}>رزرو توسط خودم</span>
                            </div>

                            <div className="add-service-drawer-self-btn clickable" >
                                    <span onClick={e=>{
                                        showBookListDrawer()
                                    }}>دیدن لیست رزروی ها</span>
                            </div>

                        </div>

                        <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onBookListDrawerClose} visible={visibleBookListDrawer}>
                            <div className="member-list">
                                <div className="member-list-header">
                                    <span>لیست رزرو ها</span>
                                </div>
                                {turn?.members?.map(m=>{
                                    return(
                                        <Spin spinning={DeleteSpin}>

                                            <div className="member-item">
                                                {!m.payedAt?
                                                    <div className="member-item-delete clickable" onClick={e=>{
                                                        handleDeleteMember(m)}
                                                    }>
                                                        <img src={trashIcon} alt=""/>
                                                    </div>:
                                                    <div>

                                                    </div>
                                                }
                                                <div className="member-item-user">
                                                    <span className="member-item-user-info">{m.first_name+" "+m.last_name}</span>
                                                    <span className="member-item-user-mobile">{m.mobile}</span>
                                                </div>
                                            </div>

                                        </Spin>
                                    )
                                })}


                            </div>
                        </Drawer>

                        <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onSelfBookDrawerClose} visible={visibleSelfBookDrawer}>
                            <div className="add-service-drawer">
                                <div className="add-service-drawer-item">
                                    <input
                                        value={fname}
                                        onChange={e=>set_fname(e.target.value)}
                                        type="text" name="" id=""/>
                                    <span className="add-service-drawer-item-label">نام</span>
                                </div>

                                <div className="add-service-drawer-item">
                                    <input
                                        value={lname}
                                        onChange={e=>set_lname(e.target.value)}
                                        type="text" name="" id=""/>
                                    <span className="add-service-drawer-item-label">نام خانوادگی</span>
                                </div>

                                <div className="add-service-drawer-item">
                                    <input
                                        value={mobile}
                                        onChange={e=>set_mobile(e.target.value)}
                                        type="text" name="" id=""/>
                                    <span className="add-service-drawer-item-label">موبایل</span>
                                </div>


                                <div className="add-service-drawer-item">
                                    <textarea
                                        value={description}
                                        onChange={e=>set_description(e.target.value)}
                                    >
                                    </textarea>
                                    <span className="add-service-drawer-item-label">توضیحات</span>
                                </div>

                                <div className="add-service-drawer-btn clickable" >
                                    <span onClick={e=>{
                                        bookTurn()
                                    }}>رزرو نوبت</span>
                                </div>

                            </div>
                        </Drawer>



                    </Fragment>

                </Spin>

            </Drawer>





        </div>
    )
}



function DumbbellBookingManagement(){

    const setLoader = useDispatchLoading()
    const [mainSpin,set_mainSpin] = useState(false)

    useEffect(()=>{
        setLoader(mainSpin)
    },[mainSpin])




    // تاریخ جاری شمسی
    const currentJalaliDate = moment().format('jYYYY/jMM/jDD');
    const currentYear = parseInt(moment().format('jYYYY'));
    const currentMonth = parseInt(moment().format('jMM'));

// تولید گزینه‌های سال
    const generateYearOptions = () => {
        const yearOptions = [];
        for (let year = currentYear; year <= currentYear + 10; year++) {
            yearOptions.push(
                <option key={year} value={year}>
                    {year}
                </option>
            );
        }
        return yearOptions;
    };


    const generateMonthOptions = () => {
        console.log("set_selectedMonth")
        const monthOptions = [];
        const months = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];

        for (let month = 1; month <= 12; month++) {
            // اگر سال جاری انتخاب شده، ماه‌های قبل از ماه جاری را غیرفعال کن
            if (parseInt(selectedYear) === currentYear && month < currentMonth) {
                continue;
            }
            monthOptions.push(
                <option key={month} value={month}>
                    {months[month - 1]}
                </option>
            );
        }
        return monthOptions;
    };


    //--------------------------------------------------------------- services
    const [selectedService,set_selectedService] = useState("");
    const [services,set_services] = useState([]);

    const getServices = async()=>{
        set_mainSpin(true)
        try{
            const resp = await getServices_api();
            set_services([])

            set_services(resp.data.data)

            if(resp.data.data.length > 0){
                if(currentServiceId.length <1){
                    currentServiceId = resp.data.data[0]._id;
                }
                if(currentGlobalDate.length<1){
                    getTodayTurns(resp.data.data[0])
                    set_selectedService(resp.data.data[0]._id)
                    // currentServiceId = resp.data.data[0]._id
                }else {
                    // let t = generateTimes(currentGlobalDate)
                    // set_times(t)
                    // handleClickOnTimes(t[0],currentServiceId)
                    switch (currentTab) {
                        case 'booking':
                            getBooks()
                            break;
                        case 'service':
                            set_mainSpin(false)
                            break;
                        case 'calender':
                            getTurns()
                            break;
                    }
                }
            }else {
                set_mainSpin(false)
            }
            return resp.data.data
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }

    const getInitServices = async()=>{
         set_mainSpin(true)
        try{
            const resp = await getServices_api();
            set_services(resp.data.data)
            if(resp.data.data.length > 0){
                getTodayTurns(resp.data.data[0])
                set_selectedService(resp.data.data[0]._id)
                currentServiceId = resp.data.data[0]._id
            }
            set_mainSpin(false)
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }

    //--------------------------------------------------- days
    const [day,set_day] = useState({});
    const getDay = async(date,serviceId)=>{
        let obj = {
            date:date,
            serviceId:serviceId
        }
        try{
            const resp = await getDay_api(obj);
            set_day([])
            set_day(resp.data.data)
            return resp.data.data
        }catch (e) {
            console.log(e)
        }
    }

    //--------------------------------------------------- turns
    const [turns,set_turns] = useState([]);

    const getTurns = async()=>{
        let obj = {
            date:currentGlobalDate,
            serviceId:currentServiceId,
            duration:parseInt(currentDuration)
        }
        try{
            const resp = await getTurns_api(obj);
            set_mainSpin(false)
            set_turns([])
            set_turns(resp.data.data)
        }catch (e) {
            set_mainSpin(false)
            console.log(e)
        }
    }

    //--------------------------------------------------- books
    const [books,set_books] = useState([])
    const getBooks = async()=>{
        let obj ={
            date:currentGlobalDate,
            serviceId:currentServiceId
        }
        try{
            const resp =await getBooks_api(obj)
            set_mainSpin(false)
            set_books([])
            normalForBook(resp.data.data)

        }catch(e){
            set_mainSpin(false)
            console.log(e)
        }
    }

    const normalForBook = (data)=>{
        let books = []
        data.forEach(d=>{
            d.members.forEach(m=>{
                if(d._id===m.for){
                    books.push({
                        member:m,
                        turn:d
                    })
                }

            })
        })

        console.log("Books : ",books)
        books = books.sort((a, b) => a.member.number - b.member.number);
        set_books([])
        set_books(books)
    }

    // ---------------------------------------------


    useEffect(()=>{
        document.body.style.backgroundColor = "white";
        getLocalUser()
        init()
    },[])





    //----------------------------------------------------- handles method

    const handleChangeTab = async (tab)=>{
        set_activeTab(tab);
        currentTab = tab
        await getServices();
    }

    const handleChangeService = async(sId)=>{
        set_selectedService(sId);
        currentServiceId = sId
        let day = await getDay(currentGlobalDate,currentServiceId)
        set_duration(day.duration.toString())
        currentDuration  = day.duration
        switch (currentTab) {
            case 'booking':
                getBooks()
                break;
            case 'service':
                set_mainSpin(false)
                break;
            case 'calender':
                getTurns()
                break;
        }
    }

    const handleChangeDuration = async(due)=>{
        currentDuration  = due
        let status = await changeDayDuration()
        if(status)
         await getServices();
        else {
            message.error("به دلیل وجود نوبت در این روز شما اجازه تغییر بازه زمانی را ندارید")
        }
    }



    const handleClickOnTimes =async (t,sId)=>{
        set_currentTimes(t.index)
        set_selectTimes(t)
        let d = moment(t.jDate,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
        currentGlobalDate = d
        console.log("Date : ",d)
        let day = await getDay(d,sId)
        set_duration(day.duration.toString())
        currentDuration = day.duration;

        // getTurns(d,sId,day.duration)
        // getBooks(d,selectedService)
        console.log("today turn ....................",currentTab)
        switch (currentTab) {
            case 'booking':
                if(currentServiceId.length>0)
                    getBooks()
                break;
            case 'calender':
                if(currentServiceId.length>0)
                    getTurns()
                break;
            default:
                set_mainSpin(false)
        }
    }


    //-----------------------------------------------------

    const getTodayTurns = async (service)=>{
        const currentDate = new Date()
        let date = moment(currentDate).format('YYYY-MM-DD')
        let nowYear  = moment(currentDate).format('jYYYY')
        let nowMonth  = moment(currentDate).format('jMM')
        let nowDay  = moment(currentDate).format('jDD')

        set_selectedYear(nowYear)
        nowMonth = nowMonth.replace(/^0+/, '');
        set_selectedMonth(nowMonth)
        console.log("today turn ....................",date)
        // let t = generateTimes(date)
        let t = newGenerateTimes(nowYear,nowMonth,nowDay)
        set_times(t)
        handleClickOnTimes(t[0],service._id)
    }



    const changeDayDuration = async()=>{
        let obj = {
            date:currentGlobalDate,
            serviceId:currentServiceId,
            duration:parseInt(currentDuration)
        }
        try{
            await changeDayDuration_api(obj);
            set_duration("")
            set_duration(currentDuration.toString())
            return true
        }catch (e) {
            return false
            console.log(e)
        }
    }



    const generateTimes = (date)=>{
        let start = new Date(date)
        let results =[]
        for (let i = 0; i < 30; i++) {
            let end = start.addDays(i)
            let jDate = moment(end).format('jYYYY-jMM-jDD')
            let jDay = moment(end).format('jDD')
            let jMonth = moment(end).format('jMM')
            let jYear = moment(end).format('jYYYY')
            let weekDay = convertDayOfWeek(moment(end).day())
            results.push({index:i,date:end,jDate:jDate,jDay:+jDay,jMonth:+jMonth,jYear:jYear,weekDay:weekDay,parts:[]})
        }
        return results
    }

    const newGenerateTimes = (year, month, day) => {
        let results = [];
        console.log("new Gen Date", year, month, day)

        const currentYear = parseInt(moment().format('jYYYY'));
        const currentMonth = parseInt(moment().format('jMM'));
        const currentDay = parseInt(moment().format('jDD'));


        if (parseInt(year) === currentYear && parseInt(month) === currentMonth) {
            day = currentDay; // روز جاری
        } else {
            day = 1;
        }
        try{
            const daysInMonth = moment.jDaysInMonth(parseInt(year), parseInt(month));
            for (let i = day; i <= daysInMonth; i++) {
                let jDate = year + "-" + month + "-" + i;
                let date = moment(jDate, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
                let jDay = i;
                let jMonth = month;
                let jYear = year;
                let weekDay = convertDayToPersian(moment(jDate, 'jYYYY-jMM-jDD').format('dddd'));

                results.push({
                    index: i,
                    date: date,
                    jDate: jDate,
                    jDay: jDay,
                    jMonth: +jMonth,
                    jYear: jYear,
                    weekDay: weekDay,
                    parts: []
                });
            }
        }catch (e) {
            console.log("E: ",e)
        }





        return results;
    };




    const init = ()=>{
        getInitServices()
    }




    const MyRef = useRef(null);
    const [my,set_my] = useState(null)
    const getLocalUser = ()=>{
        let user = localStorage.getItem('user')
        set_my(JSON.parse(user))
    }

    const [activeTab,set_activeTab] = useState("booking") //booking | services | calender

    const [times,set_times] = useState([])
    const [currentTimes,set_currentTimes]= useState("")
    const [selectTimes,set_selectTimes]= useState({})


    const convertDayToPersian = (day) => {
        switch (day) {
            case 'Saturday':
                return 'شنبه';
            case 'Sunday':
                return 'یک‌شنبه';
            case 'Monday':
                return 'دو‌شنبه';
            case 'Tuesday':
                return 'سه‌شنبه';
            case 'Wednesday':
                return 'چهار‌شنبه';
            case 'Thursday':
                return 'پنج‌شنبه';
            case 'Friday':
                return 'جمعه';
            default:
                return 'نامعتبر';
        }
    };


    const convertDayOfWeek = (num)=>{
        // console.log("num",num)
        switch(num){
            case 1:
                return "دوشنبه"
                break;
            case 2:
                return "سشنبه"
                break;
            case 3:
                return "چهارشنبه"
                break;
            case 4:
                return "پنجشنبه"
                break;
            case 5:
                return "جمعه"
                break;
            case 6:
                return "شنبه"
                break;
            case 0:
                return "یکشنبه"
                break;


        }
    }







    const [selectedYear,set_selectedYear] = useState("")
    const [selectedMonth,set_selectedMonth] = useState("")

    // const handleSelectedYear = async (y)=>{
    //     set_selectedYear(y);
    //     let date_path = y+"-"+selectedMonth+"-"+selectTimes.jDay
    //     let d = moment(date_path,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
    //     let day = await getDay(d,selectedService)
    //     set_duration(day.duration.toString())
    //     currentDuration = day.duration
    //     let ye  = moment(date_path,"jYYYY-jMM-jDD").format('YYYY')
    //     let mo  = moment(date_path,"jYYYY-jMM-jDD").format('MM')
    //     let gen_date = ye+"-"+mo+"-"+"1"
    //     console.log("gen_date",gen_date)
    //     let t = newGenerateTimes(y,selectedMonth,1)
    //     set_times(t)
    //     handleClickOnTimes(t[0],selectedService)
    // }

    const handleSelectedYear = async (year) => {
        set_selectedYear(year);

        // ساختن مسیر تاریخ به صورت شمسی
        const jalaliDatePath = `${year}-${selectedMonth}-${selectTimes.jDay}`;
        const gregorianDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").format('YYYY-MM-DD');

        // دریافت روز و تنظیم مدت زمان
        const dayData = await getDay(gregorianDate, selectedService);
        const { duration } = dayData;
        set_duration(duration.toString());
        currentDuration = duration;

        // تولید تاریخ میلادی از ابتدای ماه
        const generatedStartDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").startOf('month').format('YYYY-MM-DD');
        console.log("generatedStartDate", generatedStartDate);
        console.log("generatedStartDate", year,currentYear,typeof selectedMonth,typeof currentMonth);


        // بررسی اینکه آیا ماه انتخاب‌شده از ماه جاری عقب‌تر است
        if (parseInt(year) < parseInt(currentYear) || (parseInt(year) === parseInt(currentYear) && parseInt(selectedMonth) < parseInt(currentMonth))) {
            // کال کردن تابع دلخواه اگر ماه عقب‌تر باشد
            set_selectedMonth(currentMonth)
            // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
            const generatedTimes = newGenerateTimes(year, currentMonth, 1);
            set_times(generatedTimes);

            // انتخاب اولین زمان تولید‌شده
            handleClickOnTimes(generatedTimes[0], selectedService);

        }else {
            // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
            const generatedTimes = newGenerateTimes(year, selectedMonth, 1);
            set_times(generatedTimes);

            // انتخاب اولین زمان تولید‌شده
            handleClickOnTimes(generatedTimes[0], selectedService);
        }


    };

    // const handleSelectedMonth = async (m)=>{
    //     set_selectedMonth(m);
    //     let date_path = selectedYear+"-"+m+"-"+selectTimes.jDay
    //     let d = moment(date_path,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
    //     let day = await getDay(d,selectedService)
    //     set_duration(day.duration.toString())
    //     currentDuration = day.duration
    //
    //     let ye  = moment(date_path,"jYYYY-jMM-jDD").format('YYYY')
    //     let mo  = moment(date_path,"jYYYY-jMM-jDD").format('MM')
    //     let gen_date = ye+"-"+mo+"-"+"1"
    //     console.log("gen_date",gen_date)
    //     // let t = generateTimes(gen_date)
    //     let t = newGenerateTimes(selectedYear,m,1)
    //     set_times(t)
    //     handleClickOnTimes(t[0],selectedService)
    //     // getTurns(d,selectedService,day.duration)
    // }

    const handleSelectedMonth = async (month) => {
        set_selectedMonth(month);

        // ساختن مسیر تاریخ به صورت شمسی
        const jalaliDatePath = `${selectedYear}-${month}-${selectTimes.jDay}`;
        const gregorianDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").format('YYYY-MM-DD');

        // دریافت روز و تنظیم مدت زمان
        const dayData = await getDay(gregorianDate, selectedService);
        const { duration } = dayData;
        set_duration(duration.toString());
        currentDuration = duration;

        // تولید تاریخ میلادی از ابتدای ماه
        const generatedStartDate = moment(jalaliDatePath, "jYYYY-jMM-jDD").startOf('month').format('YYYY-MM-DD');
        console.log("generatedStartDate", generatedStartDate);

        // تولید زمان‌ها بر اساس سال و ماه انتخاب‌شده
        const generatedTimes = newGenerateTimes(selectedYear, month, 1);
        set_times(generatedTimes);

        // انتخاب اولین زمان تولید‌شده
        handleClickOnTimes(generatedTimes[0], selectedService);
    };



    //service
    const [visibleAddServiceDrawer,set_visibleAddServiceDrawer] = useState(false)



    const showAddServiceDrawer = ()=>{
        set_visibleAddServiceDrawer(!visibleAddServiceDrawer)
    }

    const onAddServiceDrawerClose = () => {
        set_visibleAddServiceDrawer(false);
    };


    const [title,set_title] = useState("")
    const [cost,set_cost] = useState("")//in
    const [pre_cost,set_pre_cost] = useState("")
    const [duration,set_duration] = useState("30")
    const [me,set_me] = useState(true)


    const handleCostChange = (v) => {
        const value = v.replace(/\D/g, "");
        set_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };
    const handlePreCostChange = (v) => {
        const value = v.replace(/\D/g, "");
        set_pre_cost(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };

    const onChange = (checked) => {
        set_me(!me)
    };

    const addService= async()=>{

        let cost_num= cost.replaceAll(",", "");
        let pre_cost_num= pre_cost.replaceAll(",", "");

        cost_num = parseInt(cost_num)
        pre_cost_num = parseInt(pre_cost_num)
        let duration_num =parseInt(duration)


        if(!duration_num || typeof duration_num !=="number"){
            return message.error({
                content: "مدت زمان باید شامل عدد باشد",
                className: 'toast-class'
            });
        }

        if(title.length<1){
            return message.error({
                content: "نام سرویس را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!cost_num || typeof cost_num !=="number"){
            return message.error({
                content: "هزینه سرویس را وارد نمایید",
                className: 'toast-class'
            });
        }

        if(!pre_cost_num || typeof pre_cost_num !=="number"){
            return message.error({
                content: "هزینه رزرو سرویس را وارد نمایید",
                className: 'toast-class'
            });
        }



        let obj = {
            title:title,
            cost:cost_num,
            pre_cost:pre_cost_num,
            duration:duration_num,
            address:address,
            location:location,
            is_me:me,
        }

        try{
            await addService_api(obj)
            await getServices()
            onAddServiceDrawerClose()
            return message.success({
                content: "سرویس اضافه شد",
                className: 'toast-class'
            });
        }catch(e){
            return message.error({
                content: "مشکل در سرویس",
                className: 'toast-class'
            });
            console.log(e)
        }
    }


    //outer map
    const [address,set_address] = useState("")
    const [mapCenter,set_mapCenter] = useState(null)
    const [location,set_location] = useState({})
    const [position, setPosition] = useState([35.6892, 51.3890])
    let mapRef = useRef();
    const [visibleMapDrawer,set_visibleMapDrawer] = useState(false)
    const showMapDrawer =()=>{
        set_visibleMapDrawer(true)
    }
    const closeMapDrawer =()=>{
        set_visibleMapDrawer(false)
    }
    const setAdd = (lat,lng)=>{
        set_mapCenter({lat,lng})
    }
    const handleAcceptLocationClick = ()=>{
        set_location(mapCenter)
        closeMapDrawer()
    }
    function LocationMarker({getAdd}) {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                console.log("click")
            },
            moveend(e) {
                let cc = map.getCenter()
                console.log(cc.lat,cc.lng)
                getAdd(cc.lat,cc.lng)
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }


    return(
        <div className="dumbbell-trainee-container">

            <Drawer height={550}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#ffffff",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom"
                    onClose={closeMapDrawer}
                    visible={visibleMapDrawer}>
                <div className="osm-sec">
                    <div className="accept-location">
                        <span className="clickable" onClick={handleAcceptLocationClick}>تایید موقعیت</span>
                    </div>
                    <div className="osm-custom-flag">
                        <img src={markerIcon} alt=""/>
                    </div>
                    <MapContainer
                        style={{width:"100%",height:"100%"}}
                        ref={mapRef}
                        center={position}
                        zoom={16}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <LocationMarker getAdd={setAdd}/>
                    </MapContainer>

                </div>


            </Drawer>

            <DumbbellHeader title="نوبت دهی"/>

            <div className="dumbbell-booking-mg">


                <div className="tabs-row">
                    <div onClick={e=>handleChangeTab("service")} className={activeTab==="service"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>سرویس ها</span>
                    </div>
                    <div onClick={e=>handleChangeTab("calender")} className={activeTab==="calender"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>تقویم</span>
                    </div>
                    <div onClick={e=>handleChangeTab("booking")} className={activeTab==="booking"?"tabs-item clickable active-tab":"tabs-item clickable"}>
                        <span>نوبت ها</span>
                    </div>
                </div>

                {/*/!*tabs*!/*/}
                {/*<div className="dumbbell-booking-mg-tabs">*/}
                {/*    <div*/}
                {/*        onClick={e=>{*/}
                {/*            handleChangeTab('service')*/}
                {/*        }}*/}
                {/*        className={activeTab==="service"?"dumbbell-booking-mg-tabs-item active":"dumbbell-booking-mg-tabs-item"}*/}
                {/*    >*/}
                {/*        <span>سرویس ها</span>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        onClick={e=>{*/}
                {/*            handleChangeTab('calender')*/}
                {/*        }}*/}
                {/*        className={activeTab==="calender"?"dumbbell-booking-mg-tabs-item active":"dumbbell-booking-mg-tabs-item"}*/}
                {/*    >*/}
                {/*        <span>تقویم</span>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        onClick={e=>{*/}
                {/*            handleChangeTab('booking')*/}
                {/*        }}*/}
                {/*        className={activeTab==="booking"?"dumbbell-booking-mg-tabs-item active":"dumbbell-booking-mg-tabs-item"}*/}
                {/*    >*/}
                {/*        <span>نوبت ها</span>*/}
                {/*    </div>*/}

                {/*</div>*/}


                {activeTab==="service"?
                    <div className="service-tab">
                        <div className="service-list">
                            {services.map(s=>{
                                return(
                                    <ServiceCard getServices={getServices} item={s}/>
                                )
                            })}
                        </div>
                        <div className="service-add-btn clickable">
                            <span onClick={e=>{
                                showAddServiceDrawer()
                            }}>اضافه کردن سرویس</span>
                        </div>
                    </div>
                    :
                    null}

                <Drawer bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={onAddServiceDrawerClose} visible={visibleAddServiceDrawer}>
                    <div className="add-service-drawer">
                        <div className="add-service-drawer-header">
                            <span>ثبت سرویس</span>
                        </div>
                        <div className="add-service-drawer-item">
                            <input
                                value={title}
                                onChange={e=>set_title(e.target.value)}
                                type="text" name="" id=""/>
                            <span className="add-service-drawer-item-label">نام سرویس  </span>
                        </div>

                        <div className="add-service-drawer-item">
                            <input
                                pattern="[0-9]*"
                                inputMode="numeric"
                                value={cost}
                                onChange={e=>handleCostChange(convertToEnglishNumbers(e.target.value))}
                                type="text" name="" id=""/>
                            <span className="add-service-drawer-item-label">هزینه سرویس (تومان) </span>
                        </div>

                        <div className="add-service-drawer-item">
                            <input
                                pattern="[0-9]*"
                                inputMode="numeric"
                                value={pre_cost}
                                onChange={e=>handlePreCostChange(convertToEnglishNumbers(e.target.value))}
                                type="text" name="" id=""/>
                            <span className="add-service-drawer-item-label">هزینه رزرو سرویس (تومان) </span>
                        </div>

                        <div className="add-service-drawer-item">
                            <input
                                pattern="[0-9]*"
                                inputMode="numeric"
                                value={address}
                                onChange={e=>set_address(e.target.value)}
                                type="text" name="" id=""/>
                            <span className="add-service-drawer-item-label">آدرس محل سرویس</span>
                        </div>

                        <div className="add-service-drawer-item">
                            <div className="page-actions-btn clickable location-btn">
                                <div className="page-actions-btn-icon">
                                    <img src={locationIcon} alt=""/>
                                </div>
                                <div className="page-actions-btn-label-left" onClick={showMapDrawer}>
                                    <span>تغییر موقعیت نقشه</span>
                                </div>
                            </div>
                            <span className="add-service-drawer-item-label">موقعیت سرویس</span>
                        </div>



                        {/*<div className="add-service-drawer-item">*/}
                        {/*    <input*/}
                        {/*        value={duration}*/}
                        {/*        onChange={e=>set_duration(e.target.value)}*/}
                        {/*        type="text" name="" id=""/>*/}
                        {/*    <span className="add-service-drawer-item-label">مدت زمان سرویس(دقیقه)</span>*/}
                        {/*</div>*/}

                        <div className="add-service-drawer-item">
                            <Switch defaultChecked  onChange={onChange} />
                            <span className="add-service-drawer-item-label">سرویس خودم</span>
                        </div>


                        <div className="add-service-drawer-btn clickable" >
                                    <span onClick={e=>{
                                        addService()
                                    }}>ثبت</span>
                        </div>

                    </div>
                </Drawer>


                {currentServiceId.length>0?
                    <Fragment>
                        {activeTab==="booking" || activeTab==="calender"?
                            <Fragment>
                                {/*date filter*/}
                                <div className="dumbbell-booking-mg-date-filter">
                                    <div className="dumbbell-booking-mg-date-filter-item">
                                        <select
                                            value={selectedMonth}
                                            onChange={e=>handleSelectedMonth(e.target.value)}
                                            name="" id="">
                                            {generateMonthOptions()}
                                        </select>
                                    </div>

                                    <div className="dumbbell-booking-mg-date-filter-item">
                                        <select
                                            value={selectedYear}
                                            onChange={e=>handleSelectedYear(e.target.value)}
                                            name="" id="">
                                            {generateYearOptions()}
                                        </select>
                                    </div>
                                </div>


                                {/*choose days*/}
                                <div className="dumbbell-booking-mg-days-regal">

                                    {times.length>0?<div className="plan-bar" style={{margin:"4px 10px"}}>

                                        <div className="plan-bar-list">
                                            <Swiper ref={MyRef}  {...params}>
                                                {times.map(time=>{

                                                    return(
                                                        <div  className={'plan-bar-item'}
                                                              style={currentTimes===time.index?{borderRadius:5,padding:"2px",color:"white",backgroundColor:"#8D89FD",fontFamily:"YekanBold"}:{}}
                                                              onClick={e=>{
                                                                  handleClickOnTimes(time,selectedService)
                                                              }}>

                                                            <span>{time.jDay}</span>
                                                            <span>{time.weekDay}</span>
                                                        </div>
                                                    )
                                                })}

                                            </Swiper>
                                        </div>
                                    </div>:null}


                                </div>


                                {/*choose services*/}
                                <div className="dumbbell-booking-mg-service">
                                    {activeTab==="calender"?
                                        <select
                                            value={duration}
                                            onChange={e=>{
                                                handleChangeDuration(e.target.value)
                                            }}
                                            name="" id="">
                                            <option value="5">۵ دقیقه</option>
                                            <option value="10">۱۰ دقیقه</option>
                                            <option value="15">۱۵ دقیقه</option>
                                            <option value="20">۲۰ دقیقه</option>
                                            <option value="25">۲۵ دقیقه</option>
                                            <option value="30">۳۰ دقیقه</option>
                                            <option value="35">۳۵ دقیقه</option>
                                            <option value="40">۴۰ دقیقه</option>
                                            <option value="45">۴۵ دقیقه</option>
                                            <option value="50">۵۰ دقیقه</option>
                                            <option value="55">۵۵ دقیقه</option>
                                            <option value="60">۶۰ دقیقه</option>
                                            <option value="90">۹۰ دقیقه</option>
                                            <option value="120">۲ ساعت</option>
                                            <option value="180">۳ ساعت</option>
                                        </select>:null
                                    }
                                    <select
                                        value={selectedService}
                                        onChange={e=>{
                                            handleChangeService(e.target.value)
                                        }}
                                        name="" id="">
                                        {services?.map(s=>{
                                            return(
                                                <option value={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Fragment>:null
                        }

                    </Fragment>:null
                }


                {currentServiceId.length<1 && !mainSpin?
                    <div>
                        <div className="service-not-exist">
                            <span className="service-not-exist-text" >اولین سرویس خود را اضافه نمایید</span>
                            <span className="service-not-exist-btn clickable" onClick={e=>{
                                showAddServiceDrawer()
                            }}>ثبت سرویس</span>
                        </div>
                    </div>:null
                }


                {activeTab==="calender"?
                    <div className="dumbbell-booking-mg-calender-list">

                        {/*booking item*/}
                        {turns.map(t=>{
                            return(
                                <TurnCard services={services} getTurns={getTurns} turn={t} date={selectTimes} duration={duration} serviceId={selectedService} />
                            )
                        })}

                    </div>:null
                }


                {activeTab==="booking"?
                    <div className="dumbbell-booking-mg-booking-list">
                        {books.map(b=>{
                            return(
                                <BookCard item={b} getBooks={getBooks}/>
                            )
                        })}

                        {books.length<1 && !mainSpin?
                            <div className="empty-state">
                                <img src={emptyIcon} alt=""/>
                                <span>برای این روز نوبتی رزرو </span>
                                <span>نشده !</span>
                            </div>:null
                        }

                    </div>:null}

            </div>


        </div>
    )
}



export default DumbbellBookingManagement;
